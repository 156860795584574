import {
  Checkbox,
  createStyles,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IRecording } from "../../infrastructure/recordings";
import { SortingButton } from "../general/SortingButton";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      padding: theme.spacing(3),
      justifyContent: "center",
      backgroundColor: "yellow",
    },
    cell: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    areaCell: {
      borderRightStyle: "dashed",
      borderLeftStyle: "dashed",
      borderRightWidth: 1,
      borderLeftWidth: 1,
      borderColor: theme.palette.divider,
    },
    processedCell: {
      borderLeftStyle: "dashed",
      borderLeftWidth: 1,
      borderColor: theme.palette.divider,
    },
    deviationCell: {
      borderRightStyle: "dashed",
      borderRightWidth: 1,
      borderColor: theme.palette.divider,
    },
    allIds: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(2),
    },
    idCell: {
      display: "flex",
      alignItems: "center",
    },
    actionContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(3),
      justifyContent: "center",
      backgroundColor: "green",
    },
    formControlText: {
      fontSize: 16,
      color: "#000000",
      fontWeight: "bold",
      fontStyle: "alpha",
    },
  });

export interface IRecordingsTableHeadProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly recordings: IRecording[];
  readonly onSortingClick: (sortingKey: string) => void;
  readonly sortInverse: boolean;
  readonly sortingKey: string;
  readonly onAllClicked: () => void;
  readonly allChecked: boolean;
  readonly onAllMenuClicked: (allMenuRef: null | HTMLElement) => void;
}
class RecordingsTableHeadState {
  public filteredRecordings: IRecording[] = [];
  public constructor(_recordings: IRecording[]) {
    this.filteredRecordings = _recordings;
  }
}

class RecordingsTableHeadComponent extends React.Component<
  IRecordingsTableHeadProps,
  RecordingsTableHeadState
> {
  private allMenuRef: null | HTMLElement = null;

  public constructor(props: IRecordingsTableHeadProps) {
    super(props);
    this.state = new RecordingsTableHeadState(props.recordings);
  }
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell align={"justify"}>
            <div className={classes.allIds}>
              <Typography variant={"subtitle2"}>
                {t("labels.recordings.table-headers.all")}
              </Typography>
            </div>
          </TableCell>
          <TableCell align={"justify"}>
            <div className={classes.cell}>
              <Typography variant={"subtitle2"}>
                {t("labels.recordings.table-headers.ids")}
              </Typography>
              {this.renderSortingButton("id")}
            </div>
          </TableCell>
          <TableCell align={"justify"}>
            <div className={classes.cell}>
              <Typography variant={"subtitle2"}>
                {t("labels.recordings.table-headers.machine")}
              </Typography>
              {this.renderSortingButton("machine")}
            </div>
          </TableCell>
          <TableCell align={"justify"}>
            <div className={classes.cell} style={{ justifyContent: "center" }}>
              <div style={{ display: "flex", flex: 0.8 }}></div>
              <Typography variant={"subtitle2"}>
                {t("labels.recordings.table-headers.fields")}
              </Typography>
              {this.renderSortingButton("fields")}
            </div>
          </TableCell>
          <TableCell colSpan={3} align={"center"} className={classes.areaCell}>
            <div className={classes.cell}>
              <Typography variant={"subtitle2"}>
                {t("labels.recordings.table-headers.area")}
              </Typography>
            </div>
          </TableCell>
          <TableCell align={"justify"}>
            <div className={classes.cell}>
              <Typography variant={"subtitle2"}>
                {t("labels.recordings.table-headers.date")}
              </Typography>
              {this.renderSortingButton("date")}
            </div>
          </TableCell>
          <TableCell align={"justify"}>
            <div className={classes.cell}>
              <Typography variant={"subtitle2"}>
                {t("labels.recordings.table-headers.status")}
              </Typography>
              {this.renderSortingButton("state")}
            </div>
          </TableCell>
          <TableCell align={"justify"}>
            <Typography variant={"subtitle2"}>
              {t("labels.recordings.table-headers.action")}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <div
              ref={(ref) => (this.allMenuRef = ref)}
              className={classes.idCell}>
              <Checkbox
                onClick={(event) => {
                  this.props.onAllClicked();
                  event.stopPropagation();
                  event.preventDefault();
                }}
                checked={this.props.allChecked}
              />
              <SortingButton
                sortingKey={"all"}
                selectedSortingKey={"all"}
                sortInverse={true}
                onSortingClick={() =>
                  this.props.onAllMenuClicked(this.allMenuRef)
                }
              />
            </div>
          </TableCell>
          <TableCell colSpan={3} />
          <TableCell className={classes.processedCell}>
            <Typography variant={"subtitle2"}>
              {t("labels.recordings.table-headers.processed")}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant={"subtitle2"}>
              {t("labels.recordings.table-headers.field-area")}
            </Typography>
          </TableCell>
          <TableCell className={classes.deviationCell}>
            <Typography variant={"subtitle2"}>
              {t("labels.recordings.table-headers.deviation")}
            </Typography>
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>
    );
  }

  private renderSortingButton(sortingKey: string): React.ReactNode {
    return (
      <SortingButton
        sortingKey={this.props.sortingKey}
        selectedSortingKey={sortingKey}
        sortInverse={this.props.sortInverse}
        onSortingClick={() => this.props.onSortingClick(sortingKey)}
      />
    );
  }
}

export const RecordingsTableHead = withTranslation()(
  withStyles(styles)(RecordingsTableHeadComponent),
);
