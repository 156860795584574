import { fade, IconButton, InputBase, Theme } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.black, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.black, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(0),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      flex: 1,
      display: "flex",
      color: "inherit",
      padding: theme.spacing(3, 1, 1, 3),
    },
    inputInput: {
      flex: 1,
      display: "flex",
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "30ch",
      },
    },
    searchContainer: {
      flex: 1,
      display: "flex",
      borderRadius: theme.spacing(1),
      borderWidth: "1 px solid",
    },
  });

export interface ISearchProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly value: string;
  readonly placeholder?: string;
  readonly onChange: (value: string) => void;
  readonly onSearchClick?: () => void;
}

class SearchComponent extends React.PureComponent<ISearchProps> {
  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.search}>
        <div className={classes.searchContainer}>
          <InputBase
            fullWidth
            value={this.props.value}
            placeholder={this.props.placeholder}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(event) =>
              this.props.onChange(event.target.value as string)
            }
          />
          {this.props.onSearchClick != undefined ? (
            <IconButton
              className={classes.searchIcon}
              onClick={() =>
                this.props.onSearchClick != undefined
                  ? this.props.onSearchClick()
                  : undefined
              }>
              <SearchIcon />
            </IconButton>
          ) : (
            false
          )}
        </div>
      </div>
    );
  }
}
export const Search = withTranslation()(withStyles(styles)(SearchComponent));
