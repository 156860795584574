import axios from "axios";
import { injectable } from "inversify";
import { ConfigService } from "../config-service";
import { inject } from "../injection";
import { IMachine, IMachinesRepository } from "../machines";
import { ITimelineItem } from "../timeline/ITimelineItem";
import { TypeNames } from "../TypeNames";
import { IHistoryService } from "./IHistoryService";

@injectable()
export class HistoryService implements IHistoryService {
  @inject(TypeNames.machinesRepository)
  private readonly _machinesRepository: IMachinesRepository;

  public async getMachines(jwt: string): Promise<IMachine[]> {
    const url = `${ConfigService.get(
      (config) => config.baseUrl,
    )}/machines/history`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const machines = response.data.map((x: any) => {
      const machine: IMachine = {
        ...x,
        category: x.machine_category.categoryName,
        type: x.machine_type.typeName,
        gpsTracker: x.gps_tracker,
        recordings: [],
      };
      return machine;
    });
    return machines;
  }

  public async getMachineTimeline(
    jwt: string,
    currentPage: number,
    machineId: number,
    minDate: string,
    maxDate: string,
  ): Promise<ITimelineItem[]> {
    const limit = 1;
    const start = (currentPage - 1) * limit;
    const url = `${ConfigService.get(
      (config) => config.baseUrl,
    )}/recordings/history?_limit=${limit}&_start=${start}&_id=${machineId}&_minDate=${minDate}&_maxDate=${maxDate}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
  }

  public async getTimelinePage(
    jwt: string,
    machineId: number,
    minDate: string,
    maxDate: string,
  ): Promise<number> {
    const url = `${ConfigService.get(
      (config) => config.baseUrl,
    )}/recordings/history/count?_id=${machineId}&_minDate=${minDate}&_maxDate=${maxDate}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
  }
}
