import { IMachine } from "../machines";

export class BatteryPercentageService {
  public static getBatteryPercentage(machine?: IMachine): number {
    if (machine != undefined) {
      if (machine.gpsTracker != undefined) {
        const gpsTracker = machine.gpsTracker;
        const maxVoltage = gpsTracker.maxVoltage;
        const currentVoltage = gpsTracker.voltage;
        const minVoltage = 3.35;
        const range = maxVoltage - minVoltage;
        const correctedCurrentVoltage = currentVoltage - minVoltage;
        const percentage = (correctedCurrentVoltage * 100) / range;
        return Number(percentage.toFixed());
      }
      return 0;
    }
    return 0;
  }

  public static getBatteryPercentageFromValues(
    voltage: number,
    maxVoltage: number,
  ): number {
    if (voltage != undefined && maxVoltage != undefined) {
      const currentVoltage = voltage;
      const minVoltage = 3.35;
      const range = maxVoltage - minVoltage;
      const correctedCurrentVoltage = currentVoltage - minVoltage;
      const percentage = (correctedCurrentVoltage * 100) / range;
      return Number(percentage.toFixed());
    }
    return 0;
  }
}
