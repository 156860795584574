import {
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      width: 275,
      height: 353,
      alignItems: "center",
      textAlign: "center",
      padding: theme.spacing(0, 2, 2, 2),
    },
    text: {
      opacity: 0.8,
    },
  });

export type IEmptyRecordingsCardProps = WithStyles<typeof styles> &
  WithTranslation;

class EmptyRecordingsCardComponent extends React.PureComponent<
  IEmptyRecordingsCardProps
> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.text} variant={"subtitle2"}>
          {t("labels.recordings.card.placeholder")}
        </Typography>
      </div>
    );
  }
}

export const EmptyRecordingsCard = withTranslation()(
  withStyles(styles)(EmptyRecordingsCardComponent),
);
