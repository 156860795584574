import { injectable } from "inversify";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Root } from "./Root";

@injectable()
export class Application {
  public start(containerElement: HTMLDivElement): void {
    const root = React.createElement(Root, {});
    ReactDOM.render(root, containerElement);
  }
}
