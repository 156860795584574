import { Theme, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      height: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
    },
    child: {
      flex: 1,
      height: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      marginRight: theme.spacing(2),
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    lastChild: {
      flex: 1,
      height: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    text: {
      textAlign: "center",
    },
  });

export interface IDashboardOverviewProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly tillage: number;
  readonly grasslandCultivation: number;
  readonly transports: number;
  readonly operatingHours: number;
  readonly records: number;
}

class DashboardOverviewComponent extends React.PureComponent<
  IDashboardOverviewProps
> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.child}>
          <Typography
            variant={"h3"}
            className={classes.text}>{`${this.props.tillage} ha`}</Typography>
          <Typography variant={"subtitle2"} className={classes.text}>
            {t("labels.dashboard.tillage")}
          </Typography>
        </div>
        <div className={classes.child}>
          <Typography
            variant={"h3"}
            className={
              classes.text
            }>{`${this.props.grasslandCultivation} ha`}</Typography>
          <Typography variant={"subtitle2"} className={classes.text}>
            {t("labels.dashboard.grassland-cultivation")}
          </Typography>
        </div>
        <div className={classes.child}>
          <Typography variant={"h3"} className={classes.text}>
            {this.props.transports.toString()}
          </Typography>
          <Typography variant={"subtitle2"} className={classes.text}>
            {t("labels.dashboard.transports")}
          </Typography>
        </div>
        <div className={classes.child}>
          <Typography variant={"h3"} className={classes.text}>
            {this.props.operatingHours.toString()}
          </Typography>
          <Typography variant={"subtitle2"} className={classes.text}>
            {t("labels.dashboard.operating-hours")}
          </Typography>
        </div>
        <div className={classes.lastChild}>
          <Typography variant={"h3"} className={classes.text}>
            {this.props.records.toString()}
          </Typography>
          <Typography variant={"subtitle2"} className={classes.text}>
            {t("labels.dashboard.records")}
          </Typography>
        </div>
      </div>
    );
  }
}

export const DashboardOverview = withTranslation()(
  withStyles(styles)(DashboardOverviewComponent),
);
