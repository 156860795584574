import * as React from "react";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { withApplicationStateStoreContext } from "./infrastructure/application-state";
import { inject } from "./infrastructure/injection";
import { NotificationCenter } from "./infrastructure/notification";
import { TypeNames } from "./infrastructure/TypeNames";
import { Theme } from "./resources/themes";
import { RootNavigation } from "./components/navigation/RootNavigation";
import { Notification } from "./components/general/Notification";

class RootComponent extends React.Component {
  @inject(TypeNames.notificationCenter)
  private readonly _notificationCenter: NotificationCenter;
  public render(): React.ReactNode {
    return (
      <MuiThemeProvider theme={Theme}>
        <CssBaseline />
        <RootNavigation />
        <Notification
          notificationCenter={this._notificationCenter}
          showDuration={5000}
        />
      </MuiThemeProvider>
    );
  }
}

export const Root = withApplicationStateStoreContext(RootComponent);
