import {
  Button,
  createStyles,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IMachine } from "../../infrastructure/machines";
import { ICoordinate } from "../../infrastructure/recordings";
import { GoogleMap } from "../maps";
import { MapType } from "../maps/MapType";

interface IGeoFenceProps extends WithStyles<typeof styles>, WithTranslation {
  readonly onRadiusChange: (radius: number) => void;
  readonly onAddGeoFence: () => void;
  readonly geoFenceCoordinate?: ICoordinate;
  readonly geoFenceRadius: number;
  readonly machine: IMachine;
  readonly onCoordinatesChange: (coords: ICoordinate) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
      alignItems: "center",
    },
    rowSpaced: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    title: {
      paddingBottom: theme.spacing(3),
    },
    map: {
      height: 450,
      width: "100%",
      marginTop: theme.spacing(3),
    },
    buttonLabel: {
      color: theme.palette.background.paper,
    },
    button: {
      flex: 1,
      display: "flex",
      textTransform: "none",
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      margin: theme.spacing(3),
    },
    buttonDisabled: {
      flex: 1,
      display: "flex",
      textTransform: "none",
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.grey.A100,
      margin: theme.spacing(3),
    },
    tooltipContainer: {
      display: "flex",
      flexDirection: "row",
      padding: 10,
    },
    tooltip: {
      marginLeft: theme.spacing(3),
      fontSize: 18,
      fontWeight: "bold",
    },
  });

class GeoFenceComponent extends React.Component<IGeoFenceProps> {
  private _map: MapType;
  private _maps: MapType;
  private _mapCircles: MapType[] = [];
  public render(): React.ReactNode {
    const { classes, t } = this.props;

    this.renderCircle(this.props.geoFenceRadius, this.props.geoFenceCoordinate);
    const markers = [];
    if (this.props.geoFenceCoordinate != undefined) {
      markers.push({
        machine: this.props.machine,
        coordinate: this.props.geoFenceCoordinate,
      });
    }
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant={"h4"}>
            {t("labels.machines-administration.geoFence.title")}
          </Typography>
        </div>
        <Typography variant={"caption"}>
          {t("labels.machines-administration.geoFence.radius")}
        </Typography>
        <TextField
          color={"primary"}
          variant={"outlined"}
          type={"number"}
          value={this.props.geoFenceRadius}
          onChange={(event) =>
            this.props.onRadiusChange(Number(event.currentTarget.value))
          }
        />
        <Button
          onClick={() => this.props.onAddGeoFence()}
          className={
            this.props.geoFenceCoordinate == undefined
              ? classes.buttonDisabled
              : classes.button
          }
          disabled={this.props.geoFenceCoordinate == undefined}>
          <Typography variant={"body1"} className={classes.buttonLabel}>
            {t("labels.machines-administration.geoFence.add-button")}
          </Typography>
        </Button>
        <div className={classes.tooltipContainer}>
          <Info fontSize={"large"} />
          <Typography className={classes.tooltip}>
            {t("labels.machines-administration.geoFence.tooltip")}
          </Typography>
        </div>
        <div className={classes.map}>
          <GoogleMap
            markers={markers}
            mapCenter={this.getMapCenter()}
            isGeofenceMap={true}
            zoom={15}
            onMapClick={(coords: ICoordinate) =>
              this.props.onCoordinatesChange(coords)
            }
            onGoogleApiLoaded={(map, maps) => {
              this._map = map;
              this._maps = maps;
              this.renderCircle(
                this.props.geoFenceRadius,
                this.props.geoFenceCoordinate,
              );
            }}
          />
        </div>
      </div>
    );
  }

  private getMapCenter(): ICoordinate {
    if (this.props.geoFenceCoordinate != undefined) {
      return this.props.geoFenceCoordinate;
    }
    if (this.props.machine.lastLocation != undefined) {
      return {
        longitude: this.props.machine.lastLocation.longitude,
        latitude: this.props.machine.lastLocation.latitude,
      };
    }
    return { longitude: 10.4515, latitude: 51.1657 };
  }

  private renderCircle(radius: number, coordinate?: ICoordinate): void {
    if (this._map != undefined && this._maps != undefined) {
      this._mapCircles.map((x) => x.setMap(null));
      if (coordinate == undefined) {
        return;
      }
      const circle = new this._maps.Circle({
        center: { lat: coordinate.latitude, lng: coordinate.longitude },
        strokeColor: "#9AB02D",
        strokeOpacity: 1,
        strokeWeight: 3,
        fillColor: "rgba(0,0,193,0.37)",
        fillOpacity: 1,
        radius: radius,
      });
      circle.setMap(this._map);
      this._mapCircles.push(circle);
    }
  }
}

export const GeoFence = withTranslation()(
  withStyles(styles)(GeoFenceComponent),
);
