import * as React from "react";
import {
  createStyles,
  IconButton,
  Snackbar,
  SnackbarContent,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import CheckCircleOutlined from "@material-ui/icons/CheckCircleOutlined";
import Close from "@material-ui/icons/Close";
import Error from "@material-ui/icons/Error";
import Info from "@material-ui/icons/Info";
import Warning from "@material-ui/icons/Warning";
import {
  INotificationData,
  NotificationCenter,
  NotificationTypes,
} from "../../infrastructure/notification";

interface INotificationComponentProps extends WithStyles<typeof styles> {
  readonly notificationCenter: NotificationCenter;
  readonly showDuration: number;
}

class NotificationComponentState {
  public notifications: INotificationData[] = [];
  public currentStyle: string;

  public constructor(style: string) {
    this.currentStyle = style;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
    },
    information: {
      backgroundColor: theme.palette.info.main,
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
    },
    success: {
      backgroundColor: theme.palette.success.main,
    },
    error: {
      backgroundColor: theme.palette.error.main,
    },
    text: {
      paddingLeft: theme.spacing(1),
    },
  });

export class NotificationComponent extends React.Component<
  INotificationComponentProps,
  NotificationComponentState
> {
  private _timeout: NodeJS.Timeout;
  private _notificationSubscription?: () => void;

  public constructor(props: INotificationComponentProps) {
    super(props);
    this.state = new NotificationComponentState(this.props.classes.information);
  }

  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <Snackbar open={this.state.notifications.length > 0}>
        <SnackbarContent
          className={this.state.currentStyle}
          elevation={6}
          message={
            <div className={classes.container}>
              {this.getIcon()}
              <Typography className={classes.text}>{this.getText()}</Typography>
            </div>
          }
          action={[
            <IconButton
              onClick={() => this.handleDismissNotification()}
              key={0}>
              <Close />
            </IconButton>,
          ]}
        />
      </Snackbar>
    );
  }

  public componentDidMount(): void {
    this._notificationSubscription = this.props.notificationCenter.subscribe(
      (data: INotificationData) => this.notification(data),
    );
  }

  public componentWillUnmount(): void {
    clearInterval(this._timeout);
    this._notificationSubscription?.();
  }

  private notification(data: INotificationData): void {
    if (this.state.notifications.length === 0) {
      this._timeout = setTimeout(
        () => this.handleDismissNotification(),
        this.props.showDuration,
      );
    }
    const notifications = [...this.state.notifications, data];
    this.updateStyle(data.notificationType);
    this.setState({ notifications });
  }

  private handleDismissNotification(): void {
    const notifications = this.state.notifications;
    notifications.shift();
    this.setState({ notifications });
    if (notifications.length > 0) {
      this.updateStyle(notifications[0].notificationType);
      this._timeout = setTimeout(
        () => this.handleDismissNotification(),
        this.props.showDuration,
      );
    }
  }

  private updateStyle(type: NotificationTypes): void {
    const { classes } = this.props;
    switch (type) {
      case NotificationTypes.warning:
        this.setState({ currentStyle: classes.warning });
        break;
      case NotificationTypes.success:
        this.setState({ currentStyle: classes.success });
        break;
      case NotificationTypes.error:
        this.setState({ currentStyle: classes.error });
        break;
      default:
        this.setState({ currentStyle: classes.information });
    }
  }

  private getIcon(): React.ReactNode {
    if (this.state.notifications.length > 0) {
      switch (this.state.notifications[0].notificationType) {
        case NotificationTypes.warning:
          return <Warning />;
        case NotificationTypes.success:
          return <CheckCircleOutlined />;
        case NotificationTypes.error:
          return <Error />;
        default:
          return <Info />;
      }
    }
    return false;
  }

  private getText(): string {
    return this.state.notifications.length > 0
      ? this.state.notifications[0].text
      : "";
  }
}

export const Notification = withStyles(styles)(NotificationComponent);
