import {
  Button,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    pageContainer: {
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(2),
      alignItems: "center",
      justifyContent: "center",
    },
    activePage: {
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
      fontWeight: "bold",
    },
    page: { marginLeft: theme.spacing(2), color: theme.palette.text.primary },
  });

export interface IPaginationProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly maxPages: number;
  readonly currentPage: number;
  readonly onPagePress: (page: number) => void;
}

class PaginationComponent extends React.PureComponent<IPaginationProps> {
  public render(): React.ReactNode {
    return (
      <div className={this.props.classes.pageContainer}>
        <Typography>{this.props.t("labels.recordings.page")}</Typography>
        {this.renderPages()}
      </div>
    );
  }

  public renderPages(): React.ReactNode {
    const pageArray: number[] = [];
    if (this.props.maxPages >= 10) {
      pageArray.push(1);
      if (this.props.currentPage > 5) {
        pageArray.push(-100);
      }
      for (
        let cP = this.props.currentPage - 3;
        cP <= this.props.currentPage + 3;
        cP++
      ) {
        if (cP > 1 && cP < this.props.maxPages) {
          pageArray.push(cP);
        }
      }
      if (this.props.currentPage + 4 < this.props.maxPages) {
        pageArray.push(-100);
      }
      pageArray.push(this.props.maxPages);
    } else {
      for (let cP = 1; cP <= this.props.maxPages; cP++) {
        pageArray.push(cP);
      }
    }
    return pageArray.map((x: number, index) =>
      x === -100 ? (
        <Typography key={index} className={this.props.classes.page}>
          ...
        </Typography>
      ) : (
        <Button key={index} onClick={() => this.props.onPagePress(x)}>
          <Typography
            className={
              this.props.currentPage === x
                ? this.props.classes.activePage
                : this.props.classes.page
            }>
            {x}
          </Typography>
        </Button>
      ),
    );
  }
}

export const Pagination = withTranslation()(
  withStyles(styles)(PaginationComponent),
);
