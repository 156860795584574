import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import GoogleMapReact, { Coords } from "google-map-react";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IMachineLocation } from "../../infrastructure/dashboard";
import { IMachine } from "../../infrastructure/machines";
import { ICoordinate } from "../../infrastructure/recordings";
import { Tool } from "../../infrastructure/Tool";
import { Routes } from "../navigation/Routes";
import { GoogleMapPinCard } from "./GoogleMapPinCard";
import { MapType } from "./MapType";
import { Marker } from "./Marker";
import { PolylineType } from "./PolylineType";

const styles = (theme: Theme) =>
  createStyles({
    map: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "hidden",
      borderRadius: theme.spacing(1),
      position: "relative",
    },
    menu: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
    },
    toolsContainer: {
      left: 10,
      bottom: 20,
      position: "absolute",
      display: "flex",
      flexDirection: "column",
    },
    controlContainer: {
      padding: theme.spacing(2, 0),
    },
    fabText: {
      color: theme.palette.text.hint,
    },
    fabIcon: {
      color: theme.palette.text.hint,
      marginRight: theme.spacing(),
    },
  });

export interface IGoogleMapProps
  extends WithStyles<typeof styles>,
    WithTranslation,
    RouteComponentProps {
  readonly isAreaMap?: boolean;
  readonly isGeofenceMap?: boolean;
  readonly mapCenter: ICoordinate;
  readonly zoom: number;
  readonly onGoogleApiLoaded?: (map: MapType, maps: MapType) => void;
  readonly controlSize?: number;
  readonly markers?: IMachineLocation[];
  readonly onMapClick?: (coords: ICoordinate) => void;
  readonly fieldMarkers?: ICoordinate[];
  readonly enableTools?: boolean;
  readonly isMarketFilled?: boolean;
}

class GoogleMapState {
  public infoWindow: unknown;
  public map: MapType;
  public maps: MapType;
  public isMarkerMenuOpen: boolean = false;
  public machine?: IMachine;
  public markerMenuAnchor?: HTMLElement;
  public bounds: unknown = undefined;
  public zoom: number | undefined = undefined;
  public currentTool: Tool = Tool.none;
  public points: ICoordinate[] = [];
  public isToolResultDialogVisible: boolean = false;
  public resultValue: number = 0;
  public polygons: PolylineType[] = [];
}

class GoogleMapComponent extends React.Component<
  IGoogleMapProps,
  GoogleMapState
> {
  private _mapType: string = "roadmap";
  private readonly earthRadiusKm = 6371;

  public constructor(props: IGoogleMapProps) {
    super(props);
    this.state = new GoogleMapState();
  }

  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.map}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyAzK7LTYH3dSg1qP6oUjNV0uF_SeSi1nnw",
            libraries: ["geometry"],
          }}
          center={this.getMapCenter()}
          options={(maps) => this.getMapOptions(maps)}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
          onMapTypeIdChange={(mapType) => (this._mapType = mapType)}
          zoom={
            this.state.zoom != undefined ? this.state.zoom : this.props.zoom
          }
          onClick={(clickEvent) =>
            this.onMapClick({
              longitude: clickEvent.lng,
              latitude: clickEvent.lat,
            })
          }
          defaultZoom={this.props.zoom}>
          {this.renderMarkers()}
        </GoogleMapReact>
        {this.props.enableTools && (
          <div className={classes.toolsContainer}>
            {this.state.currentTool != Tool.none ? (
              <div className={classes.controlContainer}>
                <Fab
                  variant={"extended"}
                  color={"primary"}
                  onClick={() =>
                    this.setState({
                      isToolResultDialogVisible: true,
                      resultValue: this.getToolResult(),
                    })
                  }>
                  <EditIcon className={classes.fabIcon} />
                  <Typography className={classes.fabText}>
                    {t("labels.recordings-detailed.google-maps.calculate")}
                  </Typography>
                </Fab>
              </div>
            ) : (
              <>
                <div className={classes.controlContainer}>
                  <Fab
                    variant={"extended"}
                    color={"primary"}
                    onClick={() => this.setState({ currentTool: Tool.path })}>
                    <EditIcon className={classes.fabIcon} />
                    <Typography className={classes.fabText}>
                      {t("labels.recordings-detailed.google-maps.path")}
                    </Typography>
                  </Fab>
                </div>
                <div className={classes.controlContainer}>
                  <Fab
                    color={"primary"}
                    variant={"extended"}
                    onClick={() => this.setState({ currentTool: Tool.area })}>
                    <EditIcon className={classes.fabIcon} />
                    <Typography className={classes.fabText}>
                      {t("labels.recordings-detailed.google-maps.area")}
                    </Typography>
                  </Fab>
                </div>
              </>
            )}
          </div>
        )}
        <GoogleMapPinCard
          isMarkerMenuOpen={this.state.isMarkerMenuOpen}
          machine={this.state.machine}
          markerMenuAnchor={this.state.markerMenuAnchor}
          onMarkerMenuClosed={() => this.handleMarkerMenuClosed()}
          onNavigate={(route: Routes, machineId: number) =>
            this.handleNavigate(route, machineId)
          }
        />
        <Dialog
          open={this.state.isToolResultDialogVisible}
          maxWidth={"xs"}
          fullWidth>
          <DialogContent>
            <Typography>
              {this.state.currentTool === Tool.area
                ? t("labels.recordings-detailed.google-maps.dialog-area-info")
                : t(
                    "labels.recordings-detailed.google-maps.dialog-distance-info",
                  )}
              {this.state.resultValue.toFixed(1)}
              {this.state.currentTool === Tool.area ? "ha" : "m"}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => this.handleToolReset()}>
              {"Ok"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  private async handleApiLoaded(map: MapType, maps: MapType): Promise<void> {
    if (this.props.onGoogleApiLoaded != undefined) {
      this.props.onGoogleApiLoaded(map, maps);
    }
    const infoWindow = new maps.InfoWindow();
    this.setState({ map, maps, infoWindow });
  }
  private handleNavigate(route: Routes, machineId: number): void {
    const newRoute = route.replace(":machineId", machineId.toString());
    this.props.history.push(newRoute);
  }
  private getMapOptions(maps: MapType) {
    if (this.props.isAreaMap === true) {
      return {
        zoomControl: false,
        rotateControl: false,
        scaleControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        gestureHandling: "none",
        keyboardShortcuts: false,
        controlSize: this.props.controlSize,
        mapTypeControl: false,
        mapTypeId: this._mapType,
        mapTypeControlOptions: {
          style: maps.MapTypeControlStyle.DEFAULT,
          position: maps.ControlPosition.DEFAULT,
          mapTypeIds: [maps.MapTypeId.ROADMAP],
        },
      };
    }
    if (this.props.isGeofenceMap === true) {
      return {
        controlSize: this.props.controlSize,
        mapTypeControl: true,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeId: this._mapType,
        mapTypeControlOptions: {
          style: maps.MapTypeControlStyle.DEFAULT,
          position: maps.ControlPosition.DEFAULT,
          mapTypeIds: [
            maps.MapTypeId.ROADMAP,
            maps.MapTypeId.SATELLITE,
            maps.MapTypeId.HYBRID,
          ],
        },
      };
    }
    return {
      controlSize: this.props.controlSize,
      mapTypeControl: true,
      mapTypeId: this._mapType,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.DEFAULT,
        position: maps.ControlPosition.DEFAULT,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },
    };
  }

  public componentDidUpdate(): void {
    if (
      this.props.markers != undefined &&
      this.props.markers.length > 0 &&
      this.state.bounds == undefined &&
      this.state.maps != undefined
    ) {
      const bounds = this.getMapBounds(this.state.maps, this.props.markers);
      this.setState({ bounds });
      this.state.map.fitBounds(bounds);
      if (this.props.markers.length === 1) {
        this.setState({ bounds, zoom: 14.8 });
      }
    }
  }

  private renderMarkers(): React.ReactNode {
    const markers = [];
    if (this.props.markers != undefined && this.props.markers.length > 0) {
      markers.push(
        ...this.props.markers.map((x, i) => {
          if (x.coordinate != undefined) {
            return (
              <Marker
                key={i}
                lat={x.coordinate.latitude}
                lng={x.coordinate.longitude}
                onClick={(event) => this.handleMarkerClicked(event, x.machine)}
              />
            );
          }
        }),
      );
    }
    if (
      this.props.fieldMarkers != undefined &&
      this.props.fieldMarkers.length > 0
    ) {
      markers.push(
        ...this.props.fieldMarkers.map((x, i) => {
          return (
            <Marker
              filled={this.props.isMarketFilled}
              key={i}
              lat={x.latitude}
              lng={x.longitude}
              label={(i + 1).toString()}
            />
          );
        }),
      );
    }
    return markers;
  }

  private getMapCenter(): Coords {
    return {
      lat: this.props.mapCenter.latitude,
      lng: this.props.mapCenter.longitude,
    };
  }

  private handleMarkerClicked(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    event: MouseEvent<HTMLDivElement>,
    machine: IMachine,
  ): void {
    if (this.state.machine?.id === machine.id) {
      return this.handleMarkerMenuClosed();
    }
    this.setState({
      machine,
      markerMenuAnchor: event.target,
      isMarkerMenuOpen: true,
    });
  }

  private handleMarkerMenuClosed(): void {
    this.setState({
      machine: undefined,
      markerMenuAnchor: undefined,
      isMarkerMenuOpen: false,
    });
  }

  private onMapClick(coords: ICoordinate): void {
    if (this.state.currentTool !== Tool.none) {
      const poly = new this.state.maps.Circle({
        center: { lat: coords.latitude, lng: coords.longitude },
        strokeColor: "#f00",
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: "#fff",
        fillOpacity: 1,
        radius: 1,
      });
      poly.setMap(this.state.map);
      this.setState({
        polygons: [...this.state.polygons, poly],
        points: [...this.state.points, coords],
      });
      if (this.state.points.length > 0) {
        const previousCoords = this.state.points[this.state.points.length - 1];
        const line = new this.state.maps.Polyline({
          path: [
            { lat: previousCoords.latitude, lng: previousCoords.longitude },
            { lat: coords.latitude, lng: coords.longitude },
          ],
          strokeColor: "#f00",
          strokeOpacity: 1,
          strokeWeight: 3,
        });
        line.setMap(this.state.map);
        this.setState({ polygons: [...this.state.polygons, line, poly] });
      }
      return;
    }
    if (this.props.onMapClick != undefined) {
      this.props.onMapClick(coords);
    }
  }

  private getMapBounds(maps: MapType, places: IMachineLocation[]): unknown {
    const bounds = new maps.LatLngBounds();
    places.forEach((place) => {
      bounds.extend(
        new maps.LatLng(place.coordinate.latitude, place.coordinate.longitude),
      );
    });
    return bounds;
  }

  private getToolResult(): number {
    let value = 0;
    if (this.state.points.length < 2) {
      return value;
    }
    if (this.state.currentTool === Tool.path) {
      this.state.points.forEach((point, index) => {
        if (index === 0) {
          return;
        }
        const previousCoords = this.state.points[index - 1];
        value += this.getLineDistance(previousCoords, point);
      });
      value *= 1000;
    } else if (this.state.currentTool === Tool.area) {
      const polygons = this.state.polygons
        .filter((x) => x.center != undefined)
        .map((x) => ({
          lat: x.center.lat(),
          lng: x.center.lng(),
        }));
      const hectare = 10000;
      value =
        this.state.maps.geometry.spherical.computeArea(polygons) / hectare;
    }
    return Math.abs(value);
  }
  private getLineDistance(
    pointFrom: ICoordinate,
    pointTo: ICoordinate,
  ): number {
    const dLat = this.degreesToRadians(pointTo.latitude - pointFrom.latitude);
    const dLon = this.degreesToRadians(pointTo.longitude - pointFrom.longitude);
    const lat1 = this.degreesToRadians(pointFrom.latitude);
    const lat2 = this.degreesToRadians(pointTo.latitude);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return this.earthRadiusKm * c;
  }
  private degreesToRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
  }
  private handleToolReset(): void {
    this.state.polygons.forEach((poly) => poly.setMap(null));
    this.setState({
      isToolResultDialogVisible: false,
      points: [],
      currentTool: Tool.none,
      polygons: [],
    });
  }
}

export const GoogleMap = withRouter(
  withTranslation()(withStyles(styles)(GoogleMapComponent)),
);
