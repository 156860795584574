import {
  createStyles,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import * as _ from "lodash";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ContentHeader } from "../../components/dashboard";
import { BasePage } from "../../components/general/BasePage";
import { GoogleMap } from "../../components/maps";
import { MapType } from "../../components/maps/MapType";
import { PolylineType } from "../../components/maps/PolylineType";
import { Routes } from "../../components/navigation/Routes";
import { IFieldsAreaInformation } from "../../components/recordings-detailed/IFieldsAreaInformation";
import { MiniMap } from "../../components/recordings-detailed/MiniMap";
import { RecalculationDialog } from "../../components/recordings-detailed/RecalculationDialog";
import { RecordingDetailedInfo } from "../../components/recordings-detailed/RecordingDetailedInfo";
import { RecordingFieldInfo } from "../../components/recordings-detailed/RecordingFieldInfo";
import {
  IApplicationStoreContextConsumerProps,
  withApplicationStateStoreContext,
} from "../../infrastructure/application-state";
import { inject } from "../../infrastructure/injection";
import {
  ICoordinate,
  ILine,
  IRecording,
  IRecordingField,
  IRecordingsService,
} from "../../infrastructure/recordings";
import { TypeNames } from "../../infrastructure/TypeNames";

const styles = (theme: Theme) =>
  createStyles({
    row: {
      display: "flex",
      flexDirection: "row",
      paddingTop: theme.spacing(2),
    },
    head: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      paddingBottom: theme.spacing(3),
      flexDirection: "row",
    },
    body: {
      flex: 16,
      display: "flex",
      flexDirection: "column",
    },
    rowCenter: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    rowSpaced: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    column: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
    mapContainer: {
      display: "flex",
      flex: 1,
      padding: theme.spacing(3),
    },
    child: {
      flex: 1,
      display: "flex",
      paddingRight: theme.spacing(2),
    },
    columnChild: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      paddingRight: theme.spacing(2),
    },
    lastChild: {
      flex: 1,
      display: "flex",
    },
    iconContainer: {
      width: "fit-content",
      height: "fit-content",
      paddingRight: theme.spacing(3),
      alignSelf: "center",
      justifyContent: "center",
    },
    icon: {
      fontSize: 35,
    },
  });

interface IRouteParams {
  readonly recordingId?: string;
}
type IRootProps = WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps<IRouteParams> &
  IApplicationStoreContextConsumerProps;

class RootState {
  public showField: boolean = true;
  public recording: IRecording;
  public isExportMenuOpen: boolean = false;
  public exportMenuAnchor?: HTMLElement;
  public currentFieldIndex: number = 0;
  public isRecalculateDialogOpen: boolean = false;
  public userLocation: ICoordinate = { longitude: 10.4515, latitude: 51.1657 };
  public search: string = "";
  public isLoading: boolean = false;
  public maps: MapType = undefined;
}

class RootComponent extends React.Component<IRootProps, RootState> {
  @inject(TypeNames.recordingsService)
  private readonly _recordingsService: IRecordingsService;
  private _monitorRecordingListener: unknown;

  private _minimapPolyLines: PolylineType[] = [];
  private _polyLines: PolylineType[] = [];
  private _miniMap: MapType;
  private _map: MapType;

  public constructor(props: IRootProps) {
    super(props);
    this.state = new RootState();
  }
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    if (this.state.recording == undefined) {
      return (
        <BasePage
          currentBaseRoute={Routes.recordings}
          onRouteClick={(newRoute: Routes) => this.props.history.push(newRoute)}
        />
      );
    }
    return (
      <BasePage
        currentBaseRoute={Routes.recordings}
        onRouteClick={(newRoute: Routes) => this.props.history.push(newRoute)}>
        <div className={classes.head}>
          <IconButton
            onClick={() => this.handleBackButtonClicked()}
            className={classes.iconContainer}>
            <ArrowBack className={classes.icon} />
          </IconButton>
          <ContentHeader
            title={t("labels.recordings.title")}
            isNested
            subtitle={`Record ${this.props.match.params.recordingId}`}
            buttonProps={{
              onButtonClick: () => this.handleRecalculateClicked(),
              buttonLabel: t("actions.recordings-detailed.recalculate"),
            }}
            iconProps={{ isIcon: true }}
          />
        </div>
        <div className={classes.body}>
          <GoogleMap
            enableTools
            isMarketFilled
            fieldMarkers={this.state.recording.fields.map((x) => x.centerPoint)}
            mapCenter={this.getMapCenter()}
            zoom={this.getZoomLevel()}
            onGoogleApiLoaded={(map, maps) => {
              this._map = map;
              this.handleGoogleApiLoaded(map, maps);
              this.setState({ maps });
            }}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.child}>
            <RecordingDetailedInfo recording={this.state.recording} />
          </div>
          <div className={classes.columnChild}>{this.renderMiniMap()}</div>
          <div className={classes.lastChild}>
            {this.getRecordingFieldInfo()}
          </div>
        </div>
        <Menu
          keepMounted
          open={this.state.isExportMenuOpen}
          anchorEl={this.state.exportMenuAnchor}
          onClose={() => this.handleExportMenuClose()}>
          <MenuItem>{t("actions.recordings-detailed.export-csv")}</MenuItem>
          <MenuItem>{t("actions.recordings-detailed.export-link")}</MenuItem>
        </Menu>
        <RecalculationDialog
          fieldsAreaInformation={this.getFieldAreaInformation()}
          processedArea={this.getProcessedArea()}
          fieldArea={this.getFieldArea()}
          isLoading={this.state.isLoading}
          mapCenter={this.getMapCenter()}
          onGoogleApiLoad={(map, maps) => this.handleGoogleApiLoaded(map, maps)}
          onRecalculateClick={() => this.handleRecalculateDialogButtonClicked()}
          onMaxCurvatureChange={(value) =>
            this.handleMaxCurvatureChanged(value)
          }
          onMinDistanceChange={(value) => this.handleMinDistanceChanged(value)}
          onMinLengthChange={(value) => this.handleMinLengthChanged(value)}
          onRadiusChange={(value) => this.handleRadiusChanged(value)}
          radius={this.state.recording.crad}
          maxCurvature={this.state.recording.slp}
          minLength={this.state.recording.lng}
          minDistance={this.state.recording.dist}
          recording={this.state.recording}
          isOpen={this.state.isRecalculateDialogOpen}
          onClose={() => this.handleRecalculationDialogClosed()}
        />
      </BasePage>
    );
  }
  public async componentDidMount(): Promise<void> {
    const recording = await this.getRecording(
      Number(this.props.match.params.recordingId),
    );
    if (recording != undefined) {
      this.setState({
        recording,
      });
    }
  }

  private async getRecording(
    recordingId: number,
  ): Promise<IRecording | undefined> {
    if (
      this.props.store.state.jwt == undefined ||
      this.props.match.params == undefined ||
      this.props.match.params.recordingId == undefined
    ) {
      return undefined;
    }
    return await this._recordingsService.getRecording(
      this.props.store.state.jwt,
      recordingId,
    );
  }
  private async handleRecalculateDialogButtonClicked(): Promise<void> {
    if (this.props.store.state.jwt != undefined) {
      await this._recordingsService.recalculateRecording(
        this.props.store.state.jwt,
        this.state.recording.id,
        {
          minDistance: this.state.recording.dist,
          minLength: this.state.recording.lng,
          radius: this.state.recording.slp,
          maxCurvature: this.state.recording.crad,
        },
      );
      this._monitorRecordingListener = setInterval(
        async () => this.monitorRecording(),
        5000,
      );
      this.setState({ isLoading: true });
    }
  }

  private async monitorRecording(): Promise<void> {
    const recording = await this.getRecording(
      Number(this.props.match.params.recordingId),
    );
    if (
      recording != undefined &&
      this.state.recording.updatedAt !== recording.updatedAt
    ) {
      this.setState({
        recording,
        isLoading: false,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      clearInterval(this._monitorRecordingListener);
    }
  }
  private handleMinDistanceChanged(minDistance: number): void {
    this.setState({
      recording: { ...this.state.recording, dist: minDistance },
    });
  }

  private handleMinLengthChanged(minLength: number): void {
    this.setState({
      recording: { ...this.state.recording, lng: minLength },
    });
  }

  private handleMaxCurvatureChanged(maxCurvature: number): void {
    this.setState({
      recording: { ...this.state.recording, slp: maxCurvature },
    });
  }

  private handleRadiusChanged(radius: number): void {
    this.setState({
      recording: { ...this.state.recording, crad: radius },
    });
  }

  private handleShowFieldClicked(): void {
    this.setState({ showField: !this.state.showField });
    this.togglePinkLines();
  }

  private handleExportMenuClose(): void {
    this.setState({
      isExportMenuOpen: false,
      exportMenuAnchor: undefined,
    });
  }

  private handlePreviousFieldClicked(): void {
    this.setState({ currentFieldIndex: this.state.currentFieldIndex - 1 });
  }

  private handleNextFieldClicked(): void {
    this.setState({ currentFieldIndex: this.state.currentFieldIndex + 1 });
  }

  private handleRecalculationDialogClosed(): void {
    this.setState({ isRecalculateDialogOpen: false });
  }

  private handleRecalculateClicked(): void {
    this.setState({ isRecalculateDialogOpen: true });
  }

  private handleMinimapGoogleApiLoaded(map: MapType, maps: MapType): void {
    this.renderIndependentLines(
      this.state.recording.redLines,
      "#b70600",
      maps,
      map,
    );
    this.state.recording.fields.forEach((x) => {
      this.renderIndependentLines(x.greenLines, "#6ade09", maps, map);
      this.renderPinkLines(x.pinkPoints, "#cb09de", maps, map, true);
      RootComponent.renderCircle(x.entryPoint, "#afaf00", maps, map);
      RootComponent.renderCircle(x.exitPoint, "#0000c1", maps, map);
    });
  }

  private handleGoogleApiLoaded(map: MapType, maps: MapType): void {
    this.renderIndependentLines(
      this.state.recording.redLines,
      "#b70600",
      maps,
      map,
    );
    this.state.recording.fields.forEach((x) => {
      this.renderIndependentLines(x.greenLines, "#6ade09", maps, map);
      this.renderPinkLines(x.pinkPoints, "#cb09de", maps, map, false);
      RootComponent.renderCircle(x.entryPoint, "#afaf00", maps, map);
      RootComponent.renderCircle(x.exitPoint, "#0000c1", maps, map);
    });
  }

  private renderIndependentLines(
    lines: ILine[],
    color: string,
    maps: MapType,
    map: MapType,
  ): void {
    lines.forEach((line) => {
      const polyline = new maps.Polyline({
        path: [
          { lat: line.point1.latitude, lng: line.point1.longitude },
          { lat: line.point2.latitude, lng: line.point2.longitude },
        ],
        strokeColor: color,
        strokeOpacity: 1,
        strokeWeight: 2,
      });
      polyline.setMap(map);
    });
  }

  private renderPinkLines(
    lines: ILine[],
    color: string,
    maps: MapType,
    map: MapType,
    isMiniMap: boolean,
  ): void {
    lines.forEach((line) => {
      const polyline = new maps.Polyline({
        path: [
          { lat: line.point1.latitude, lng: line.point1.longitude },
          { lat: line.point2.latitude, lng: line.point2.longitude },
        ],
        strokeColor: color,
        strokeOpacity: 1,
        strokeWeight: 3,
      });
      polyline.setMap(map);
      if (isMiniMap) {
        this._minimapPolyLines.push(polyline);
      } else {
        this._polyLines.push(polyline);
      }
    });
  }

  private static renderCircle(
    circle: ICoordinate,
    color: string,
    maps: MapType,
    map: MapType,
  ): void {
    const poly = new maps.Circle({
      center: { lat: circle.latitude, lng: circle.longitude },
      strokeColor: color,
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 1,
      radius: 10,
    });
    poly.setMap(map);
  }

  private getField(): IRecordingField {
    return this.state.recording.fields[this.state.currentFieldIndex];
  }

  private getRecordingFieldInfo(): React.ReactNode {
    const recordingFieldLength = this.state.recording.fields.length;
    if (recordingFieldLength > 0) {
      return (
        <RecordingFieldInfo
          field={this.getField()}
          showField={this.state.showField}
          onShowFieldClick={() => this.handleShowFieldClicked()}
        />
      );
    } else return null;
  }

  private renderMiniMap(): React.ReactNode {
    const recordingFieldLength = this.state.recording.fields.length;
    if (recordingFieldLength > 0) {
      return (
        <MiniMap
          fieldIndex={this.state.currentFieldIndex}
          onNextFieldClick={() => this.handleNextFieldClicked()}
          onPreviousFieldClick={() => this.handlePreviousFieldClicked()}
          fields={this.state.recording.fields}
          zoom={16}
          onGoogleApiLoaded={(map, maps) => {
            this._miniMap = map;
            this.handleMinimapGoogleApiLoaded(map, maps);
          }}
        />
      );
    } else return null;
  }

  private getProcessedArea(): number {
    return _.reduce(
      this.state.recording.fields,
      (sum, value) => sum + value.processedArea,
      0,
    );
  }

  private getFieldArea(): number {
    return _.reduce(
      this.state.recording.fields,
      (sum, value) => sum + value.fieldArea,
      0,
    );
  }

  private getFieldAreaInformation(): IFieldsAreaInformation[] {
    return this.state.recording.fields.map((x) => ({
      totalProcessed: x.processedArea,
      totalArea: x.fieldArea,
    }));
  }

  private togglePinkLines(): void {
    if (this.state.showField) {
      this._polyLines.forEach((x) => (x.visible = false));
      this._minimapPolyLines.forEach((x) => (x.visible = false));
      this._minimapPolyLines.forEach((x) => x.setMap(this._miniMap));
      this._polyLines.forEach((x) => x.setMap(this._map));
    } else {
      this._polyLines.forEach((x) => (x.visible = true));
      this._minimapPolyLines.forEach((x) => (x.visible = true));
      this._minimapPolyLines.forEach((x) => x.setMap(this._miniMap));
      this._polyLines.forEach((x) => x.setMap(this._map));
    }
  }

  private getMapCenter(): ICoordinate {
    if (this.state.maps != undefined) {
      const points = this.state.recording.redLines.map((x) => x.point1);
      const pointsFields = this.state.recording.fields.map(
        (x) => x.centerPoint,
      );
      const bounds = this.getMapBounds(
        this.state.maps,
        points.concat(pointsFields),
      );
      this._map.fitBounds(bounds);
    }
    if (this.state.recording.redLines.length !== 0) {
      return this.state.recording.redLines[0].point1;
    }
    return this.state.userLocation;
  }

  private getZoomLevel(): number {
    if (this.state.recording.redLines.length !== 0) {
      return 13;
    }
    return 11;
  }

  private handleBackButtonClicked(): void {
    this.props.history.goBack();
  }

  private getMapBounds(maps: MapType, places: ICoordinate[]): unknown {
    const bounds = new maps.LatLngBounds();
    places.forEach((place) => {
      bounds.extend(new maps.LatLng(place.latitude, place.longitude));
    });
    return bounds;
  }
}

export const Root = withApplicationStateStoreContext(
  withRouter(withTranslation()(withStyles(styles)(RootComponent))),
);
