import * as HttpAbstractions from "@econsor-mobile/http-abstractions";
import * as HttpFetchBrowser from "@econsor-mobile/http-fetch-browser";
import * as Logging from "@econsor-mobile/logging";
import i18n, { Resource } from "i18next";
import { Container, interfaces } from "inversify";
import { initReactI18next } from "react-i18next";
import { Application } from "../../Application";
import { InjectionContainerLocator } from "../injection";
import { Languages } from "../Languages";
import { Module } from "../Module";
import { TypeNames } from "../TypeNames";

export class Bootstrapper {
  public static create(logger: Logging.ILogger): Bootstrapper {
    return new Bootstrapper(logger);
  }

  private _logger: Logging.ILogger;
  private _translations: unknown[] = [];
  private constructor(logger: Logging.ILogger) {
    this._logger = logger;
  }

  public async bootstrap(): Promise<Application> {
    this._logger.info("Bootstrapping application");

    this._logger.debug("Creating configurations");
    const applicationLoggerConfiguration = this.createApplicationLoggerConfiguration(
      Logging.LogLevel.Debug,
    );
    this._logger.debug("Created configurations");

    this._logger.debug("Creating Localization");
    await this.initLocalization();
    this._logger.debug("Created Localization");

    this._logger.debug("Creating injection container");
    const container = this.createContainer(applicationLoggerConfiguration);
    this._logger.debug("Created injection container");

    this._logger.debug("Creating application");
    const application = container.get<Application>(TypeNames.application);
    this._logger.debug("Created application");

    this._logger.info("Successfully bootstrapped application");
    return application;
  }
  public useTranslation(translation: Resource): Bootstrapper {
    this._translations.push(translation);
    return this;
  }
  private createContainer(
    loggerConfiguration: Logging.LoggerConfiguration,
  ): interfaces.Container {
    const container = new Container();
    container.load(
      new Module(loggerConfiguration),
      new HttpAbstractions.HttpModule(),
      new HttpFetchBrowser.HttpFetchModule(),
    );
    InjectionContainerLocator.setContainer(container);
    return container;
  }

  private createApplicationLoggerConfiguration(
    level: Logging.LogLevel,
  ): Logging.LoggerConfiguration {
    return Logging.LoggerConfiguration.create()
      .setLogLevel(level)
      .addSink(Logging.ConsoleSink.create());
  }

  private async initLocalization(
    lng: Languages = Languages.german,
  ): Promise<void> {
    const resources: Resource = {};
    this._translations.forEach((x) => {
      // x is any but we used it as unknown
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resources[x.cultureCode.value] = { translation: x.translation };
    });
    await i18n.use(initReactI18next).init({
      resources,
      lng,
      fallbackLng: Languages.german,
      debug: true,
      interpolation: {
        escapeValue: false,
      },
    });
  }
}
