import {
  createStyles,
  FormControl,
  MenuItem,
  Select,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import BatteryChargingFull from "@material-ui/icons/BatteryChargingFull";
import moment from "moment";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { BatteryPercentageService } from "../../infrastructure/battery-percentage/BatteryPercentageService";
import { BatteryVoltageService } from "../../infrastructure/battery-percentage/BatteryVoltageService";
import { IMachine } from "../../infrastructure/machines";
import { Search } from "../general/Search";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(3),
      backgroundColor: theme.palette.grey["100"],
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    rowSpaced: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
      padding: theme.spacing(2, 0),
    },
    head: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(2),
    },
    column: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    text: {
      textAlign: "center",
    },
    voltPercentContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "space-between",
    },
    voltText: {
      paddingRight: theme.spacing(2),
    },
    formControl: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      minWidth: 200,
    },
  });

interface ITimelineInfosProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly machine: IMachine;
  readonly machines: IMachine[];
  readonly onClose: () => void;
  readonly onOpen: () => void;
  readonly isOpen: boolean;
  readonly onMachineChanged: (id: number) => void;
  readonly search: string;
  readonly onSearchChanged: (text: string) => void;
}

class TimelineInfosComponent extends React.Component<ITimelineInfosProps> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.column}>
          <div className={classes.head}>
            <div className={classes.row}>
              <div className={classes.formControl}>
                <FormControl variant="outlined">
                  <Select
                    onClose={() => {
                      this.props.onClose();
                    }}
                    style={{ height: 50 }}
                    renderValue={() => this.renderSelectValue()}
                    onOpen={() => this.props.onOpen()}
                    color={"primary"}
                    variant={"outlined"}
                    open={this.props.isOpen}
                    value={this.props.machine.id}
                    onChange={(event) => {
                      const value = event.target.value as string;
                      this.props.onMachineChanged(Number(value));
                    }}>
                    {this.renderMachines()}
                  </Select>
                </FormControl>
                <div>
                  <Search
                    value={this.props.search}
                    onChange={(value) => this.props.onSearchChanged(value)}
                    placeholder={t("labels.history.search-placeholder")}
                  />
                </div>
                <div className={classes.rowSpaced}>
                  <Typography className={classes.text}>
                    (
                    {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      moment(this.props.machine.gpsTracker!.updated_at).format(
                        "DD.MM.YYYY",
                      )
                    }
                    )
                  </Typography>
                  <BatteryChargingFull
                    color={"primary"}
                    className={classes.text}
                  />
                  <div className={classes.voltPercentContainer}>
                    <Typography
                      className={classes.voltText}
                      variant={"h4"}>{`${BatteryVoltageService.getVoltage(
                      this.props.machine,
                    )}Volt`}</Typography>
                    <Typography
                      variant={
                        "h4"
                      }>{`${BatteryPercentageService.getBatteryPercentage(
                      this.props.machine,
                    )}%`}</Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.rowSpaced}>
            <Typography className={classes.text} variant={"caption"}>
              {t("labels.history.name")}
            </Typography>
            <Typography className={classes.text} variant={"body1"}>
              {this.props.machine.name}
            </Typography>
          </div>
          <div className={classes.rowSpaced}>
            <Typography className={classes.text} variant={"caption"}>
              {t("labels.history.machine-nr")}
            </Typography>
            <Typography className={classes.text} variant={"body1"}>
              {this.props.machine.stockNumber}
            </Typography>
          </div>
          <div className={classes.rowSpaced}>
            <Typography className={classes.text} variant={"caption"}>
              {t("labels.history.machine-id")}
            </Typography>
            <Typography className={classes.text} variant={"body1"}>
              {this.props.machine.gpsTracker?.serialNumber}
            </Typography>
          </div>
          <div className={classes.rowSpaced}>
            <Typography className={classes.text} variant={"caption"}>
              {t("labels.history.machine-category")}
            </Typography>
            <Typography className={classes.text} variant={"body1"}>
              {t(`labels.machine-categories.${this.props.machine.category}`)}
            </Typography>
          </div>
          <div className={classes.rowSpaced}>
            <Typography className={classes.text} variant={"caption"}>
              {t("labels.history.machine-type")}
            </Typography>
            <Typography className={classes.text} variant={"body1"}>
              {t(`labels.machine-types.${this.props.machine.type}`)}
            </Typography>
          </div>
          <div className={classes.rowSpaced}>
            <Typography className={classes.text} variant={"caption"}>
              {t("labels.history.work-width")}
            </Typography>
            <Typography className={classes.text} variant={"body1"}>
              {t("labels.history.work-width-value", {
                value: this.props.machine.workWidth,
              })}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
  private renderSelectValue(): React.ReactNode {
    return <Typography>{this.props.machine.name}</Typography>;
  }

  private renderMachines(): React.ReactNode {
    return this.props.machines.map((x) => {
      return (
        <MenuItem key={x.id} color={"primary"} value={x.id}>
          {x.name}
        </MenuItem>
      );
    });
  }
}

export const TimelineInfos = withTranslation()(
  withStyles(styles)(TimelineInfosComponent),
);
