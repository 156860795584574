import {
  Accordion,
  AccordionSummary,
  Checkbox,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import {
  FiberManualRecord,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import moment, { duration, Duration } from "moment";
import "moment/locale/de";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IRecordingField } from "../../infrastructure/recordings";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
    },
    content: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    head: {
      flex: 5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: theme.spacing(3),
    },
    body: {
      flex: 5,
      display: "flex",
      flexDirection: "column",
    },
    rowSpaced: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
    },
    text: {
      flex: 1,
      display: "flex",
      alignSelf: "center",
      padding: theme.spacing(0, 2),
    },
    title: {
      color: theme.palette.primary.main,
    },
    faded: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      alignSelf: "center",
    },
    dotEntrance: {
      color: "#afaf00",
    },
    dotExit: {
      color: "#0000c1",
    },
    dotField: {
      color: "#00b700",
    },
  });

interface IRecordingFieldInfoProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly field: IRecordingField;
  readonly showField: boolean;
  readonly onShowFieldClick: () => void;
}

class RecordingFieldInfoComponent extends React.Component<
  IRecordingFieldInfoProps
> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    moment.locale(this.props.i18n.language);
    return (
      <Accordion className={classes.root} expanded={false}>
        <AccordionSummary>
          <div className={classes.content}>
            <div className={classes.head}>
              <Typography className={classes.title} variant={"h5"}>
                {t("labels.recordings-detailed.field-info.title")}
              </Typography>
            </div>
            <div className={classes.body}>
              <div className={classes.rowSpaced}>
                <Typography className={classes.faded} variant={"caption"}>
                  {t("labels.recordings-detailed.field-info.field-entrance")}
                </Typography>
                <FiberManualRecord
                  className={classes.dotEntrance}
                  fontSize={"small"}
                />
                <Typography className={classes.text} variant={"subtitle2"}>
                  {moment(this.props.field.entryTime).format("HH:mm")}
                </Typography>
              </div>
              <div className={classes.rowSpaced}>
                <Typography className={classes.faded} variant={"caption"}>
                  {t("labels.recordings-detailed.field-info.field-exit")}
                </Typography>
                <FiberManualRecord
                  className={classes.dotExit}
                  fontSize={"small"}
                />
                <Typography className={classes.text} variant={"subtitle2"}>
                  {moment(this.props.field.exitTime).format("HH:mm")}
                </Typography>
              </div>
              <div className={classes.rowSpaced}>
                <Typography className={classes.faded} variant={"caption"}>
                  {t("labels.recordings-detailed.field-info.work-duration")}
                </Typography>
                <Typography className={classes.text} variant={"subtitle2"}>
                  {this.getDuration().humanize()}
                </Typography>
              </div>
              <div className={classes.rowSpaced}>
                <Typography className={classes.faded} variant={"caption"}>
                  {t("labels.recordings-detailed.field-info.processed-area")}
                </Typography>
                <FiberManualRecord
                  className={classes.dotField}
                  fontSize={"small"}
                />
                <Typography className={classes.text} variant={"subtitle2"}>
                  {t(
                    "labels.recordings-detailed.field-info.processed-area-value",
                    {
                      value: this.props.field.processedArea.toFixed(2),
                    },
                  )}
                </Typography>
              </div>
              <div className={classes.rowSpaced}>
                <Typography className={classes.faded} variant={"caption"}>
                  {t("labels.recordings-detailed.field-info.field-size")}
                </Typography>
                <Typography className={classes.faded} variant={"subtitle2"}>
                  {t("labels.recordings-detailed.field-info.field-size-value", {
                    value: this.props.field.fieldArea.toFixed(2),
                  })}
                </Typography>
                {this.props.showField ? <Visibility /> : <VisibilityOff />}
                <Checkbox
                  checked={this.props.showField}
                  onChange={() => this.props.onShowFieldClick()}
                />
              </div>
            </div>
          </div>
        </AccordionSummary>
      </Accordion>
    );
  }

  private getDuration(): Duration {
    return duration(
      moment(this.props.field.entryTime).diff(
        moment(this.props.field.exitTime),
      ),
    );
  }
}

export const RecordingFieldInfo = withTranslation()(
  withStyles(styles)(RecordingFieldInfoComponent),
);
