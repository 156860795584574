import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Routes } from "../../components/navigation/Routes";
import { SignUpForm } from "../../components/sign-up/SignUpForm";
import {
  ActionNames,
  IApplicationStoreContextConsumerProps,
  withApplicationStateStoreContext,
} from "../../infrastructure/application-state";
import { IAuthenticatedAction } from "../../infrastructure/application-state/actions";
import {
  IAuthenticationResponse,
  IAuthenticationService,
} from "../../infrastructure/authentication";
import { inject } from "../../infrastructure/injection";
import {
  NotificationCenter,
  NotificationTypes,
} from "../../infrastructure/notification";
import { TypeNames } from "../../infrastructure/TypeNames";

const styles = () =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      height: "100vh",
      flexDirection: "column",
      justifyContent: "center",
    },
  });

type IRootProps = WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps &
  IApplicationStoreContextConsumerProps;

class RootState {
  public userName: string = "";
  public email: string = "";
  public password: string = "";
  public passwordAssurance: string = "";
  public checkedTermsAndService: boolean = false;
  public userNameError?: string;
  public mailError?: string;
  public passwordError?: string;
  public passwordAssuranceError?: string;
  public termsAndConditionsError?: string;
}

class RootComponent extends React.Component<IRootProps, RootState> {
  @inject(TypeNames.notificationCenter)
  private readonly _notificationCenter: NotificationCenter;
  @inject(TypeNames.authenticationService)
  private readonly _authenticationService: IAuthenticationService;
  public constructor(props: IRootProps) {
    super(props);
    this.state = new RootState();
  }

  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <SignUpForm
          userName={this.state.userName}
          email={this.state.email}
          password={this.state.password}
          passwordAssurance={this.state.passwordAssurance}
          onPasswordChange={(password) => this.handlePasswordChanged(password)}
          onEmailChange={(email) => this.handleEmailChanged(email)}
          onPasswordAssuranceChange={(passwordAssurance) =>
            this.handlePasswordAssurance(passwordAssurance)
          }
          onUsernameChange={(userName) => this.handleUsernameChanged(userName)}
          checkedTermsAndService={this.state.checkedTermsAndService}
          onCheckboxChange={() => this.handleCheckboxChanged()}
          onSignUpClick={() => this.handleSignUpClicked()}
          onBackClick={() => this.handleBackClicked()}
          userNameError={this.state.userNameError}
          mailError={this.state.mailError}
          passwordError={this.state.passwordError}
          passwordAssuranceError={this.state.passwordAssuranceError}
          termsAndConditionsError={this.state.termsAndConditionsError}
        />
      </div>
    );
  }

  private handleUsernameChanged(userName: string): void {
    this.setState({ userName });
  }

  private handleEmailChanged(email: string): void {
    this.setState({ email });
  }

  private handlePasswordChanged(password: string): void {
    this.setState({ password });
  }

  private handlePasswordAssurance(passwordAssurance: string): void {
    this.setState({ passwordAssurance });
  }

  private handleCheckboxChanged(): void {
    this.setState({
      checkedTermsAndService: !this.state.checkedTermsAndService,
    });
  }

  private handleBackClicked(): void {
    this.props.history.push(Routes.signIn);
  }

  private async handleSignUpClicked(): Promise<void> {
    const { t } = this.props;
    let userNameError,
      mailError,
      passwordError,
      passwordAssuranceError,
      termsAndConditionsError;
    if (this.state.userName.trim() === "") {
      userNameError = t("labels.sign-up.errors.user-name");
    }
    if (this.state.email.trim() === "") {
      mailError = t("labels.sign-up.errors.mail");
    }
    if (this.state.password !== this.state.passwordAssurance) {
      passwordError = t("labels.sign-up.errors.password-assurance");
      passwordAssuranceError = t("labels.sign-up.errors.password-assurance");
    }
    if (this.state.password.trim() === "") {
      passwordError = t("labels.sign-up.errors.no-password");
    }
    if (this.state.passwordAssurance.trim() === "") {
      passwordAssuranceError = t("labels.sign-up.errors.no-password-assurance");
    }
    if (this.state.checkedTermsAndService === false) {
      termsAndConditionsError = t("labels.sign-up.errors.terms-and-conditions");
    }
    this.setState({
      userNameError,
      mailError,
      passwordError,
      passwordAssuranceError,
      termsAndConditionsError,
    });
    if (
      userNameError != undefined ||
      mailError != undefined ||
      passwordError != undefined ||
      passwordAssuranceError != undefined ||
      termsAndConditionsError != undefined
    ) {
      return;
    }
    try {
      const response: IAuthenticationResponse = await this._authenticationService.register(
        this.state.userName,
        this.state.email,
        this.state.password,
      );
      const action: IAuthenticatedAction = {
        name: ActionNames.authenticatedAction,
        user: response.user,
        jwt: response.jwt,
      };
      this.props.store.dispatch<IAuthenticatedAction>(action);
      this.props.history.push(Routes.dashboard);
    } catch (e) {
      this._notificationCenter.sendNotification({
        notificationType: NotificationTypes.error,
        text: this.props.t("labels.sign-up.errors.failed"),
      });
    }
  }
}

export const Root = withApplicationStateStoreContext(
  withRouter(withTranslation()(withStyles(styles)(RootComponent))),
);
