import axios from "axios";
import { injectable } from "inversify";
import { ConfigService } from "../config-service";
import { IMachine } from "./IMachine";
import { IMachineCategory } from "./IMachineCategory";
import { IMachinesRepository } from "./IMachinesRepository";
import { INewMachine } from "./INewMachine";
import { IStatisticResponse } from "./IStatisticResponse";

@injectable()
export class MachinesRepository implements IMachinesRepository {
  public async getMachine(jwt: string, machineId: number): Promise<IMachine> {
    const url = `${ConfigService.get((x) => x.baseUrl)}/machines/${machineId}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
  }

  public async getStatistics(
    jwt: string,
    machineId: number,
    month: string,
  ): Promise<IStatisticResponse> {
    const url = `${ConfigService.get(
      (x) => x.baseUrl,
    )}/machines/${machineId}/${month}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
  }
  public async getMachinesCount(jwt: string, search: string): Promise<number> {
    const url = `${ConfigService.get(
      (x) => x.baseUrl,
    )}/machines/count?_q=${search}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
  }
  public async getMachines(
    jwt: string,
    currentPage: number,
    search: string,
    limit?: number,
  ): Promise<IMachine[]> {
    const limitQuery = limit != undefined ? limit : 100;
    const start = (currentPage - 1) * limitQuery;
    const url = `${ConfigService.get(
      (x) => x.baseUrl,
    )}/machines?_limit=${limitQuery}&_start=${start}&_q=${search}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
  }

  public async updateMachine(jwt: string, machine: IMachine): Promise<void> {
    const url = `${ConfigService.get((x) => x.baseUrl)}/machines/${machine.id}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.put(url, machine, { headers });
    return response.data;
  }

  public async postMachine(jwt: string, machine: INewMachine): Promise<void> {
    const url = `${ConfigService.get((x) => x.baseUrl)}/machines`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    await axios.post(url, machine, { headers });
  }

  public async getMachineCategories(jwt: string): Promise<IMachineCategory[]> {
    const url = `${ConfigService.get((x) => x.baseUrl)}/machine-categories`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
  }

  public async getMachineTypes(jwt: string): Promise<string[]> {
    const url = `${ConfigService.get((x) => x.baseUrl)}/machine-types`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
  }
  public async deleteMachine(machineId: number, jwt: string): Promise<void> {
    const url = `${ConfigService.get((x) => x.baseUrl)}/machines/${machineId}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    await axios.delete(url, { headers });
  }
}
