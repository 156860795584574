import { Theme, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: theme.palette.divider,
      borderRadius: theme.spacing(2),
      padding: theme.spacing(2),
    },
    rowSpaced: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
    },
    titleText: {
      opacity: 0.9,
      textAlign: "center",
    },
    text: {
      opacity: 0.8,
      textAlign: "center",
    },
  });

export interface IDialogBoxProps extends WithStyles, WithTranslation {
  readonly title: string;
}

class DialogBoxComponent extends React.PureComponent<IDialogBoxProps> {
  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant={"body1"} className={classes.titleText}>
          {this.props.title}
        </Typography>
        {this.props.children}
      </div>
    );
  }
}
export const DialogBox = withTranslation()(
  withStyles(styles)(DialogBoxComponent),
);
