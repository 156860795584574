import {
  createStyles,
  Paper,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ContentHeader, ProgressBar } from "../../components/dashboard";
import { DashboardOverview } from "../../components/dashboard/DashboardOverview";
import { BasePage } from "../../components/general/BasePage";
import { GoogleMap } from "../../components/maps";
import { Routes } from "../../components/navigation/Routes";
import {
  IApplicationStateStoreContextProps,
  withApplicationStateStoreContext,
} from "../../infrastructure/application-state";
import {
  IDashboardService,
  IMachineLocation,
} from "../../infrastructure/dashboard";
import { inject } from "../../infrastructure/injection";
import { ICoordinate } from "../../infrastructure/recordings";
import { TypeNames } from "../../infrastructure/TypeNames";

const styles = (theme: Theme) =>
  createStyles({
    mapContainer: {
      flex: 7,
      display: "flex",
      flexDirection: "column",
      zIndex: 2,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    head: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      paddingBottom: theme.spacing(3),
    },
    body: {
      flex: 16,
      display: "flex",
      flexDirection: "column",
    },
    overviewContainer: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      paddingBottom: theme.spacing(2),
    },
    infoBoxContainer: {
      flex: 3,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    progressContainer: {
      zIndex: 3,
      right: "5%",
      top: "25%",
      position: "absolute",
      width: "fit-content",
    },
  });

export type IRootProps = WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps &
  IApplicationStateStoreContextProps;

class RootState {
  public activeMachines: number = 0;
  public totalMachines: number = 0;
  public selectedMonth: string = moment.months()[moment().month() - 1];
  public months: string[] = moment.months();
  public tillage: number = 0;
  public grasslandCultivation: number = 0;
  public transports: number = 0;
  public operatingHours: number = 0;
  public records: number = 0;
  public machineLocation: IMachineLocation[] = [];
  public zoom = 7;
  public userLocation: ICoordinate = { longitude: 10.4515, latitude: 51.1657 };
}

class RootComponent extends React.Component<IRootProps, RootState> {
  @inject(TypeNames.dashboardService)
  private readonly _dashboardService: IDashboardService;

  public constructor(props: IRootProps) {
    super(props);
    this.state = new RootState();
  }

  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <BasePage
        currentBaseRoute={Routes.dashboard}
        onRouteClick={(newRoute: Routes) => this.props.history.push(newRoute)}>
        <div className={classes.head}>
          <ContentHeader
            iconProps={{
              isIcon: true,
            }}
            title={t("labels.dashboard.title")}
          />
        </div>
        <div className={classes.body}>
          <div className={classes.overviewContainer}>
            <DashboardOverview
              tillage={this.state.tillage}
              operatingHours={this.state.operatingHours}
              records={this.state.records}
              transports={this.state.transports}
              grasslandCultivation={this.state.grasslandCultivation}
            />
          </div>
          <div className={classes.mapContainer}>
            <Paper elevation={1} className={classes.progressContainer}>
              <ProgressBar
                height={15}
                activeMachines={this.state.activeMachines}
                totalMachines={this.state.totalMachines}
              />
            </Paper>
            <GoogleMap
              markers={this.state.machineLocation}
              mapCenter={this.state.userLocation}
              zoom={this.state.zoom}
            />
          </div>
        </div>
      </BasePage>
    );
  }
  public async componentDidMount(): Promise<void> {
    if (this.props.store.state.jwt == undefined) {
      return;
    }
    const dashboardStatistics = await this._dashboardService.getDashboardStatistics(
      this.props.store.state.jwt,
      this.state.selectedMonth,
    );
    const location = await this._dashboardService.getMachineLocation(
      this.props.store.state.jwt,
    );
    this.setState({
      activeMachines: dashboardStatistics.activeMachines,
      grasslandCultivation: dashboardStatistics.grasslandCultivation,
      operatingHours: dashboardStatistics.operatingHours,
      records: dashboardStatistics.records,
      tillage: dashboardStatistics.tillage,
      transports: dashboardStatistics.transports,
      totalMachines: dashboardStatistics.totalMachines,
      machineLocation: location,
    });
  }
}

export const Root = withApplicationStateStoreContext(
  withRouter(withTranslation()(withStyles(styles)(RootComponent))),
);
