import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ITimelineItem } from "../../infrastructure/timeline/ITimelineItem";
import { TimelineItem } from "./TimelineItem";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(3),
    },
    container: {
      flex: 1,
      display: "flex",
    },
  });

interface ITimelineProps extends WithStyles<typeof styles>, WithTranslation {
  readonly timelineItem: ITimelineItem;
  readonly isTransport: boolean;
  readonly currentPage: number;
  readonly maxPages: number;
  readonly onNewPagePress: (page: number) => void;
}

class TimelineComponent extends React.PureComponent<ITimelineProps> {
  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <TimelineItem
            item={this.props.timelineItem}
            isTransport={this.props.isTransport}
            currentPage={this.props.currentPage}
            maxPages={this.props.maxPages}
            onNewPagePress={(page: number) => this.props.onNewPagePress(page)}
          />
        </div>
      </div>
    );
  }
}

export const Timeline = withTranslation()(
  withStyles(styles)(TimelineComponent),
);
