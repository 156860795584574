module.exports = {
  "cultureCode": {
    "value": "en"
  },
  "translation": {
    "labels": {
      "language": {
        "german": "German",
        "english": "English"
      },
      "machine-types": {
        "frontPacker": "Front Packer",
        "cultivator": "Cultivator",
        "weeder": "Weeder",
        "rotaryHarrow": "Rotary Harrow",
        "lightCultivator": "Light Cultivator",
        "cornHoe": "Corn Hoe",
        "rotaryMulcher": "Rotary Mulcher",
        "plow": "Plow",
        "sowingCombination": "Sowing Combination",
        "discHarrow": "Disc Harrow",
        "roller": "Roller",
        "rotaryTedder": "Rotary Tedder",
        "mower": "Mower",
        "swatherRake": "Swather Rake",
        "grasslandHarrow": "Grassland Harrow",
        "pushOffTrailer": "Push-off Trailer",
        "slurryTanker": "Slurry Tanker",
        "slurryTransport": "Slurry Transport",
        "loadingWagon": "Loading Wagon",
        "manureSpreader": "Manure Spreader"
      },
      "machine-categories": {
        "tillage": "Tillage",
        "grasslandCultivation": "Grassland Cultivation",
        "transport": "Transport"
      },
      "machine-states": {
        "active": "Active",
        "inactive": "Inactive",
        "archived": "Archived"
      },
      "sign-in": {
        "mail": "E-Mail address",
        "password": "Password",
        "header-title": "Login",
        "no-account": "No account yet?",
        "title": "START MACHINE TRACKING",
        "subtitle": "Maintain an overview and track your machine movements",
        "errors": {
          "failed": "Login failed. Please check your information",
          "mail": "Please enter a valid E-Mail address",
          "password": "Please enter a password"
        }
      },
      "sidebar": {
        "welcome": "Welcome back,"
      },
      "dashboard": {
        "title": "Dashboard",
        "active-machines": "Active Machines",
        "overview": "Overview",
        "tillage": "Tillage",
        "grassland-cultivation": "Grassland cultivation",
        "transports": "Transports",
        "operating-hours": "Operating hours",
        "records": "Records",
        "marker-information": "The red markers on the overview map represent the last transmitted location of a machine."
      },
      "recordings": {
        "title": "Recordings",
        "filter": "Filter",
        "machine-type": "Maschine-type",
        "page": "Page: ",
        "select": {
          "show-all": "Show all",
          "show-less": "Show less",
          "none": "None"
        },
        "button-title": "Add Recording",
        "search": "Search",
        "search-placeholder": "Search for id or machine",
        "time": "hr",
        "table-headers": {
          "ids": "ID",
          "machine": "Machine",
          "fields": "Transports | Fields",
          "area": "Area",
          "date": "Date",
          "action": "Action",
          "all": "All",
          "processed": "Processed",
          "field-area": "Field Area",
          "deviation": "Deviation",
          "search": "Search...",
          "status": "Status",
          "all-values": "Show all values (Transports | Fields 0)"
        },
        "actions": {
          "finished": "Finished",
          "analyzing": "Analyzing",
          "open": "Open"
        },
        "recordings-detailed": {
          "recalculate": "Recalculate"
        },
        "card": {
          "processed": "Processed",
          "processed-value": "{{value}} ha",
          "field-area": "Field area",
          "field-area-value": "{{value}} ha",
          "placeholder": "No fields found for selected recording"
        },
        "info": {
          "title": "Info",
          "machine-type": "Machine TYP",
          "name": "Name",
          "recording-begin": "Recording begin",
          "recording-end": "Recording end",
          "duration": "Total duration",
          "duration-value": "{{hours}}h {{minutes}}m",
          "area-processed": "Processed area",
          "area-processed-value": "{{value}} ha",
          "area-total": "Total area",
          "area-total-value": "{{value}} ha",
          "fields": "Fields",
          "share": "Share",
          "copy-link": "Copy link"
        }
      },
      "recordings-detailed": {
        "title": "Recordings - Info",
        "subtitle": "Recordings > Info",
        "print": "Print",
        "copy-link": "Copy link",
        "recording-info": {
          "title": "Recording - Information",
          "machine-id": "Machine ID",
          "machine-type": "Machine TYPE",
          "name": "Name",
          "recording-begin": "Recording beginning",
          "recording-end": "Recording end",
          "total-duration": "Total duration",
          "total-duration-fields": "Total duration on fields",
          "fields": "Fields",
          "area-processed": "Area processed",
          "area-processed-value": "{{value}} ha",
          "area": "Total area (Field borders)",
          "area-value": "{{value}} ha",
          "transports": "Fuhren"
        },
        "field-info": {
          "title": "Field information",
          "field-entrance": "Field entrance",
          "field-exit": "Field exit",
          "work-duration": "Work duration",
          "field-size": "Size by Field borders",
          "field-size-value": "{{value}} ha",
          "processed-area": "Processed area",
          "processed-area-value": "{{value}} ha"
        },
        "google-maps": {
          "path": "path",
          "area": "Area",
          "calculate": "Calculate",
          "dialog-area-info": "Your calculated area amounts to: ",
          "dialog-distance-info": "The distance between the points amounts to: "
        }
      },
      "recalculation-dialog": {
        "title": "Recordings - Recalculation",
        "total-area": "Total area",
        "single-area": "Single areas",
        "processed": "Processed",
        "processed-value": "{{value}} ha",
        "area": "Field area",
        "area-value": "{{value}} ha",
        "information": "Adjust parameters to correct errors in the calculation",
        "sliders": {
          "min-distance": "Maximum distance between lanes (Dist)",
          "min-length": "Minimum lane length (Lng)",
          "max-curvature": "Maximum angular deviation from a straight lane (Slp)",
          "radius": "Headland acceptance radius (Crad)"
        }
      },
      "machines": {
        "title": "Machines",
        "filter": "Filter",
        "no-movement": "None",
        "search-placeholder": "Search for id, typ or name",
        "machine-type": "Machine type",
        "machine-table": {
          "ids": "ID",
          "machine-type": "Machine type",
          "name": "Name",
          "state": "State",
          "total-area": "Total Area",
          "last-moved": "Last moved",
          "action": "Action"
        }
      },
      "create-machine": {
        "gps-tracker-title": "GPS Tracker",
        "gps-serial-number": "Serial number",
        "machine-title": "Machine",
        "machine-stock-number": "Stock number",
        "machine-name": "Name",
        "machine-work-width": "Work width (cm)",
        "errors": {
          "failed": "Something went wrong while trying to create machine"
        }
      },
      "machines-administration": {
        "title": "Machine administration",
        "profile": {
          "title": "Machine Profile",
          "name": "Name",
          "stock-number": "Machine stock number",
          "device-id": "Device ID (Serial number)",
          "category": "Machine Category",
          "type": "Machine Type",
          "work-width": "Work width",
          "work-width-value": "{{value}} m",
          "sleep-timer": "Sleep Timer (min. 120)",
          "sleep-timer-value": "{{value}} Seconds",
          "device-information": "Device Information",
          "firmware-version": "Firmware Version",
          "iccid": "ICCID",
          "info": "Welcome to the machine management, use the machine profile to register a GPS tracker. (Important! Be sure to state the machine category, machine type and working width that match the machine)"
        },
        "statistics": {
          "title": "Machine- Statistic",
          "operating-hours": "Operating hours",
          "operating-hours-value": "{{hours}} hours",
          "traveled-distance": "Traveled distance",
          "traveled-distance-value": "{{value}} Km",
          "area-processed": "Area processed (Calculated)",
          "area-processed-value": "{{value}} ha",
          "field-area-total": "Total field area (Field borders)",
          "field-area-total-value": "{{value}} ha"
        },
        "geoFence": {
          "title": "Geofence",
          "radius": "Radius in meter",
          "add-button": "Add Geofence",
          "tooltip": "Mark parking position of the machine + safety radius",
          "success-message": "Geofence successfully created",
          "error-message": "Geofence could not be created"
        }
      },
      "alerts": {
        "title": "Alert",
        "filter": "Filter",
        "machine-type": "Machine type",
        "info": {
          "batterylow": "Critical battery level!"
        },
        "machine-table": {
          "ids": "Machine ID",
          "machine-type": "Machine type",
          "name": "Machine name",
          "state": "State",
          "info": "Info",
          "date": "Date",
          "action": "Action"
        }
      },
      "history": {
        "title": "History",
        "path": "History",
        "search": "Search",
        "machine-information": "Machine Information",
        "name": "Name:",
        "machine-nr": "Machine Nr.:",
        "machine-id": "Machine Id:",
        "machine-category": "Machine category:",
        "machine-type": "Machine type:",
        "work-width": "Work width:",
        "work-width-value": "{{value}} cm",
        "end": "End",
        "from": "From",
        "until": "Until",
        "search-placeholder": "Search for machine name",
        "timeline": {
          "no-entry": "No entry found.",
          "left-parking-space": "Left parking space",
          "enter-parking-space": "Enter parking space",
          "absent": "Absent parking space (Geofence)",
          "field-entry": "Field entry",
          "field-exit": "Field exit",
          "field": "Field",
          "fields": "Fields",
          "transports": "Transports",
          "upload": "Upload recording",
          "max-surface": "Total area",
          "surface": "Area",
          "processed": "Processed",
          "area": "Field boundaries",
          "hektar": "Hectares",
          "start-recording": "Start recording",
          "end-recording": "End recording",
          "max-time": "Total time",
          "work-time": "Working hours"
        }
      },
      "sign-up": {
        "title": "Register",
        "username": "User name",
        "mail": "Email",
        "password": "Password",
        "password-assurance": "Password assurance",
        "terms-and-conditions": "I agree to the Terms and Conditions",
        "has-account": "Have an account?",
        "errors": {
          "failed": "Something went wrong while trying to register",
          "user-name": "Please enter a Username",
          "mail": "Please enter a valid E-Mail address",
          "no-password": "Please enter a Password",
          "no-password-assurance": "Please repeat your Password",
          "password-assurance": "Your entered Passwords don´t match",
          "terms-and-conditions": "Please accept the Terms and Conditions"
        }
      },
      "request-password-recovery": {
        "title": "Restore password",
        "mail": "Email address",
        "success": "You should receive an e-mail shortly to change your password",
        "errors": {
          "failed": "An error occurred while trying to request a password reset.",
          "mail": "Please enter a valid E-Mail address"
        }
      },
      "password-recovery": {
        "title": "New password",
        "password": "Password",
        "password-assurance": "Password assurance",
        "errors": {
          "failed": "Something went wrong while trying to recover the password",
          "no-password": "Please enter a Password",
          "no-password-assurance": "Please repeat your Password",
          "password-assurance": "Your entered Passwords don´t match"
        }
      },
      "create-machine-dialog": {
        "title": "Create machine"
      }
    },
    "actions": {
      "header": {
        "sign-out": "Sign out",
        "language": {
          "german": "German",
          "english": "English"
        }
      },
      "sidebar": {
        "dashboard": "Dashboard",
        "recordings": "Recordings",
        "machines": "Machines",
        "history": "History",
        "alerts": "Alerts",
        "miscellaneous": "Miscellaneous",
        "logout": "Logout"
      },
      "sign-in": {
        "sign-in": "Login",
        "sign-in-now": "Join Now",
        "recover-password": "Forgot password",
        "register-device": "Register device"
      },
      "sign-up": {
        "register": "Register",
        "click-here": "Click here"
      },
      "request-password-recovery": {
        "recover": "Reset password",
        "go-back": "To sign in"
      },
      "password-recovery": {
        "recover": "Reset password"
      },
      "dashboard": {
        "administration": "Edit",
        "history": "History",
        "google-maps": {
          "title": "Machine-ID:"
        }
      },
      "recordings": {
        "table-actions": {
          "delete": "Delete"
        }
      },
      "recordings-actions": {
        "detailed": "Edit",
        "delete": "Delete"
      },
      "recordings-detailed": {
        "export": "Export",
        "export-csv": "Excel CSV",
        "export-link": "Copy Link",
        "recalculate": "Recalculate"
      },
      "recalculation-dialog": {
        "recalculate": "Recalculate"
      },
      "machine-actions": {
        "administration": "Edit",
        "delete": "Delete"
      },
      "machines-administration": {
        "history": "History",
        "statistic-types": {
          "operating-hours": "Operating hours",
          "area-processed": "Processed area",
          "field-area": "Total field area"
        }
      },
      "machines": {
        "create": "Create machine"
      },
      "create-machine": {
        "create": "Create machine",
        "cancel": "Cancel"
      }
    }
  }
}
;