import { LinearProgress, Theme, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.paper,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    text: {
      alignSelf: "center",
      textAlign: "center",
    },
    progressBar: {
      width: 300,
      paddingTop: theme.spacing(1),
    },
    titleContainer: {
      flex: 5,
      display: "flex",
      justifyContent: "flex-start",
      paddingBottom: theme.spacing(1),
    },
    activeMachinesContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      paddingBottom: theme.spacing(1),
    },
  });

export interface IProgressBarProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly activeMachines: number;
  readonly totalMachines: number;
  readonly height: number;
}

class ProgressBarComponent extends React.Component<IProgressBarProps> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.row}>
          <div className={classes.titleContainer}>
            <Typography className={classes.text} variant={"subtitle2"}>
              {t("labels.dashboard.active-machines")}
            </Typography>
          </div>
          <div className={classes.activeMachinesContainer}>
            <Typography className={classes.text} variant={"h4"}>
              {`${this.props.activeMachines}/${this.props.totalMachines}`}
            </Typography>
          </div>
        </div>
        <LinearProgress
          color={"primary"}
          style={{ height: this.props.height }}
          variant="determinate"
          className={classes.progressBar}
          value={(this.props.activeMachines * 100) / this.props.totalMachines}
        />
      </div>
    );
  }
}
export const ProgressBar = withTranslation()(
  withStyles(styles)(ProgressBarComponent),
);
