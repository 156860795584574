import { MenuItem, Select, Theme, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Search } from "../general/Search";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(2, 1, 0, 1),
    },
    overviewText: {
      opacity: 0.8,
      textAlign: "center",
    },
    title: {
      opacity: 0.6,
      textAlign: "center",
    },
  });

export interface IRecordingsOverviewProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly selectedMachineType: string;
  readonly machineTypes: string[];
  readonly search: string;
  readonly onMachineChanged: (value: string) => void;
  readonly onSearchChanged: (value: string) => void;
}

class RecordingsOverviewComponent extends React.PureComponent<
  IRecordingsOverviewProps
> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.title} variant={"h6"}>
          {t("labels.recordings.filter")}
        </Typography>
        <Typography className={classes.overviewText} variant={"body1"}>
          {t("labels.recordings.machine-type")}
        </Typography>
        <Select
          className={classes.overviewText}
          value={this.props.selectedMachineType}
          onChange={(event) =>
            this.props.onMachineChanged(event.target.value as string)
          }>
          {this.renderMachineTypes()}
        </Select>
        <Search
          value={this.props.search}
          placeholder={t("labels.recordings.search")}
          onChange={(value) => this.props.onSearchChanged(value)}
        />
      </div>
    );
  }

  private renderMachineTypes(): React.ReactNode {
    const { t } = this.props;
    return this.props.machineTypes.map((x) => (
      <MenuItem color={"secondary"} value={x}>
        {t(`labels.machine-types.${x}`)}
      </MenuItem>
    ));
  }
}
export const RecordingsOverview = withTranslation()(
  withStyles(styles)(RecordingsOverviewComponent),
);
