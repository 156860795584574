import * as React from "react";
import { IStore } from "@econsor-mobile/flux";
import { ApplicationStateStoreContext } from "./ApplicationStateStoreContext";
import { IApplicationState } from "./IApplicationState";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IApplicationStoreContextConsumerProps {
  store: IStore<IApplicationState>;
}

type ComponentType =
  | React.ComponentClass<any & IApplicationStoreContextConsumerProps>
  | React.FunctionComponent<any & IApplicationStoreContextConsumerProps>;

export const withApplicationStateStoreContext = (Component: ComponentType) => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return <TSourceComponentProps extends any>(
    sourceComponentProps: TSourceComponentProps,
  ) => {
    return (
      <ApplicationStateStoreContext.Consumer>
        {(store: IStore<IApplicationState>) => {
          return <Component {...sourceComponentProps} store={store} />;
        }}
      </ApplicationStateStoreContext.Consumer>
    );
  };
};
