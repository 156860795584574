import * as React from "react";
import { IStore, Store } from "@econsor-mobile/flux";
import { ApplicationReducer } from "./ApplicationReducer";
import { IApplicationState } from "./IApplicationState";

const jwt = window.localStorage.getItem("jwt");
const user = window.localStorage.getItem("user");

const store = new Store<IApplicationState>(
  { user: user != undefined ? JSON.parse(user) : undefined, jwt: jwt || "" },
  new ApplicationReducer(),
);

export const ApplicationStateStoreContext = React.createContext<
  IStore<IApplicationState>
>(store);
