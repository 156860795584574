import {
  createStyles,
  MenuItem,
  Select,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

interface IEnumDropdownProps<T>
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly enumT: T;
  readonly value: T;
  readonly onChange: (value: T) => void;
  readonly translationsPath: string;
}

const styles = () =>
  createStyles({
    root: {
      display: "flex",
    },
  });

class EnumDropdownComponent<T> extends React.Component<IEnumDropdownProps<T>> {
  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Select
          value={this.props.value}
          onChange={(event) => this.props.onChange(event.target.value as T)}>
          {this.renderMenuItems()}
        </Select>
      </div>
    );
  }
  private renderMenuItems(): React.ReactNode {
    const { t } = this.props;
    return Object.keys(this.props.enumT).map((key, index) => {
      const value = Object(this.props.enumT)[key as keyof T];
      return (
        <MenuItem value={value} key={index}>
          {t(`${this.props.translationsPath}.${value}`)}
        </MenuItem>
      );
    });
  }
}

export const EnumDropdown = withTranslation()(
  withStyles(styles)(EnumDropdownComponent),
);
