import {
  Button,
  createStyles,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require("../../resources/images/logo.jpg");
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "30%",
      height: "100%",
      alignSelf: "center",
      flexDirection: "column",
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
    },
    componentContainer: {
      padding: theme.spacing(5, 2),
    },
    logoContainer: {
      display: "flex",
      height: "12%",
      width: "70%",
      alignSelf: "center",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: theme.spacing(30),
    },
    image: {
      resizeMode: "contain",
      height: "100%",
      width: "100%",
    },
    title: {
      textAlign: "center",
    },
  });

export interface IRequestPasswordRecoveryFormProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly email: string;
  readonly onEmailChange: (value: string) => void;
  readonly onRequestRecoveryClick: () => void;
  readonly onBackButtonClick: () => void;
  readonly emailError?: string;
}

export class RequestPasswordRecoveryFormComponent extends React.PureComponent<
  IRequestPasswordRecoveryFormProps
> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.logoContainer}>
          <img className={classes.image} src={logo} />
        </div>
        <Typography className={classes.title} variant={"h4"}>
          {t("labels.request-password-recovery.title")}
        </Typography>
        <div className={classes.componentContainer}>
          <TextField
            variant={"outlined"}
            required
            fullWidth
            type={"email"}
            label={t("labels.request-password-recovery.mail")}
            value={this.props.email}
            onChange={(event) => this.props.onEmailChange(event.target.value)}
            error={this.props.emailError != undefined}
            helperText={this.props.emailError}
          />
        </div>
        <div className={classes.componentContainer}>
          <Button
            fullWidth
            color={"primary"}
            variant={"contained"}
            onClick={() => this.props.onRequestRecoveryClick()}>
            {t("actions.request-password-recovery.recover")}
          </Button>
        </div>
        <Button variant={"text"} onClick={() => this.props.onBackButtonClick()}>
          {t("actions.request-password-recovery.go-back")}
        </Button>
      </div>
    );
  }
}

export const RequestPasswordRecoveryForm = withTranslation()(
  withStyles(styles)(RequestPasswordRecoveryFormComponent),
);
