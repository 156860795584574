import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { AlertsTable } from "../../components/alerts";
import { ContentHeader } from "../../components/dashboard";
import { BasePage } from "../../components/general/BasePage";
import { Routes } from "../../components/navigation/Routes";
import { IAlert, IAlertsService } from "../../infrastructure/alerts";
import {
  IApplicationStoreContextConsumerProps,
  withApplicationStateStoreContext,
} from "../../infrastructure/application-state";
import { inject } from "../../infrastructure/injection";
import { TypeNames } from "../../infrastructure/TypeNames";

const styles = (theme: Theme) =>
  createStyles({
    head: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      paddingBottom: theme.spacing(3),
    },
    body: {
      flex: 16,
      display: "flex",
      flexDirection: "column",
    },
  });

export type IRootProps = RouteComponentProps &
  WithStyles<typeof styles> &
  WithTranslation &
  IApplicationStoreContextConsumerProps;

class RootState {
  public alerts: IAlert[] = [];
  public isMachineActionMenuOpen: boolean = false;
  public selectedAlertIds: number[] = [];
  public isMenuOpen: boolean = false;
}

class RootComponent extends React.Component<IRootProps, RootState> {
  @inject(TypeNames.alertsService)
  private readonly _alertsService: IAlertsService;

  public constructor(props: IRootProps) {
    super(props);
    this.state = new RootState();
  }

  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <BasePage
        currentBaseRoute={Routes.alerts}
        onRouteClick={(newRoute: Routes) => this.props.history.push(newRoute)}>
        <div className={classes.head}>
          <ContentHeader
            iconProps={{
              isIcon: true,
            }}
            title={t("labels.alerts.title")}
          />
        </div>
        <div className={classes.body}>
          <AlertsTable
            alerts={this.state.alerts}
            selectedAlertIds={this.state.selectedAlertIds}
            onAllMenuClicked={(open: boolean) =>
              this.setState({ isMenuOpen: open })
            }
            onDeleteClick={() => this.handleOnDeleteClicked()}
            isAllMenuOpen={this.state.isMenuOpen}
            onSelectClick={(item, id) => this.handleSelectClicked(item, id)}
            onSelectAllClick={() => this.handleSelectAllClicked()}
          />
        </div>
      </BasePage>
    );
  }

  public async componentDidMount(): Promise<void> {
    await this.getAlerts();
  }

  private async getAlerts(): Promise<void> {
    if (this.props.store.state.jwt != undefined) {
      const alerts = await this._alertsService.getAlerts(
        this.props.store.state.jwt,
      );
      this.setState({ alerts });
    }
  }

  private handleSelectAllClicked(): void {
    if (this.state.selectedAlertIds.length === this.state.alerts.length) {
      this.setState({ selectedAlertIds: [] });
    } else {
      const selectedAlertIds = this.state.alerts.map((item) => item.id);
      this.setState({ selectedAlertIds });
    }
  }

  private handleSelectClicked(itemId: number, checked: boolean): void {
    if (checked === true) {
      const selectedAlertIds = [...this.state.selectedAlertIds, itemId];
      this.setState({ selectedAlertIds });
    } else {
      const selectedAlertIds = this.state.selectedAlertIds.filter(
        (id) => id != itemId,
      );
      this.setState({ selectedAlertIds });
    }
  }

  private async handleOnDeleteClicked(): Promise<void> {
    if (this.props.store.state.jwt != undefined) {
      const jwt: string = this.props.store.state.jwt;
      for (const x of this.state.selectedAlertIds) {
        await this._alertsService.deleteAlert(jwt, x);
      }
      this.setState({ selectedAlertIds: [], isMenuOpen: false });
      this.getAlerts();
    }
  }
}

export const Root = withApplicationStateStoreContext(
  withTranslation()(withStyles(styles)(RootComponent)),
);
