import { injectable } from "inversify";
import { IMachinesFactory } from "./IMachinesFactory";
import { IStatistic } from "./IStatistic";
import { IStatisticResponse } from "./IStatisticResponse";

@injectable()
export class MachinesFactory implements IMachinesFactory {
  public statistics(statistics: IStatisticResponse, month: string): IStatistic {
    return {
      ...statistics,
      barChartOperatingHours: [
        {
          value: statistics.operatingHours,
          key: month,
        },
      ],
      barChartAreaProcessed: [
        {
          value: statistics.areaProcessed,
          key: month,
        },
      ],
      barChartTotalFieldArea: [
        {
          value: statistics.totalFieldArea,
          key: month,
        },
      ],
    };
  }
}
