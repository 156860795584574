/* eslint-disable @typescript-eslint/no-explicit-any */
import { interfaces } from "inversify";
import { InjectionContainerLocator } from "./InjectionContainerLocator";

function applyInject(
  serviceIdentifier: interfaces.ServiceIdentifier<any>,
): (target: any, name: string, descriptor?: any) => void {
  return function (target: any, name: string): any {
    Object.defineProperty(target, name, {
      enumerable: true,
      get(): any {
        return InjectionContainerLocator.get().get(serviceIdentifier);
      },
    });
  };
}

// tslint:disable-next-line
export function inject(
  serviceIdentifier: interfaces.ServiceIdentifier<any>,
): (target: any, name: string, descriptor?: any) => void {
  return applyInject(serviceIdentifier);
}
