import { CircularProgress, Theme } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
  });

export type ILoadingIndicatorProps = WithStyles;

class LoadingIndicatorComponent extends React.PureComponent<
  ILoadingIndicatorProps
> {
  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CircularProgress color="primary" />
      </div>
    );
  }
}
export const LoadingIndicator = withStyles(styles)(LoadingIndicatorComponent);
