import { INotificationCenter } from "./INotificationCenter";
import { INotificationData } from "./INotificationData";
import { injectable } from "inversify";

@injectable()
export class NotificationCenter implements INotificationCenter {
  private subs: Array<(data: INotificationData) => void> = [];
  public sendNotification(data: INotificationData): void {
    this.subs.forEach((sub) => sub(data));
  }
  public subscribe(callback: (data: INotificationData) => void): () => void {
    this.subs.push(callback);
    return () => {
      const index = this.subs.findIndex((item) => item === callback);
      if (index > -1) {
        this.subs.splice(index, 1);
      }
    };
  }
}
