import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  FormControlLabel,
  Link,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import OpenInNew from "@material-ui/icons/OpenInNew";
import moment, { Duration, duration, Moment } from "moment";
import "moment/locale/de";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IMachine } from "../../infrastructure/machines";
import { IRecording } from "../../infrastructure/recordings";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Routes } from "../navigation/Routes";
import { RouteComponentProps, withRouter } from "react-router-dom";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
    },
    summary: {
      flexDirection: "column",
      flex: 1,
      display: "flex",
    },
    details: {
      flexDirection: "column",
      flex: 1,
      display: "flex",
    },
    head: {
      flex: 5,
      display: "flex",
      paddingBottom: theme.spacing(3),
      justifyContent: "center",
      alignItems: "center",
    },
    body: {
      flex: 5,
      display: "flex",
      flexDirection: "column",
    },
    rowSpaced: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
    },
    text: {
      flex: 1,
      display: "flex",
      alignSelf: "center",
      padding: theme.spacing(0, 2),
    },
    idText: {
      marginRight: 20,
    },
    idContainer: {
      display: "flex",
      flex: 1,
      alignSelf: "center",
      padding: theme.spacing(0, 2),
    },
    title: {
      color: theme.palette.primary.main,
    },
    faded: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      alignSelf: "center",
    },
    override: {
      "&.MuiAccordionSummary-content.Mui-expanded": {
        margin: 0,
      },
    },
    expandIcon: {
      alignSelf: "start",
    },
  });

interface IRecordingDetailedInfoProps
  extends WithStyles<typeof styles>,
    WithTranslation,
    RouteComponentProps {
  readonly recording?: IRecording;
}

class RecordingDetailedInfoComponent extends React.Component<
  IRecordingDetailedInfoProps
> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    moment.locale(this.props.i18n.language);
    moment.relativeTimeRounding((t) => {
      const DIGITS = 2; // like: 2.56 minutes
      return Math.round(t * Math.pow(10, DIGITS)) / Math.pow(10, DIGITS);
    });
    if (this.props.recording == undefined) {
      return false;
    }
    let totalArea = 0;
    let areaProcessed = 0;
    this.props.recording.fields.forEach((field) => {
      totalArea += field.fieldArea;
      areaProcessed += field.processedArea;
    });
    return (
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            content: classes.override,
            expandIcon: classes.expandIcon,
          }}>
          <div className={classes.summary}>
            <div className={classes.head}>
              <Typography className={classes.title} variant={"h5"}>
                {t("labels.recordings-detailed.recording-info.title")}
              </Typography>
            </div>
            <div className={classes.body}>
              <div className={classes.rowSpaced}>
                <Typography className={classes.faded} variant={"caption"}>
                  {t("labels.recordings-detailed.recording-info.machine-id")}
                </Typography>
                <div className={classes.idContainer}>
                  <Typography className={classes.idText} variant={"subtitle2"}>
                    {
                      (this.props.recording.machine as IMachine).gpsTracker
                        ?.serialNumber
                    }
                  </Typography>
                  <Link>
                    <a
                      href={Routes.machinesAdministration.replace(
                        ":machineId",
                        (this.props.recording
                          ?.machine as IMachine).id.toString(),
                      )}>
                      <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <OpenInNew
                            onClick={() => {
                              const route = Routes.machinesAdministration.replace(
                                ":machineId",
                                (this.props.recording
                                  ?.machine as IMachine).id.toString(),
                              );
                              this.props.history.push(route);
                            }}
                          />
                        }
                        label=""
                      />
                    </a>
                  </Link>
                </div>
              </div>
              <div className={classes.rowSpaced}>
                <Typography className={classes.faded} variant={"caption"}>
                  {t("labels.recordings-detailed.recording-info.machine-type")}
                </Typography>
                <Typography className={classes.text} variant={"subtitle2"}>
                  {t(
                    "labels.machine-types." +
                      (this.props.recording.machine as IMachine).type,
                  )}
                </Typography>
              </div>
              <div className={classes.rowSpaced}>
                <Typography className={classes.faded} variant={"caption"}>
                  {t("labels.recordings-detailed.recording-info.name")}
                </Typography>
                <Typography className={classes.text} variant={"subtitle2"}>
                  {(this.props.recording.machine as IMachine).name}
                </Typography>
              </div>
              <div className={classes.rowSpaced}>
                <Typography className={classes.faded} variant={"caption"}>
                  {t(
                    "labels.recordings-detailed.recording-info.recording-begin",
                  )}
                </Typography>
                <Typography className={classes.text} variant={"subtitle2"}>
                  {moment(this.props.recording.beginRecording).format(
                    "DD.MM.YYYY HH:mm",
                  )}
                </Typography>
              </div>
              <div className={classes.rowSpaced}>
                <Typography className={classes.faded} variant={"caption"}>
                  {t("labels.recordings-detailed.recording-info.recording-end")}
                </Typography>
                <Typography className={classes.text} variant={"subtitle2"}>
                  {moment(this.props.recording.endRecording).format(
                    "DD.MM.YYYY HH:mm",
                  )}
                </Typography>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.details}>
            <div className={classes.rowSpaced}>
              <Typography className={classes.faded} variant={"caption"}>
                {t("labels.recordings-detailed.recording-info.total-duration")}
              </Typography>
              <Typography className={classes.text} variant={"subtitle2"}>
                {this.getDuration(
                  moment(this.props.recording.beginRecording),
                  moment(this.props.recording.endRecording),
                ).humanize()}
              </Typography>
            </div>
            <div className={classes.rowSpaced}>
              <Typography className={classes.faded} variant={"caption"}>
                {t(
                  "labels.recordings-detailed.recording-info.total-duration-fields",
                )}
              </Typography>
              <Typography className={classes.text} variant={"subtitle2"}>
                {this.getDurationFields().humanize()}
              </Typography>
            </div>
            <div className={classes.rowSpaced}>
              <Typography className={classes.faded} variant={"caption"}>
                {t("labels.recordings-detailed.recording-info.fields")}
              </Typography>
              <Typography className={classes.text} variant={"subtitle2"}>
                {this.props.recording.fields.length}
              </Typography>
            </div>
            <div className={classes.rowSpaced}>
              <Typography className={classes.faded} variant={"caption"}>
                {t("labels.recordings-detailed.recording-info.area-processed")}
              </Typography>
              <Typography className={classes.text} variant={"subtitle2"}>
                {t(
                  "labels.recordings-detailed.recording-info.area-processed-value",
                  { value: areaProcessed.toFixed(2) },
                )}
              </Typography>
            </div>
            <div className={classes.rowSpaced}>
              <Typography className={classes.faded} variant={"caption"}>
                {t("labels.recordings-detailed.recording-info.area")}
              </Typography>
              <Typography className={classes.text} variant={"subtitle2"}>
                {t("labels.recordings-detailed.recording-info.area-value", {
                  value: totalArea.toFixed(2),
                })}
              </Typography>
            </div>
            <div className={classes.rowSpaced}>
              <Typography className={classes.faded} variant={"caption"}>
                {t("labels.recordings-detailed.recording-info.transports")}
              </Typography>
              <Typography className={classes.text} variant={"subtitle2"}>
                {this.props.recording.transports}
              </Typography>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  private getDuration(start: Moment, end: Moment): Duration {
    const durationTime = duration(moment(start).diff(end));
    return durationTime;
  }
  private getDurationFields(): Duration {
    const fieldsDuration = duration();
    this.props.recording?.fields.forEach((field) => {
      fieldsDuration.add(
        this.getDuration(moment(field.entryTime), moment(field.exitTime)),
      );
    });
    return fieldsDuration;
  }
}

export const RecordingDetailedInfo = withTranslation()(
  withRouter(withStyles(styles)(RecordingDetailedInfoComponent)),
);
