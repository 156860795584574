import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import GoogleMapReact, { MapOptions } from "google-map-react";
import React from "react";
import { ICoordinate } from "../../infrastructure/recordings";
import { MapType } from "./MapType";

const styles = () =>
  createStyles({
    map: {
      display: "flex",
      flex: 1,
      height: "100%",
    },
  });

export interface IGoogleAreaMapProps extends WithStyles<typeof styles> {
  readonly mapCenter: ICoordinate;
  readonly zoom: number;
  readonly onGoogleApiLoaded?: (map: MapType, maps: MapType) => void;
  readonly controlSize?: number;
}

class GoogleAreaMapState {
  public infoWindow: unknown;
  public map: MapType;
  public maps: MapType;
}

class GoogleAreaMapComponent extends React.Component<
  IGoogleAreaMapProps,
  GoogleAreaMapState
> {
  public constructor(props: IGoogleAreaMapProps) {
    super(props);
    this.state = new GoogleAreaMapState();
  }

  public render(): React.ReactNode {
    return (
      <div className={this.props.classes.map}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyA2hjxQAOQ8fxEjPXZRBNjxprEvq2zQA6o" }}
          center={{
            lat: this.props.mapCenter.latitude,
            lng: this.props.mapCenter.longitude,
          }}
          options={(maps) => this.getMapOptions(maps)}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
          defaultZoom={this.props.zoom}
        />
      </div>
    );
  }
  private async handleApiLoaded(map: MapType, maps: MapType): Promise<void> {
    this.props.onGoogleApiLoaded?.(map, maps);
    const infoWindow = new maps.InfoWindow();
    this.setState({ map, maps, infoWindow });
  }

  private getMapOptions(maps: MapType): MapOptions {
    return {
      zoomControl: false,
      rotateControl: false,
      scaleControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      gestureHandling: "none",
      keyboardShortcuts: false,
      controlSize: this.props.controlSize,
      mapTypeControl: false,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.DEFAULT,
        position: maps.ControlPosition.DEFAULT,
        mapTypeIds: [maps.MapTypeId.ROADMAP],
      },
    };
  }
}

export const GoogleAreaMap = withStyles(styles)(GoogleAreaMapComponent);
