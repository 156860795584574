import * as React from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from "react-router";
import { Route } from "react-router-dom";
import {
  IApplicationStoreContextConsumerProps,
  withApplicationStateStoreContext,
} from "../../infrastructure/application-state";
import { Routes } from "./Routes";

interface IPrivateRouteProps extends IApplicationStoreContextConsumerProps {
  readonly exact: boolean;
  readonly path: Routes;
  readonly component: // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  React.ComponentType<RouteComponentProps<any>> | React.ComponentType<unknown>;
}

class PrivateRouteComponent extends React.Component<IPrivateRouteProps> {
  private _unsubscribe: () => void;
  public render(): React.ReactNode {
    if (this.props.store.state.user == undefined) {
      return false;
    }
    return (
      <Route
        exact={this.props.exact}
        path={this.props.path}
        component={this.props.component}
      />
    );
  }
  public componentDidMount(): void {
    this._unsubscribe = this.props.store.subscribe(() =>
      this.handleApplicationStateStoreChanged(),
    );
  }

  public componentWillUnmount(): void {
    this._unsubscribe();
  }

  private handleApplicationStateStoreChanged(): void {
    this.forceUpdate();
  }
}

export const PrivateRoute = withApplicationStateStoreContext(
  PrivateRouteComponent,
);
