import {
  createStyles,
  Dialog,
  IconButton,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ICoordinate, IRecording } from "../../infrastructure/recordings";
import { GoogleMap } from "../maps";
import { MapType } from "../maps/MapType";
import { DialogBody } from "./DialogBody";
import { IFieldsAreaInformation } from "./IFieldsAreaInformation";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
    },
    body: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
    },
    infoContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    rowSpaced: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
    },
    mapContainer: {
      flex: 1,
      display: "flex",
      paddingBottom: theme.spacing(2),
    },
    dialogPaper: {
      height: "100%",
    },
    titleText: {
      textAlign: "center",
    },
    infoText: {
      paddingLeft: theme.spacing(2),
    },
  });

export interface IRecalculationDialogProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onMaxCurvatureChange: (value: number) => void;
  readonly onMinDistanceChange: (value: number) => void;
  readonly onMinLengthChange: (value: number) => void;
  readonly onRadiusChange: (value: number) => void;
  readonly onGoogleApiLoad: (map: MapType, maps: MapType) => void;
  readonly onRecalculateClick: () => void;
  readonly recording: IRecording;
  readonly minDistance: number;
  readonly minLength: number;
  readonly maxCurvature: number;
  readonly radius: number;
  readonly mapCenter: ICoordinate;
  readonly isLoading: boolean;
  readonly fieldArea: number;
  readonly processedArea: number;
  readonly fieldsAreaInformation: IFieldsAreaInformation[];
}

class RecalculationDialogState {
  public zoom = 13;
}

class RecalculationDialogComponent extends React.PureComponent<
  IRecalculationDialogProps,
  RecalculationDialogState
> {
  public constructor(props: IRecalculationDialogProps) {
    super(props);
    this.state = new RecalculationDialogState();
  }

  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <Dialog
        fullWidth
        open={this.props.isOpen}
        maxWidth={"xl"}
        PaperProps={{ className: classes.dialogPaper }}>
        <div className={classes.root}>
          <div className={classes.rowSpaced}>
            <Typography className={classes.titleText} variant={"body2"}>
              {t("labels.recalculation-dialog.title")}
            </Typography>
            <IconButton onClick={() => this.props.onClose()}>
              <Close fontSize={"large"} />
            </IconButton>
          </div>
          <div className={classes.mapContainer}>
            {this.props.isOpen && (
              <GoogleMap
                key={this.props.recording.updatedAt}
                mapCenter={this.props.mapCenter}
                zoom={this.state.zoom}
                onGoogleApiLoaded={(map, maps) =>
                  this.props.onGoogleApiLoad(map, maps)
                }
              />
            )}
          </div>
          <div className={classes.body}>
            <DialogBody
              fieldsAreaInformation={this.props.fieldsAreaInformation}
              isLoading={this.props.isLoading}
              onRecalculateClick={() => this.props.onRecalculateClick()}
              totalArea={this.props.fieldArea}
              totalProcessed={this.props.processedArea}
              maxCurvature={this.props.maxCurvature}
              minDistance={this.props.minDistance}
              minLength={this.props.minLength}
              radius={this.props.radius}
              onMaxCurvatureChange={(value) =>
                this.props.onMaxCurvatureChange(value)
              }
              onMinDistanceChange={(value) =>
                this.props.onMinDistanceChange(value)
              }
              onMinLengthChange={(value) => this.props.onMinLengthChange(value)}
              onRadiusChange={(value) => this.props.onRadiusChange(value)}
            />
          </div>
          <div className={classes.infoContainer}></div>
        </div>
      </Dialog>
    );
  }
}

export const RecalculationDialog = withTranslation()(
  withStyles(styles)(RecalculationDialogComponent),
);
