import { injectable } from "inversify";
import { IAuthenticationResponse } from "./IAuthenticationResponse";
import { IAuthenticationService } from "./IAuthenticationService";
import axios from "axios";
import { ConfigService } from "../config-service";

@injectable()
export class AuthenticationService implements IAuthenticationService {
  public async authenticate(
    mail: string,
    password: string,
  ): Promise<IAuthenticationResponse> {
    const url = `${ConfigService.get((x) => x.baseUrl)}/auth/local`;
    const body = {
      identifier: mail,
      password,
    };
    const { data } = await axios.post(url, body);
    return {
      jwt: data.jwt,
      user: {
        id: data.user.id,
        mail: data.user.email,
        name: data.user.username,
      },
    };
  }

  public async recoverPassword(email: string): Promise<void> {
    const url = `${ConfigService.get((x) => x.baseUrl)}/auth/forgot-password`;
    const body = {
      email,
    };
    await axios.post(url, body);
  }

  public async register(
    username: string,
    email: string,
    password: string,
  ): Promise<IAuthenticationResponse> {
    const url = `${ConfigService.get((x) => x.baseUrl)}/auth/local/register`;
    const body = {
      username,
      email,
      password,
    };
    const { data } = await axios.post(url, body);
    return {
      jwt: data.jwt,
      user: {
        id: data.user.id,
        mail: data.user.email,
        name: data.user.username,
      },
    };
  }

  public async resetPassword(
    password: string,
    passwordConfirmation: string,
    code: string,
  ): Promise<void> {
    const url = `${ConfigService.get((x) => x.baseUrl)}/auth/reset-password`;
    const body = {
      code,
      password,
      passwordConfirmation,
    };
    await axios.post(url, body);
  }
}
