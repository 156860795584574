import {
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  ICoordinate,
  ILine,
  IRecordingField,
} from "../../infrastructure/recordings";
import { GoogleAreaMap } from "../maps";
import { MapType } from "../maps/MapType";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
      padding: theme.spacing(0, 2, 2, 2),
    },
    mapContainer: {
      display: "flex",
      width: 275,
      height: 275,
      padding: theme.spacing(2),
    },
    text: {
      opacity: 0.8,
    },
  });

export interface IRecordingsCardProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly processed: number;
  readonly fieldArea: number;
  readonly zoom: number;
  readonly field: IRecordingField | undefined;
}

class RecordingsCardComponent extends React.PureComponent<
  IRecordingsCardProps
> {
  private map: MapType = null;
  private maps: MapType = null;

  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.mapContainer}>
          <GoogleAreaMap
            controlSize={1}
            mapCenter={this.getMapCenter()}
            zoom={this.props.zoom}
            onGoogleApiLoaded={(map, maps) =>
              this.handleGoogleApiLoaded(map, maps)
            }
          />
        </div>
        <div className={classes.row}>
          <Typography className={classes.text} variant={"subtitle2"}>
            {t("labels.recordings.card.processed")}
          </Typography>
          <Typography className={classes.text} variant={"h4"}>
            {t("labels.recordings.card.processed-value", {
              value: this.props.field?.processedArea,
            })}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.text} variant={"subtitle2"}>
            {t("labels.recordings.card.field-area")}
          </Typography>
          <Typography className={classes.text} variant={"h4"}>
            {t("labels.recordings.card.field-area-value", {
              value: this.props.field?.fieldArea,
            })}
          </Typography>
        </div>
      </div>
    );
  }

  public componentDidUpdate(): void {
    this.handleGoogleApiLoaded(this.map, this.maps);
  }

  private handleGoogleApiLoaded(map: MapType, maps: MapType): void {
    this.map = map;
    this.maps = maps;
    if (this.props.field != undefined) {
      RecordingsCardComponent.renderIndependentLines(
        this.props.field.greenLines,
        "#6ade09",
        maps,
        map,
      );
      RecordingsCardComponent.renderIndependentLines(
        this.props.field.pinkPoints,
        "#cb09de",
        maps,
        map,
        3,
      );
      RecordingsCardComponent.renderCircle(
        this.props.field.entryPoint,
        "#afaf00",
        maps,
        map,
      );
      RecordingsCardComponent.renderCircle(
        this.props.field.exitPoint,
        "#0000c1",
        maps,
        map,
      );
    }
  }

  private getMapCenter(): ICoordinate {
    if (this.props.field != undefined) {
      return this.props.field.centerPoint;
    }
    return {
      longitude: 1,
      latitude: 2,
    };
  }

  private static renderIndependentLines(
    lines: ILine[],
    color: string,
    maps: MapType,
    map: MapType,
    strokeWeight: number = 2,
  ): void {
    lines.forEach((line) => {
      const polyline = new maps.Polyline({
        path: [
          { lat: line.point1.latitude, lng: line.point1.longitude },
          { lat: line.point2.latitude, lng: line.point2.longitude },
        ],
        strokeColor: color,
        strokeOpacity: 1,
        strokeWeight,
      });
      polyline.setMap(map);
    });
  }

  private static renderCircle(
    circle: ICoordinate,
    color: string,
    maps: MapType,
    map: MapType,
  ): void {
    const poly = new maps.Circle({
      center: { lat: circle.latitude, lng: circle.longitude },
      strokeColor: color,
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 1,
      radius: 10,
    });
    poly.setMap(map);
  }
}

export const RecordingsCard = withTranslation()(
  withStyles(styles)(RecordingsCardComponent),
);
