import {
  Button,
  createStyles,
  Dialog,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IMachineCategory, INewMachine } from "../../infrastructure/machines";
import { ArrayDropdown } from "../general/ArrayDropdown";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(3),
    },
    head: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      textAlign: "center",
      paddingBottom: theme.spacing(3),
    },
    body: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    titleText: {
      textAlign: "center",
    },
    leftTextFieldContainer: {
      flex: 1,
      display: "flex",
      padding: theme.spacing(0, 2, 2, 0),
    },
    rightTextFieldContainer: {
      flex: 1,
      display: "flex",
      paddingBottom: theme.spacing(2),
    },
    textFieldContainer: {
      flex: 1,
      display: "flex",
      paddingBottom: theme.spacing(2),
    },
    lastTextFieldContainer: {
      flex: 1,
      display: "flex",
      paddingBottom: theme.spacing(3),
    },
    textField: {
      flex: 1,
      display: "flex",
    },
    buttonsContainer: {
      flex: 2,
      display: "flex",
      height: "100%",
      justifyContent: "center",
    },
    createButtonContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      paddingRight: theme.spacing(3),
    },
    cancelButtonContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
    },
    button: {
      flex: 1,
      display: "flex",
    },
    createButtonText: {
      color: theme.palette.background.paper,
    },
  });

export interface IRecalculationDialogProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly machineCategories: IMachineCategory[];
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onCreateClick: (value: INewMachine) => void;
}

class RecalculationDialogState {
  public gpsTrackerSerialNumber: string = "";
  public machineStockNumber: string = "";
  public machineName: string = "";
  public machineWorkWidth: number = 0;
  public machineCategoryIndex: number = 0;
  public machineTypeIndex: number = 0;
}

class CreateMachineDialogComponent extends React.Component<
  IRecalculationDialogProps,
  RecalculationDialogState
> {
  public constructor(props: IRecalculationDialogProps) {
    super(props);
    this.state = new RecalculationDialogState();
  }

  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <Dialog fullWidth open={this.props.isOpen}>
        <div className={classes.root}>
          <div className={classes.head}>
            <Typography className={classes.titleText} variant={"h4"}>
              {t("labels.create-machine-dialog.title")}
            </Typography>
          </div>
          <div className={classes.body}>
            <div className={classes.textFieldContainer}>
              <TextField
                className={classes.textField}
                variant={"outlined"}
                label={t("labels.create-machine.machine-stock-number")}
                type={"number"}
                value={this.state.machineStockNumber}
                onChange={(event) =>
                  this.handleMachineStockNumberChanged(
                    event.target.value as string,
                  )
                }
              />
            </div>
            <div className={classes.textFieldContainer}>
              <TextField
                variant={"outlined"}
                className={classes.textField}
                label={t("labels.create-machine.machine-name")}
                value={this.state.machineName}
                onChange={(event) =>
                  this.handleMachineNameChanged(event.target.value as string)
                }
              />
            </div>
            <div className={classes.textFieldContainer}>
              <TextField
                variant={"outlined"}
                className={classes.textField}
                label={t("labels.create-machine.machine-work-width")}
                value={this.state.machineWorkWidth}
                onChange={(event) =>
                  this.handleMachineWorkWidthChanged(Number(event.target.value))
                }
              />
            </div>
            <div className={classes.row}>
              <div className={classes.leftTextFieldContainer}>
                <ArrayDropdown
                  data={this.props.machineCategories}
                  valueIndex={this.state.machineCategoryIndex}
                  onChange={(value: number) =>
                    this.handleMachineCategoryChanged(value)
                  }
                  translationsPath={"labels.machine-categories"}
                  translationsKey={"name"}
                />
              </div>
              <div className={classes.rightTextFieldContainer}>
                <ArrayDropdown
                  data={this.getMachineTypes()}
                  valueIndex={this.state.machineTypeIndex}
                  onChange={(value: number) =>
                    this.handleMachineTypeChanged(value)
                  }
                  translationsPath={"labels.machine-types"}
                />
              </div>
            </div>
            <div className={classes.lastTextFieldContainer}>
              <TextField
                variant={"outlined"}
                className={classes.textField}
                label={t("labels.create-machine.gps-serial-number")}
                value={this.state.gpsTrackerSerialNumber}
                onChange={(event) =>
                  this.handleGPSSerialChanged(event.target.value as string)
                }
              />
            </div>
          </div>
          <div className={classes.buttonsContainer}>
            <div className={classes.createButtonContainer}>
              <Button
                className={classes.button}
                color={"primary"}
                variant={"contained"}
                onClick={() => this.onCreateClick()}>
                <Typography className={classes.createButtonText}>
                  {t("actions.create-machine.create")}
                </Typography>
              </Button>
            </div>
            <div className={classes.cancelButtonContainer}>
              <Button
                className={classes.button}
                color={"default"}
                variant={"outlined"}
                onClick={() => this.props.onClose()}>
                <Typography color={"error"}>
                  {t("actions.create-machine.cancel")}
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

  private handleMachineTypeChanged(value: number): void {
    this.setState({ machineTypeIndex: value });
  }

  private handleGPSSerialChanged(value: string): void {
    this.setState({ gpsTrackerSerialNumber: value });
  }

  private handleMachineCategoryChanged(value: number) {
    if (this.state.machineCategoryIndex != value) {
      this.setState({ machineCategoryIndex: value, machineTypeIndex: 0 });
    }
  }

  private handleMachineStockNumberChanged(value: string): void {
    this.setState({ machineStockNumber: value });
  }

  private handleMachineNameChanged(value: string): void {
    this.setState({ machineName: value });
  }

  private handleMachineWorkWidthChanged(value: number): void {
    this.setState({ machineWorkWidth: value });
  }

  private onCreateClick(): void {
    const category = this.props.machineCategories[
      this.state.machineCategoryIndex
    ];
    this.props.onCreateClick({
      gpsTrackerSerialNumber: this.state.gpsTrackerSerialNumber,
      machineStockNumber: this.state.machineStockNumber,
      machineName: this.state.machineName,
      machineWorkWidth: this.state.machineWorkWidth,
      machineCategory: category.name,
      machineType: category.machineTypes[this.state.machineTypeIndex],
    });
  }

  private getMachineTypes(): string[] {
    if (this.props.machineCategories.length === 0) {
      return [];
    }
    return this.props.machineCategories[this.state.machineCategoryIndex]
      .machineTypes;
  }
}

export const CreateMachineDialog = withTranslation()(
  withStyles(styles)(CreateMachineDialogComponent),
);
