import {
  createStyles,
  IconButton,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BarChart } from "../../components/bar-chart/BarChart";
import { BasePage } from "../../components/general/BasePage";
import { ContentHeader } from "../../components/dashboard";
import { GeoFence } from "../../components/geo-fence/GeoFence";
import {
  MachinesProfile,
  MachinesStatistic,
} from "../../components/machines-administration";
import { Routes } from "../../components/navigation/Routes";
import {
  IApplicationStateStoreContextProps,
  withApplicationStateStoreContext,
} from "../../infrastructure/application-state";
import { IBarData } from "../../infrastructure/bar-chart/IBarData";
import { inject } from "../../infrastructure/injection";
import {
  IMachine,
  IMachineCategory,
  IMachinesService,
  IStatistic,
  ITrackerConfig,
  StatisticTypes,
} from "../../infrastructure/machines";
import {
  NotificationCenter,
  NotificationTypes,
} from "../../infrastructure/notification";
import { ICoordinate } from "../../infrastructure/recordings";
import { TypeNames } from "../../infrastructure/TypeNames";

export interface IRouteParams {
  readonly machineId?: string;
}
export type IRootProps = WithStyles<typeof styles> &
  RouteComponentProps<IRouteParams> &
  WithTranslation &
  IApplicationStateStoreContextProps;

const styles = (theme: Theme) =>
  createStyles({
    head: {
      flex: 1,
      display: "flex",
      textAlign: "center",
      alignItems: "center",
      paddingBottom: theme.spacing(3),
    },
    body: {
      flex: 16,
      display: "flex",
      flexDirection: "column",
    },
    main: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
    },
    columnLeft: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    columnRight: {
      flex: 3,
      display: "flex",
      flexDirection: "column",
    },
    row: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
    },
    machinesProfileContainer: {
      flex: 1,
      display: "flex",
      paddingBottom: theme.spacing(2),
    },
    machineStatisticsContainer: {
      flex: 1,
      display: "flex",
    },
    barChart: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
    },
    barChartContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingLeft: theme.spacing(2),
    },
    geoFence: {
      height: 700,
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    iconContainer: {
      width: "fit-content",
      height: "fit-content",
      paddingRight: theme.spacing(3),
      alignSelf: "center",
      justifyContent: "center",
    },
    icon: {
      fontSize: 35,
    },
  });

class RootState {
  public machine?: IMachine;
  public statistics: IStatistic;
  public selectedMonths: string[] = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  public categories: IMachineCategory[] = [];
  public selectedChartData: StatisticTypes = StatisticTypes.operatingHours;
  public geoFenceRadius: number = 500;
  public geoFenceCoordinates?: ICoordinate;
}

class RootComponent extends React.Component<IRootProps, RootState> {
  @inject(TypeNames.machinesService)
  private readonly _machinesService: IMachinesService;
  @inject(TypeNames.notificationCenter)
  private readonly _notificationCenter: NotificationCenter;
  public constructor(props: IRootProps) {
    super(props);
    this.state = new RootState();
  }

  public render(): React.ReactNode {
    const { classes, t } = this.props;
    if (this.state.machine == undefined) {
      return (
        <BasePage
          currentBaseRoute={Routes.machines}
          onRouteClick={(newRoute: Routes) => this.props.history.push(newRoute)}
        />
      );
    }
    return (
      <BasePage
        currentBaseRoute={Routes.machines}
        onRouteClick={(newRoute: Routes) => this.props.history.push(newRoute)}>
        <div className={classes.head}>
          <IconButton
            onClick={() => this.handleBackButtonClicked()}
            className={classes.iconContainer}>
            <ArrowBack className={classes.icon} />
          </IconButton>
          <ContentHeader
            title={t("labels.machines-administration.title")}
            buttonProps={{
              onButtonClick: () => this.handleButtonClicked(),
              buttonLabel: t("actions.machines-administration.history"),
            }}
            iconProps={{ isIcon: true }}
          />
        </div>
        <div className={classes.body}>
          <div className={classes.main}>
            <div className={classes.columnLeft}>
              <div className={classes.machinesProfileContainer}>
                <MachinesProfile
                  categories={this.state.categories}
                  machineTypes={this.getMachineTypes()}
                  machine={this.state.machine}
                  onMachineCategoryChange={(category) =>
                    this.handleMachineCategoryChanged(category)
                  }
                  onMachineTypeChange={(type) =>
                    this.handleMachineTypeChanged(type)
                  }
                  onMachineWorkWidthChange={(workWidth) =>
                    this.handleMachineWorkWidthChanged(workWidth)
                  }
                  onMachineSleepTimerChange={(sleepTimer) =>
                    this.handleMachineSleepTimerChanged(sleepTimer)
                  }
                />
              </div>
              <div className={classes.machineStatisticsContainer}>
                <MachinesStatistic statistics={this.state.statistics} />
              </div>
            </div>
            <div className={classes.columnRight}>
              <div className={classes.barChartContainer}>
                <div className={classes.barChart}>
                  <BarChart
                    data={this.getStatisticsData()}
                    selectedMonths={this.state.selectedMonths}
                    selectedDataType={this.state.selectedChartData}
                    selectedDataTypeChange={(value) =>
                      this.handleSelectedChartDataChanged(value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.geoFence}>
            <GeoFence
              machine={this.state.machine}
              geoFenceCoordinate={this.state.geoFenceCoordinates}
              geoFenceRadius={this.state.geoFenceRadius}
              onCoordinatesChange={(coords: ICoordinate) =>
                this.setState({ geoFenceCoordinates: coords })
              }
              onRadiusChange={(radius: number) =>
                this.setState({ geoFenceRadius: radius })
              }
              onAddGeoFence={() => this.handleMachineGeoFenceChanged()}
            />
          </div>
        </div>
      </BasePage>
    );
  }
  public async componentDidMount(): Promise<void> {
    if (
      this.props.match.params != undefined &&
      this.props.match.params.machineId != undefined &&
      this.props.store.state.jwt != undefined
    ) {
      const machine = await this._machinesService.getMachine(
        this.props.store.state.jwt,
        Number(this.props.match.params.machineId),
      );
      this.setState({
        geoFenceRadius:
          machine.geoFenceRadius != undefined ? machine.geoFenceRadius : 250,
        geoFenceCoordinates: machine.geoFenceCoordinates,
      });
      const categories = await this._machinesService.getMachineCategories(
        this.props.store.state.jwt,
      );
      await this.getStatistics(Number(machine.id));
      this.setState({
        machine,
        categories,
      });
    }
  }

  private async handleMachineCategoryChanged(category: string): Promise<void> {
    if (
      this.state.machine == undefined ||
      this.props.store.state.jwt == undefined
    ) {
      return;
    }
    try {
      const machine = {
        ...this.state.machine,
        category,
      };
      await this._machinesService.updateMachine(
        machine,
        this.props.store.state.jwt,
      );
      this.setState({ machine });
    } catch {
      this._notificationCenter.sendNotification({
        notificationType: NotificationTypes.error,
        text: this.props.t(
          "labels.machines-administration.errors.update-failed",
        ),
      });
    }
  }
  private async handleMachineTypeChanged(type: string): Promise<void> {
    if (
      this.state.machine == undefined ||
      this.props.store.state.jwt == undefined
    ) {
      return;
    }
    try {
      const machine = {
        ...this.state.machine,
        type,
      };
      await this._machinesService.updateMachine(
        machine,
        this.props.store.state.jwt,
      );
      this.setState({ machine });
    } catch {
      this._notificationCenter.sendNotification({
        notificationType: NotificationTypes.error,
        text: this.props.t(
          "labels.machines-administration.errors.update-failed",
        ),
      });
    }
  }

  private async handleMachineSleepTimerChanged(
    sleepTimer: number,
  ): Promise<void> {
    if (
      this.state.machine == undefined ||
      this.props.store.state.jwt == undefined
    ) {
      return;
    }
    try {
      if (this.state.machine.gpsTracker == undefined) {
        return;
      }
      const configurations: ITrackerConfig[] = this.state.machine.gpsTracker.configurations.map(
        (x) => {
          if (x.key == "sleepTimer") {
            return {
              ...x,
              value: sleepTimer.toString(),
            };
          } else {
            return x;
          }
        },
      );
      const gpsTracker = {
        ...this.state.machine.gpsTracker,
        configurations,
      };
      const machine = {
        ...this.state.machine,
        gpsTracker,
      };
      await this._machinesService.updateMachine(
        machine,
        this.props.store.state.jwt,
      );
      this.setState({ machine });
    } catch {
      this._notificationCenter.sendNotification({
        notificationType: NotificationTypes.error,
        text: this.props.t(
          "labels.machines-administration.errors.update-failed",
        ),
      });
    }
  }

  private async handleMachineWorkWidthChanged(
    workWidth: number,
  ): Promise<void> {
    if (
      this.state.machine == undefined ||
      this.props.store.state.jwt == undefined
    ) {
      return;
    }
    try {
      const machine = {
        ...this.state.machine,
        workWidth,
      };
      await this._machinesService.updateMachine(
        machine,
        this.props.store.state.jwt,
      );
      this.setState({ machine });
    } catch {
      this._notificationCenter.sendNotification({
        notificationType: NotificationTypes.error,
        text: this.props.t(
          "labels.machines-administration.errors.update-failed",
        ),
      });
    }
  }

  private async handleMachineGeoFenceChanged(): Promise<void> {
    if (
      this.state.machine == undefined ||
      this.props.store.state.jwt == undefined ||
      this.state.geoFenceCoordinates == undefined
    ) {
      return;
    }
    try {
      const machine = {
        ...this.state.machine,
        geoFenceRadius: this.state.geoFenceRadius,
        geoFenceCoordinates: this.state.geoFenceCoordinates,
      };
      await this._machinesService.updateMachine(
        machine,
        this.props.store.state.jwt,
      );
      this._notificationCenter.sendNotification({
        notificationType: NotificationTypes.success,
        text: this.props.t(
          "labels.machines-administration.geoFence.success-message",
        ),
      });
      this.setState({ machine });
    } catch {
      this._notificationCenter.sendNotification({
        notificationType: NotificationTypes.error,
        text: this.props.t(
          "labels.machines-administration.geoFence.error-message",
        ),
      });
    }
  }

  private async getStatistics(machineId: number): Promise<void> {
    const jwt = this.props.store.state.jwt;
    if (jwt != undefined) {
      const promises = this.state.selectedMonths.map(async (x) => {
        return await this._machinesService.getStatistics(jwt, machineId, x);
      });
      const rawStatistics = await Promise.all(promises);
      const statistics = rawStatistics.reduce((a, b) => ({
        totalFieldArea: a.totalFieldArea + b.totalFieldArea,
        operatingHours: a.operatingHours + b.operatingHours,
        traveledDistance: a.traveledDistance + b.traveledDistance,
        areaProcessed: a.areaProcessed + b.areaProcessed,
        barChartOperatingHours: a.barChartOperatingHours.concat(
          b.barChartOperatingHours,
        ),
        barChartAreaProcessed: a.barChartAreaProcessed.concat(
          b.barChartAreaProcessed,
        ),
        barChartTotalFieldArea: a.barChartTotalFieldArea.concat(
          b.barChartTotalFieldArea,
        ),
      }));
      this.setState({ statistics });
    }
  }

  private getStatisticsData(): IBarData[] {
    switch (this.state.selectedChartData) {
      case StatisticTypes.operatingHours:
        return this.state.statistics.barChartOperatingHours;
      case StatisticTypes.areaProcessed:
        return this.state.statistics.barChartAreaProcessed;
      case StatisticTypes.fieldArea:
        return this.state.statistics.barChartTotalFieldArea;
      default:
        return [];
    }
  }

  private getMachineTypes(): string[] {
    const selectedCategory = this.state.categories.find(
      (x) => x.name === this.state.machine?.category,
    );
    if (selectedCategory != undefined) {
      return selectedCategory.machineTypes;
    }
    return [];
  }

  private handleSelectedChartDataChanged(
    selectedChartData: StatisticTypes,
  ): void {
    this.setState({ selectedChartData });
  }

  private handleButtonClicked(): void {
    if (this.state.machine != undefined) {
      this.props.history.push({
        pathname: Routes.history,
        search: this.state.machine.id.toString(),
      });
    }
  }

  private handleBackButtonClicked(): void {
    this.props.history.goBack();
  }
}

export const Root = withApplicationStateStoreContext(
  withRouter(withTranslation()(withStyles(styles)(RootComponent))),
);
