import {
  Box,
  createStyles,
  Divider,
  IconButton,
  Link,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import PageviewIcon from "@material-ui/icons/Pageview";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  ITimelineField,
  ITimelineItem,
} from "../../infrastructure/timeline/ITimelineItem";
import { Routes } from "../navigation/Routes";
import moment = require("moment");
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: theme.spacing(0, 3),
    },
    timelineColumn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginRight: theme.spacing(2),
    },
    dateContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.primary.light,
      padding: theme.spacing(2),
    },
    lastConnectorLineContainer: {
      flex: 1,
      display: "flex",

      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    connectorLine: {
      flex: 1,
      minHeight: 35,
      marginRight: 525,
      display: "flex",
      width: theme.spacing(1),
      backgroundColor: theme.palette.divider,
    },
    lastConnectorFooter: {
      flex: 1,
      marginRight: 525,
      display: "flex",
      justifyContent: "center",
      borderRadius: theme.spacing(10),
      backgroundColor: theme.palette.divider,
      padding: theme.spacing(1),
    },
    date: {
      textAlign: "center",
      color: theme.palette.text.primary,
    },
    itemContainer: {
      display: "flex",
      flex: 1,
    },
    infoContainer: {
      width: 500,
      marginLeft: 25,
    },
    durationContainer: {
      borderColor: "red",
      border: 1,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    uploadText: {
      fontSize: 10,
    },
  });

interface ITimelineItemProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    WithTranslation {
  readonly item: ITimelineItem | undefined;
  readonly isTransport: boolean;
  readonly currentPage: number;
  readonly maxPages: number;
  readonly onNewPagePress: (page: number) => void;
}

class TimelineItemComponent extends React.Component<ITimelineItemProps> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    let startFieldTime = 0;
    let endFieldTime = 0;
    let fieldAreas = 0;
    let fieldProcessed = 0;
    if (this.props.item === undefined) {
      return <Typography>{t("labels.history.timeline.no-entry")}</Typography>;
    }
    return (
      <div className={classes.root}>
        <div className={classes.timelineColumn}>
          {this.previousButton()}
          <div className={classes.itemContainer}>
            {this.renderTimeStamp(this.props.item.beginRecording)}
            <div className={classes.infoContainer}>
              <Typography>
                {t("labels.history.timeline.left-parking-space")}
              </Typography>
              <Divider />
            </div>
          </div>
          {this.renderConnector(false)}
          {this.props.isTransport
            ? false
            : this.props.item.fields.map((field, index) => {
                if (
                  startFieldTime === 0 ||
                  startFieldTime > new Date(field.entryTime).getTime()
                ) {
                  startFieldTime = new Date(field.entryTime).getTime();
                }
                if (
                  endFieldTime === 0 ||
                  endFieldTime < new Date(field.exitTime).getTime()
                ) {
                  endFieldTime = new Date(field.exitTime).getTime();
                }
                fieldAreas = fieldAreas + field.fieldArea;
                fieldProcessed = fieldProcessed + field.processedArea;
                return (
                  <div key={field.id} className={classes.timelineColumn}>
                    <div className={classes.itemContainer}>
                      {this.renderTimeStamp(field.entryTime)}
                      <div className={classes.infoContainer}>
                        <Typography>
                          {t("labels.history.timeline.field-entry")} (
                          {t("labels.history.timeline.field")} {index + 1})
                        </Typography>
                        <Divider />
                      </div>
                    </div>
                    {this.renderConnector(false)}
                    <div className={classes.itemContainer}>
                      {this.renderTimeStamp(field.exitTime)}
                      <div className={classes.infoContainer}>
                        <Typography>
                          {t("labels.history.timeline.field-exit")} (
                          {t("labels.history.timeline.field")} {index + 1})
                        </Typography>
                        <Divider />
                      </div>
                    </div>
                    {this.renderConnector(false)}
                  </div>
                );
              })}
          <div className={classes.itemContainer}>
            {this.renderTimeStamp(this.props.item.date)}
            {this.renderUpload(
              startFieldTime,
              endFieldTime,
              fieldAreas,
              fieldProcessed,
            )}
          </div>
          {this.renderConnector(false)}
          <div className={classes.itemContainer}>
            {this.renderTimeStamp(this.props.item.endRecording)}
            <div className={classes.infoContainer}>
              <Typography>
                {t("labels.history.timeline.enter-parking-space")}
              </Typography>
              <Divider />
              <Box borderRadius={25}>
                <Typography>
                  {t("labels.history.timeline.absent")}{" "}
                  {moment
                    .utc(
                      moment(new Date(this.props.item.endRecording)).diff(
                        moment(new Date(this.props.item.beginRecording)),
                      ),
                    )
                    .format("HH[" + "Std. " + "]mm[" + "min.]")}
                </Typography>
              </Box>
            </div>
          </div>
          {this.renderConnector(true)}
        </div>
      </div>
    );
  }

  private renderConnector(isLastItem: boolean): React.ReactNode {
    const { classes, t } = this.props;
    if (isLastItem) {
      if (this.props.currentPage === this.props.maxPages) {
        return (
          <div className={classes.lastConnectorLineContainer}>
            <div className={classes.connectorLine} />
            <Paper className={classes.lastConnectorFooter} elevation={1}>
              <Typography variant={"caption"} style={{ padding: 10 }}>
                {t("labels.history.end")}
              </Typography>
            </Paper>
          </div>
        );
      }
      return (
        <div className={classes.lastConnectorLineContainer}>
          <div className={classes.connectorLine} />
          <Paper className={classes.lastConnectorFooter} elevation={1}>
            <IconButton
              onClick={() => {
                this.props.onNewPagePress(this.props.currentPage + 1);
              }}>
              <ArrowDropDownIcon color={"action"} fontSize={"large"} />
            </IconButton>
          </Paper>
        </div>
      );
    } else {
      return <div className={classes.connectorLine} />;
    }
  }

  private renderTimeStamp(date: string): JSX.Element {
    const { classes } = this.props;
    return (
      <Paper className={classes.dateContainer} elevation={1}>
        <Typography className={classes.date} variant={"h4"}>
          {moment(date).format("DD")}
        </Typography>
        <Typography className={classes.date} variant={"h6"}>
          {moment(date).format("MM.yyyy")}
        </Typography>
        <Typography className={classes.date} variant={"h5"}>
          {moment(date).format("HH:mm")} Uhr
        </Typography>
      </Paper>
    );
  }

  private renderUpload(
    startFieldTime: number,
    endFieldTime: number,
    fieldAreas: number,
    fieldProcesssed: number,
  ): JSX.Element {
    const { classes, t } = this.props;
    return (
      <div className={classes.infoContainer}>
        <Typography>
          {t("labels.history.timeline.upload")} -{" "}
          {this.props.item?.fields.length}{" "}
          {this.props.isTransport
            ? t("labels.history.timeline.transports")
            : t("labels.history.timeline.fields")}
        </Typography>
        <div
          style={{ flex: 1, display: "flex", justifyContent: "space-between" }}>
          <div />
          <div>
            <Typography className={classes.uploadText}>
              {t("labels.history.timeline.max-surface")}{" "}
              {t("labels.history.timeline.processed")} {fieldProcesssed}{" "}
              {t("labels.history.timeline.hektar")}
            </Typography>
            <Typography className={classes.uploadText}>
              {t("labels.history.timeline.max-surface")}{" "}
              {t("labels.history.timeline.area")} {fieldAreas}{" "}
              {t("labels.history.timeline.hektar")}
            </Typography>
          </div>
          <div>
            <Typography className={classes.uploadText}>
              {t("labels.history.timeline.start-recording")}{" "}
              {startFieldTime === 0
                ? "-"
                : moment(new Date(startFieldTime)).format("HH:mm")}
            </Typography>
            <Typography className={classes.uploadText}>
              {t("labels.history.timeline.end-recording")}{" "}
              {endFieldTime === 0
                ? "-"
                : moment(new Date(endFieldTime)).format("HH:mm")}
            </Typography>
          </div>
          <div>
            <Typography className={classes.uploadText}>
              {t("labels.history.timeline.max-time")}{" "}
              {moment
                .utc(moment(endFieldTime).diff(moment(startFieldTime)))
                .format("HH:mm")}
            </Typography>
          </div>
        </div>
        <Divider />
        {this.props.isTransport
          ? false
          : this.props.item?.fields.map((field: ITimelineField, index) => {
              return (
                <div
                  key={field.id}
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}>
                  <div>
                    <Typography className={classes.uploadText}>
                      {t("labels.history.timeline.field")} {index + 1}
                    </Typography>
                  </div>
                  <div>
                    <Typography className={classes.uploadText}>
                      {t("labels.history.timeline.surface")}{" "}
                      {t("labels.history.timeline.processed")}{" "}
                      {field.processedArea}{" "}
                      {t("labels.history.timeline.hektar")}
                    </Typography>
                    <Typography className={classes.uploadText}>
                      {t("labels.history.timeline.surface")}{" "}
                      {t("labels.history.timeline.area")} {field.fieldArea}{" "}
                      {t("labels.history.timeline.hektar")}
                    </Typography>
                  </div>
                  <div>
                    <Typography className={classes.uploadText}>
                      {t("labels.history.timeline.field-entry")}{" "}
                      {moment(new Date(field.entryTime)).format("HH:mm")}
                    </Typography>
                    <Typography className={classes.uploadText}>
                      {t("labels.history.timeline.field-exit")}{" "}
                      {moment(new Date(field.exitTime)).format("HH:mm")}
                    </Typography>
                  </div>
                  <div>
                    <Typography className={classes.uploadText}>
                      {t("labels.history.timeline.work-time")}{" "}
                      {moment
                        .utc(
                          moment(field.exitTime).diff(moment(field.entryTime)),
                        )
                        .format("HH:mm")}
                    </Typography>
                  </div>
                </div>
              );
            })}
        <Link>
          <a
            href={Routes.recordingsDetailed.replace(
              ":recordingId",
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              this.props.item!.id.toString(),
            )}>
            <PageviewIcon fontSize={"large"} color={"primary"} />
          </a>
        </Link>
      </div>
    );
  }

  private previousButton() {
    const { classes } = this.props;
    if (this.props.currentPage > 1) {
      return (
        <div className={classes.lastConnectorLineContainer}>
          <Paper className={classes.lastConnectorFooter} elevation={1}>
            <IconButton
              onClick={() => {
                this.props.onNewPagePress(this.props.currentPage - 1);
              }}>
              <ArrowDropUpIcon color={"action"} fontSize={"large"} />
            </IconButton>
          </Paper>
          <div className={classes.connectorLine} />
        </div>
      );
    }
  }
}

export const TimelineItem = withRouter(
  withTranslation()(withStyles(styles)(TimelineItemComponent)),
);
