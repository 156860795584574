import { IMachine } from "../machines";

export class BatteryVoltageService {
  public static getVoltage(machine?: IMachine): number {
    if (machine != undefined) {
      if (machine.gpsTracker != undefined) {
        const gpsTracker = machine.gpsTracker;
        const currentVoltage = gpsTracker.voltage;
        return currentVoltage;
      }
      return 0;
    }
    return 0;
  }
}
