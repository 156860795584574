import {
  Button,
  Divider,
  IconButton,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import LanguageIcon from "@material-ui/icons/Language";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { LanguageDropDown } from "../general/LanguageDropDown";
import { IButtonProps } from "./IButtonProps";
import { IIconProps } from "./IIconProps";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
      flex: 1,
    },
    icon: {
      fontSize: 35,
    },
    iconContainer: {
      width: "fit-content",
      paddingRight: theme.spacing(3),
      alignSelf: "center",
      justifyContent: "center",
    },
    buttonContainer: {
      flex: 1,
      display: "flex",
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    title: {
      textAlign: "center",
    },
    subtitle: {
      textAlign: "center",
      paddingLeft: theme.spacing(3),
    },
    button: {
      flex: 1,
      display: "flex",
      textTransform: "none",
      borderRadius: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
    },
    titleContainer: {
      flex: 4,
      display: "flex",
      textAlign: "center",
    },
    actionContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
    },
    buttonLabel: {
      color: theme.palette.background.paper,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  });

export interface IContentHeaderProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly title: string;
  readonly icon?: boolean;
  readonly isNested?: boolean;
  readonly subtitle?: string;
  readonly iconProps?: IIconProps;
  readonly buttonProps?: IButtonProps;
}

class ContentHeaderState {
  public isLanguageMenuOpen: boolean = false;
  public anchorElement: (EventTarget & HTMLElement) | undefined;
}

class ContentHeaderComponent extends React.PureComponent<
  IContentHeaderProps,
  ContentHeaderState
> {
  public constructor(props: IContentHeaderProps) {
    super(props);
    this.state = new ContentHeaderState();
  }

  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.titleContainer}>{this.renderTitle()}</div>
        <div className={classes.actionContainer}>
          {this.renderIcon()}
          <Divider variant={"middle"} />
          {this.renderButton()}
        </div>
        <LanguageDropDown
          isLanguageMenuOpen={this.state.isLanguageMenuOpen}
          anchorElement={this.state.anchorElement}
          onMenuClose={() => this.handleMenuClose()}
          onLanguageClick={(language: string) =>
            this.handleLanguageClicked(language)
          }
        />
      </div>
    );
  }

  private renderIcon(): React.ReactNode {
    const iconProps = this.props.iconProps;
    const { classes } = this.props;
    if (iconProps != undefined) {
      return (
        <IconButton
          onClick={(event) => this.handleOnIconClicked(event)}
          className={classes.iconContainer}>
          <LanguageIcon className={this.props.classes.icon} />
        </IconButton>
      );
    }
    return undefined;
  }
  private renderButton(): React.ReactNode {
    const { classes } = this.props;
    const buttonProps = this.props.buttonProps;
    if (buttonProps != undefined) {
      return (
        <Paper elevation={1} className={classes.buttonContainer}>
          <Button
            onClick={() => buttonProps.onButtonClick()}
            className={classes.button}>
            <Typography variant={"body1"} className={classes.buttonLabel}>
              {buttonProps.buttonLabel}
            </Typography>
          </Button>
        </Paper>
      );
    }
    return undefined;
  }

  private renderTitle(): React.ReactNode {
    const { classes } = this.props;
    if (this.props.isNested === true) {
      return (
        <div className={classes.row}>
          <Typography variant={"subtitle1"} className={classes.title}>
            {this.props.title} /
          </Typography>
          <Typography variant={"body2"} className={classes.subtitle}>
            {this.props.subtitle}
          </Typography>
        </div>
      );
    } else {
      return (
        <Typography variant={"body2"} className={classes.title}>
          {this.props.title}
        </Typography>
      );
    }
  }

  private handleOnIconClicked(event: React.MouseEvent<HTMLElement>): void {
    this.setState({
      anchorElement: event.currentTarget,
      isLanguageMenuOpen: true,
    });
  }
  private handleMenuClose(): void {
    this.setState({ anchorElement: undefined, isLanguageMenuOpen: false });
  }
  private async handleLanguageClicked(language: string): Promise<void> {
    await this.props.i18n.changeLanguage(language);
    this.setState({ anchorElement: undefined, isLanguageMenuOpen: false });
  }
}

export const ContentHeader = withTranslation()(
  withStyles(styles)(ContentHeaderComponent),
);
