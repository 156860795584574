import {
  Button,
  createStyles,
  InputAdornment,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Lock from "@material-ui/icons/Lock";
import Mail from "@material-ui/icons/Mail";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { background } from "../resources/data-uris.json";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require("../../resources/images/logo.jpg");
const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      height: "100vh",
      flexDirection: "row",
      backgroundColor: theme.palette.background.paper,
    },
    leftContainer: {
      flex: 3,
      display: "flex",
      flexDirection: "column",
      backgroundImage: `url(${background})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    leftContainerBackgroundView: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    leftContainerHeader: {
      height: "10%",
      backgroundColor: theme.palette.primary.main,
      opacity: 0.9,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    backgroundViewTextContainer: {
      height: "50%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      paddingLeft: theme.spacing(20),
    },
    rightContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    rightContainersHeader: {
      height: "10%",
      backgroundColor: theme.palette.grey.A100,
    },
    componentContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: theme.spacing(2),
    },
    textFieldContainer: {
      flex: 4,
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "column",
    },
    robotContainer: {
      flex: 1,
      display: "flex",
    },
    buttonContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    registerButtonContainer: {
      display: "flex",
      height: "20%",
      width: "25%",
      padding: theme.spacing(1),
      marginBottom: theme.spacing(10),
    },
    textContainer: {
      flex: 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    registerTextContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    logo: {
      resizeMode: "contain",
      height: "100%",
      width: "100%",
    },
    headerText: {
      textAlign: "center",
      paddingRight: theme.spacing(5),
      color: theme.palette.text.hint,
    },
    title: {
      textAlign: "center",
      paddingBottom: theme.spacing(10),
      color: theme.palette.text.hint,
      fontWeight: 600,
    },
    subtitle: {
      textAlign: "center",
      paddingBottom: theme.spacing(10),
      color: theme.palette.text.hint,
    },
    fadeText: {
      textAlign: "center",
      fontWeight: 500,
      opacity: 0.6,
    },
    registerText: {
      textAlign: "center",
      fontWeight: 600,
    },
    textField: {
      borderRadius: theme.spacing(6),
      marginBottom: theme.spacing(5),
    },
    button: {
      borderRadius: theme.spacing(6),
      height: "50%",
    },
    registerButton: {
      flex: 1,
      borderRadius: theme.spacing(15),
      borderColor: theme.palette.background.paper,
      color: theme.palette.background.paper,
    },
  });

export interface ISignInFormProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly email: string;
  readonly password: string;
  readonly onEmailChange: (email: string) => void;
  readonly onPasswordChange: (password: string) => void;
  readonly onRecoverPasswordClick: () => void;
  readonly onSignInClick: () => void;
  readonly onSignInNowClick: () => void;
  readonly emailError?: string;
  readonly passwordError?: string;
}

export class SignInFormComponent extends React.PureComponent<ISignInFormProps> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.leftContainer}>
          <div className={classes.leftContainerHeader}>
            <Typography className={classes.headerText} variant={"h4"}>
              {t("labels.sign-in.header-title")}
            </Typography>
          </div>
          <div className={classes.leftContainerBackgroundView}>
            <div className={classes.backgroundViewTextContainer}>
              <div className={classes.textContainer}>
                <Typography className={classes.title} variant={"h4"}>
                  {t("labels.sign-in.title")}
                </Typography>
                <Typography className={classes.subtitle} variant={"h6"}>
                  {t("labels.sign-in.subtitle")}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.rightContainersHeader}>
            <img className={classes.logo} src={logo} />
          </div>
          <div className={classes.componentContainer}>
            <div className={classes.textFieldContainer}>
              <TextField
                placeholder={t("labels.sign-in.mail")}
                color={"primary"}
                variant={"outlined"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Mail color={"disabled"} />
                    </InputAdornment>
                  ),
                  className: classes.textField,
                }}
                required
                fullWidth
                type={"email"}
                value={this.props.email}
                onChange={(event) =>
                  this.props.onEmailChange(event.target.value)
                }
                error={this.props.emailError != undefined}
                helperText={this.props.emailError}
              />
              <TextField
                placeholder={t("labels.sign-in.password")}
                color={"primary"}
                variant={"outlined"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color={"disabled"} />
                    </InputAdornment>
                  ),
                  className: classes.textField,
                }}
                required
                fullWidth
                type={"password"}
                value={this.props.password}
                onChange={(event) =>
                  this.props.onPasswordChange(event.target.value)
                }
                error={this.props.passwordError != undefined}
                helperText={this.props.passwordError}
              />
            </div>
            <div className={classes.robotContainer} />
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => this.props.onSignInClick()}
                classes={{ root: classes.button }}
                fullWidth
                color={"primary"}
                variant={"contained"}>
                {t("actions.sign-in.sign-in")}
              </Button>
              <Button
                variant={"text"}
                onClick={() => this.props.onRecoverPasswordClick()}>
                {t("actions.sign-in.recover-password")}
              </Button>
            </div>
            <div className={classes.registerTextContainer}>
              <Typography className={classes.fadeText} variant={"h6"}>
                {t("labels.sign-in.no-account")}
              </Typography>
              <Button
                className={classes.registerText}
                variant={"text"}
                onClick={() => this.props.onSignInNowClick()}>
                {t("actions.sign-in.sign-in-now")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const SignInForm = withTranslation()(
  withStyles(styles)(SignInFormComponent),
);
