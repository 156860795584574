import { Slider, Theme, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    row: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
    },
    text: {
      opacity: 0.8,
      textAlign: "center",
    },
    sliderText: {
      opacity: 0.8,
      textAlign: "center",
      paddingLeft: theme.spacing(2),
    },
  });

export interface IDialogSliderProps extends WithStyles, WithTranslation {
  readonly title: string;
  readonly value: number;
  readonly onChange: (value: number) => void;
}

class DialogSliderComponent extends React.PureComponent<IDialogSliderProps> {
  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant={"body1"} className={classes.text}>
          {this.props.title}
        </Typography>
        <div className={classes.row}>
          <Slider
            min={0}
            max={100}
            value={this.props.value}
            onChange={(event, value) => this.props.onChange(value as number)}
          />
          <Typography variant={"body1"} className={classes.sliderText}>
            {this.props.children}
          </Typography>
        </div>
      </div>
    );
  }
}
export const DialogSlider = withTranslation()(
  withStyles(styles)(DialogSliderComponent),
);
