import axios from "axios";
import { injectable } from "inversify";
import { ConfigService } from "../config-service";
import { IMachineLocation } from "./IMachineLocation";
import { IDashboardService } from "./IDashboardService";
import { IDashboardStatistics } from "./IDashboardStatistics";

@injectable()
export class DashboardService implements IDashboardService {
  public async getDashboardStatistics(
    jwt: string,
    month: string,
  ): Promise<IDashboardStatistics> {
    const url = `${ConfigService.get(
      (x) => x.baseUrl,
    )}/dashboard?month=${month}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
  }
  public async getMachineLocation(jwt: string): Promise<IMachineLocation[]> {
    const url = `${ConfigService.get((x) => x.baseUrl)}/dashboard/locations`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
  }
}
