import { ILogger, LoggerConfiguration } from "@econsor-mobile/logging";
import { injectable } from "inversify";

@injectable()
export class LoggerFactory {
  private _configuration: LoggerConfiguration;

  public constructor(configuration: LoggerConfiguration) {
    this._configuration = configuration;
  }

  public create(scope: string): ILogger {
    return this._configuration.createLogger(scope);
  }
}
