export enum Routes {
  signIn = "/",
  signUp = "/sign-up",
  requestPasswordRecovery = "/password-recovery/request",
  passwordRecovery = "/password-recovery",
  dashboard = "/dashboard",
  recordings = "/recordings",
  recordingsDetailed = "/recordings/:recordingId",
  machines = "/machines",
  machinesAdministration = "/machines/:machineId",
  history = "/history",
  settings = "/settings",
  alerts = "/alerts",
  miscellaneous = "/miscellaneous",
}
