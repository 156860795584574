import { injectable } from "inversify";
import { ConfigService } from "../config-service";
import { IRecalculationOptions } from "./IRecalculationOptions";
import { IRecording } from "./IRecording";
import { IRecordingsService } from "./IRecordingsService";
import axios from "axios";

@injectable()
export class RecordingsService implements IRecordingsService {
  public async getRecording(
    jwt: string,
    recordingId: number,
  ): Promise<IRecording> {
    const url = `${ConfigService.get(
      (config) => config.baseUrl,
    )}/recordings/${recordingId}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
  }
  public async getRecordingsPages(
    jwt: string,
    search: string,
    type: string,
    showAllValue: boolean,
  ): Promise<number> {
    const count = await this.getRecordingsCount(
      jwt,
      search,
      type,
      showAllValue,
    );
    return Math.ceil(count / 100);
  }
  public async getRecordingsCount(
    jwt: string,
    search: string,
    type: string,
    showAllValues: boolean,
  ): Promise<number> {
    const url = `${ConfigService.get(
      (x) => x.baseUrl,
    )}/recordings/count?_q=${search}&_type=${type}&_all=${showAllValues}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
  }
  public async getRecordings(
    jwt: string,
    currentPage: number,
    search: string,
    machineType: string,
    showAllValues: boolean,
  ): Promise<IRecording[]> {
    const limit = 100;
    const start = (currentPage - 1) * limit;
    const url = `${ConfigService.get(
      (config) => config.baseUrl,
    )}/recordings?_limit=${limit}&_start=${start}&_q=${search}&_type=${machineType}&_all=${showAllValues}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
  }
  public async deleteRecording(
    recordingId: number,
    jwt?: string,
  ): Promise<void> {
    if (jwt == undefined) {
      throw new Error("Jwt token is undefined");
    }
    const url = `${ConfigService.get(
      (config) => config.baseUrl,
    )}/recordings/${recordingId}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    await axios.delete(url, { headers });
  }
  public async recalculateRecording(
    jwt: string,
    recordingId: number,
    options: IRecalculationOptions,
  ): Promise<IRecording> {
    const url = `${ConfigService.get(
      (config) => config.baseUrl,
    )}/recordings/recalculate/${recordingId}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.post(url, options, { headers });
    return response.data;
  }
}
