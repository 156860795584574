import * as React from "react";
import {
  Card,
  createStyles,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import BatteryChargingFull from "@material-ui/icons/BatteryChargingFull";
import Dehaze from "@material-ui/icons/Dehaze";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import moment from "moment";
import { withTranslation, WithTranslation } from "react-i18next";
import { BatteryPercentageService } from "../../infrastructure/battery-percentage/BatteryPercentageService";
import { BatteryVoltageService } from "../../infrastructure/battery-percentage/BatteryVoltageService";
import { IMachine } from "../../infrastructure/machines";
import { LoadingIndicator } from "../general/LoadingIndicator";
import { Search } from "../general/Search";
import { SortingButton } from "../general/SortingButton";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
    },
    tableContainer: {
      flex: 1,
      display: "flex",
    },
    editIcon: {
      display: "flex",
      justifyContent: "center",
    },
    deleteIcon: {
      display: "flex",
      justifyContent: "center",
    },
    centerCell: {
      justifyContent: "center",
      alignItems: "center",
    },
    dateText: {
      textAlign: "center",
      alignSelf: "center",
    },
    loadingIndicatorContainer: {
      flex: 1,
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    cell: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    searchContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(3),
      justifyContent: "flex-end",
    },
  });

export interface IMachinesTableProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly selectedMachineIds: number[];
  readonly isMachineActionMenuOpen: boolean;
  readonly machineMenuAnchor: HTMLElement | undefined;
  readonly machines: IMachine[];
  readonly onActionOpen: (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    machine: number,
  ) => void;
  readonly onActionClose: () => void;
  readonly onAdministrationClick: () => void;
  readonly onDeleteClick: () => void;
  readonly isLoading: boolean;
  readonly onSortingClick: (sortingKey: string) => void;
  readonly sortInverse: boolean;
  readonly sortingKey: string;
  readonly search: string;
  readonly onSearchChanged: (search: string) => void;
  readonly onSearchClick: () => void;
}

class MachinesTableComponent extends React.PureComponent<IMachinesTableProps> {
  public render(): React.ReactNode {
    const { t, classes } = this.props;
    if (this.props.isLoading) {
      return (
        <div className={classes.loadingIndicatorContainer}>
          <LoadingIndicator />
        </div>
      );
    }
    return (
      <Card className={classes.root}>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={10}>
                  <div className={classes.searchContainer}>
                    <Search
                      value={this.props.search}
                      onChange={(value) => this.props.onSearchChanged(value)}
                      onSearchClick={() => this.props.onSearchClick()}
                      placeholder={t("labels.machines.search-placeholder")}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className={classes.cell}>
                    <Typography variant={"subtitle2"}>
                      {t("labels.machines.machine-table.ids")}
                    </Typography>
                    {this.renderSortingButton("id")}
                  </div>
                </TableCell>
                <TableCell>
                  <div className={classes.cell}>
                    <Typography variant={"subtitle2"}>
                      {t("labels.machines.machine-table.machine-type")}
                    </Typography>
                    {this.renderSortingButton("type")}
                  </div>
                </TableCell>
                <TableCell>
                  <div className={classes.cell}>
                    <Typography variant={"subtitle2"}>
                      {t("labels.machines.machine-table.name")}
                    </Typography>
                    {this.renderSortingButton("name")}
                  </div>
                </TableCell>
                <TableCell>
                  <div className={classes.cell}>
                    <Typography variant={"subtitle2"}>
                      {t("labels.machines.machine-table.state")}
                    </Typography>
                    {this.renderSortingButton("state")}
                  </div>
                </TableCell>
                <TableCell>
                  <div className={classes.cell}>
                    <Typography variant={"subtitle2"}>
                      {t("labels.machines.machine-table.total-area")}
                    </Typography>
                    {this.renderSortingButton("area")}
                  </div>
                </TableCell>
                <TableCell>
                  <div className={classes.cell}>
                    <Typography variant={"subtitle2"}>
                      {t("labels.machines.machine-table.last-moved")}
                    </Typography>
                    {this.renderSortingButton("date")}
                  </div>
                </TableCell>
                <TableCell>
                  <div className={classes.cell}>
                    <BatteryChargingFull color={"primary"} />
                    {this.renderSortingButton("battery")}
                  </div>
                </TableCell>
                <TableCell>
                  <Typography variant={"subtitle2"}>
                    {t("labels.machines.machine-table.action")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.machines.map((machine) => {
                return (
                  <TableRow key={machine.id}>
                    <TableCell>
                      <Typography variant={"body1"}>
                        {machine.gpsTracker?.serialNumber}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant={"body1"}>
                        {t(`labels.machine-types.${machine.type}`)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant={"body1"}>{machine.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant={"body1"}>
                        {t(`labels.machine-states.${machine.state}`)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant={"body1"}>
                        {machine.totalFieldArea == undefined
                          ? 0
                          : machine.totalFieldArea.toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell>{this.renderDate(machine)}</TableCell>
                    <TableCell>
                      <Typography variant={"body1"}>
                        {`${BatteryPercentageService.getBatteryPercentage(
                          machine,
                        )}%`}
                      </Typography>
                      <Typography
                        variant={"body1"}>{`${BatteryVoltageService.getVoltage(
                        machine,
                      )}Volt`}</Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(event) =>
                          this.props.onActionOpen(event, machine.id)
                        }>
                        <Dehaze />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          keepMounted
          open={this.props.isMachineActionMenuOpen}
          anchorEl={this.props.machineMenuAnchor}
          onClose={() => this.props.onActionClose()}>
          <MenuItem onClick={() => this.props.onAdministrationClick()}>
            {t("actions.machine-actions.administration")}
            <ListItemIcon className={classes.editIcon}>
              <Edit color={"action"} fontSize="small" />
            </ListItemIcon>
          </MenuItem>
          <MenuItem onClick={() => this.props.onDeleteClick()}>
            {t("actions.machine-actions.delete")}
            <ListItemIcon className={classes.deleteIcon}>
              <Delete color={"error"} fontSize="small" />
            </ListItemIcon>
          </MenuItem>
        </Menu>
      </Card>
    );
  }

  private renderDate(machine: IMachine): React.ReactNode {
    if (machine.lastMoved == null) {
      return (
        <Typography variant={"body1"}>
          {this.props.t("labels.machines.no-movement")}
        </Typography>
      );
    }
    return (
      <>
        <Typography variant={"body1"}>
          {moment(machine.lastMoved).format("DD.MM.YYYY")}
        </Typography>
        <Typography variant={"body1"}>
          {`${moment(machine.lastMoved).format("HH:mm")} ${this.props.t(
            "labels.recordings.time",
          )}`}
        </Typography>
      </>
    );
  }

  private renderSortingButton(sortingKey: string): React.ReactNode {
    return (
      <SortingButton
        sortingKey={this.props.sortingKey}
        selectedSortingKey={sortingKey}
        sortInverse={this.props.sortInverse}
        onSortingClick={() => this.props.onSortingClick(sortingKey)}
      />
    );
  }
}

export const MachinesTable = withTranslation()(
  withStyles(styles)(MachinesTableComponent),
);
