import { IconButton, Theme, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import FileCopy from "@material-ui/icons/FileCopy";
import Share from "@material-ui/icons/Share";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2, 1, 0, 1),
    },
    textContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    overviewText: {
      opacity: 0.8,
      textAlign: "center",
    },
    column: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
  });

export interface IRecordingsShareOverviewProps
  extends WithStyles,
    WithTranslation {
  readonly onShareClick: () => void;
  readonly onShareLinkClick: () => void;
}

class RecordingsShareOverviewComponent extends React.PureComponent<
  IRecordingsShareOverviewProps
> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.column}>
          <IconButton onClick={() => this.props.onShareClick()}>
            <Share />
          </IconButton>
          <div className={classes.textContainer}>
            <Typography className={classes.overviewText} variant={"body1"}>
              {t("labels.recordings.info.share")}
            </Typography>
          </div>
        </div>
        <div className={classes.column}>
          <IconButton onClick={() => this.props.onShareClick()}>
            <FileCopy />
          </IconButton>
          <div className={classes.textContainer}>
            <Typography className={classes.overviewText} variant={"body1"}>
              {t("labels.recordings.info.copy-link")}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}
export const RecordingsShareOverview = withTranslation()(
  withStyles(styles)(RecordingsShareOverviewComponent),
);
