import {
  createStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import BatteryChargingFull from "@material-ui/icons/BatteryChargingFull";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { BatteryPercentageService } from "../../infrastructure/battery-percentage/BatteryPercentageService";
import { BatteryVoltageService } from "../../infrastructure/battery-percentage/BatteryVoltageService";
import {
  IGPSTracker,
  IMachine,
  IMachineCategory,
} from "../../infrastructure/machines";

interface IMachinesProfileProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly machine: IMachine;
  readonly categories: IMachineCategory[];
  readonly machineTypes: string[];
  readonly onMachineCategoryChange: (newValue: string) => void;
  readonly onMachineTypeChange: (newValue: string) => void;
  readonly onMachineWorkWidthChange: (newValue: number) => void;
  readonly onMachineSleepTimerChange: (newValue: number) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
    },
    rowSpaced: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    infoRow: {
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(2),
    },
    title: {
      paddingBottom: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    text: {
      alignText: "center",
      paddingBottom: theme.spacing(2),
    },
    infoText: {
      alignText: "center",
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontSize: theme.typography.fontSize,
    },
    paddedText: {
      alignText: "center",
      padding: theme.spacing(3, 0, 2, 0),
    },
    padding: {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(2),
    },
    selectItem: {
      width: "50%",
    },
    batteryIcon: {
      textAlign: "center",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "space-between",
    },
    voltPercentContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "space-between",
    },
    voltText: {
      paddingRight: theme.spacing(2),
    },
  });

class MachinesProfileState {
  public widthValue: number = 0;
  public sleepValue: number = 0;
}

class MachinesProfileComponent extends React.Component<
  IMachinesProfileProps,
  MachinesProfileState
> {
  public constructor(props: IMachinesProfileProps) {
    super(props);
    this.state = new MachinesProfileState();
  }
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography className={classes.text} variant={"h4"}>
            {this.props.machine.name}
          </Typography>
          <div className={classes.row}>
            <Typography>
              (
              {moment(this.props.machine.gpsTracker!.updatedAt).format(
                "DD.MM.YYYY",
              )}
              )
            </Typography>
            <BatteryChargingFull
              color={"primary"}
              className={classes.batteryIcon}
            />
            <div className={classes.voltPercentContainer}>
              <Typography
                className={classes.voltText}
                variant={"h4"}>{`${BatteryVoltageService.getVoltage(
                this.props.machine,
              )}Volt`}</Typography>
              <Typography
                variant={
                  "h4"
                }>{`${BatteryPercentageService.getBatteryPercentage(
                this.props.machine,
              )}%`}</Typography>
            </div>
          </div>
        </div>
        <Typography className={classes.text} variant={"body2"}>
          {t("labels.machines-administration.profile.title")}
        </Typography>
        <div className={classes.rowSpaced}>
          <Typography className={classes.text} variant={"caption"}>
            {t("labels.machines-administration.profile.name")}
          </Typography>
          <Typography className={classes.text} variant={"subtitle2"}>
            {this.props.machine.name}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.text} variant={"caption"}>
            {t("labels.machines-administration.profile.stock-number")}
          </Typography>
          <Typography className={classes.text} variant={"subtitle2"}>
            {this.props.machine.stockNumber}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.text} variant={"caption"}>
            {t("labels.machines-administration.profile.device-id")}
          </Typography>
          <Typography className={classes.text} variant={"subtitle2"}>
            {(this.props.machine.gpsTracker as IGPSTracker).serialNumber}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <div className={classes.padding}>
            <Typography className={classes.paddedText} variant={"caption"}>
              {t("labels.machines-administration.profile.category")}
            </Typography>
            <Select
              className={classes.selectItem}
              color={"primary"}
              variant={"outlined"}
              value={this.props.machine.category}
              onChange={(event) =>
                this.props.onMachineCategoryChange(event.target.value as string)
              }>
              {this.renderCategoriesOptions()}
            </Select>
          </div>
        </div>
        <div className={classes.rowSpaced}>
          <div className={classes.padding}>
            <Typography className={classes.paddedText} variant={"caption"}>
              {t("labels.machines-administration.profile.type")}
            </Typography>
            <Select
              className={classes.selectItem}
              color={"primary"}
              variant={"outlined"}
              value={this.props.machine.type}
              onChange={(event) =>
                this.props.onMachineTypeChange(event.target.value as string)
              }>
              {this.renderTypeOptions()}
            </Select>
          </div>
        </div>
        <div className={classes.rowSpaced}>
          <div className={classes.padding}>
            <Typography className={classes.paddedText} variant={"caption"}>
              {t("labels.machines-administration.profile.work-width")}
            </Typography>
            <TextField
              className={classes.selectItem}
              color={"primary"}
              variant={"outlined"}
              label={"cm"}
              type={"number"}
              value={this.state.widthValue}
              onChange={(event) =>
                this.setState({ widthValue: Number(event.currentTarget.value) })
              }
              onBlur={(event) =>
                this.props.onMachineWorkWidthChange(
                  Number(event.currentTarget.value),
                )
              }
            />
          </div>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.paddedText} variant={"caption"}>
            {t("labels.machines-administration.profile.sleep-timer")}
          </Typography>
          <TextField
            className={classes.selectItem}
            color={"primary"}
            variant={"outlined"}
            label={t(
              "labels.machines-administration.profile.sleep-timer-value",
            )}
            type={"number"}
            value={this.state.sleepValue}
            onChange={(event) => {
              this.setState({
                sleepValue: Number(event.currentTarget.value),
              });
            }}
            onBlur={(event) => {
              if (Number(event.currentTarget.value) > 120) {
                this.setState({
                  sleepValue: parseInt(event.currentTarget.value),
                });
                this.props.onMachineSleepTimerChange(
                  parseInt(event.currentTarget.value),
                );
              } else {
                this.setState({
                  sleepValue: Number(120),
                });
                this.props.onMachineSleepTimerChange(Number(120));
              }
            }}
          />
        </div>
        <Typography className={classes.text} variant={"body2"}>
          {t("labels.machines-administration.profile.device-information")}
        </Typography>
        <div className={classes.rowSpaced}>
          <Typography className={classes.text} variant={"caption"}>
            {t("labels.machines-administration.profile.firmware-version")}
          </Typography>
          <Typography className={classes.text} variant={"subtitle2"}>
            {(this.props.machine.gpsTracker as IGPSTracker)?.firmwareVersion}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.text} variant={"caption"}>
            {t("labels.machines-administration.profile.iccid")}
          </Typography>
          <Typography className={classes.text} variant={"subtitle2"}>
            {(this.props.machine.gpsTracker as IGPSTracker)?.iccid}
          </Typography>
        </div>
        <div className={classes.infoRow}>
          <InfoIcon />
          <Typography className={classes.infoText} variant={"subtitle2"}>
            {t("labels.machines-administration.profile.info")}
          </Typography>
        </div>
      </div>
    );
  }

  public componentDidMount(): void {
    let sleepValue = 0;
    if (this.props.machine.gpsTracker != undefined) {
      const sleepMap = this.props.machine.gpsTracker.configurations.find(
        (x) => x.key === "sleepTimer",
      );
      if (sleepMap != undefined) {
        sleepValue = Number(sleepMap.value);
      }
    }
    this.setState({
      widthValue: this.props.machine.workWidth,
      sleepValue: sleepValue,
    });
  }

  private renderCategoriesOptions(): React.ReactNode {
    return this.props.categories.map((x) => (
      <MenuItem value={x.name}>
        <Typography variant={"body1"}>
          {this.props.t(`labels.machine-categories.${x.name}`)}
        </Typography>
      </MenuItem>
    ));
  }

  private renderTypeOptions(): React.ReactNode {
    return this.props.machineTypes.map((x) => (
      <MenuItem value={x}>
        <Typography variant={"body1"}>
          {this.props.t(`labels.machine-types.${x}`)}
        </Typography>
      </MenuItem>
    ));
  }
}

export const MachinesProfile = withTranslation()(
  withStyles(styles)(MachinesProfileComponent),
);
