export enum TypeNames {
  application = "application",
  loggerFactory = "logger-factory",
  recordingsService = "recordings-service",
  machinesService = "machines-service",
  dashboardService = "dashboard-service",
  historyService = "history-service",
  alertsService = "alerts-service",
  authenticationService = "authentication-service",
  notificationCenter = "notification-center",
  machinesRepository = "machines-repository",
  machinesFactory = "machines-factory",
  sidebarFactory = "sidebar-factory",
}
