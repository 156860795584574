import { LoggerConfiguration } from "@econsor-mobile/logging";
import { ContainerModule, interfaces } from "inversify";
import { Application } from "../Application";
import { IAlertsService, AlertsService } from "./alerts";
import {
  AuthenticationService,
  IAuthenticationService,
} from "./authentication";
import { DashboardService, IDashboardService } from "./dashboard";
import { IHistoryService, HistoryService } from "./history";
import { ILoggerFactory, LoggerFactory } from "./logging";
import {
  IMachinesFactory,
  IMachinesRepository,
  IMachinesService,
  MachinesFactory,
  MachinesRepository,
  MachinesService,
} from "./machines";
import { NotificationCenter } from "./notification";
import { IRecordingsService, RecordingsService } from "./recordings";
import { ISidebarFactory } from "./sidebar/ISidebarFactory";
import { SidebarFactory } from "./sidebar/SidebarFactory";
import { TypeNames } from "./TypeNames";

export class Module extends ContainerModule {
  private static configure(
    bind: interfaces.Bind,
    loggerConfiguration: LoggerConfiguration,
  ): void {
    bind<Application>(TypeNames.application).to(Application);
    bind<ILoggerFactory>(TypeNames.loggerFactory).toConstantValue(
      new LoggerFactory(loggerConfiguration),
    );
    bind<IRecordingsService>(TypeNames.recordingsService)
      .to(RecordingsService)
      .inSingletonScope();
    bind<IMachinesService>(TypeNames.machinesService)
      .to(MachinesService)
      .inSingletonScope();
    bind<IMachinesRepository>(TypeNames.machinesRepository)
      .to(MachinesRepository)
      .inSingletonScope();
    bind<IMachinesFactory>(TypeNames.machinesFactory)
      .to(MachinesFactory)
      .inSingletonScope();
    bind<IHistoryService>(TypeNames.historyService)
      .to(HistoryService)
      .inSingletonScope();
    bind<IAlertsService>(TypeNames.alertsService)
      .to(AlertsService)
      .inSingletonScope();
    bind<IAuthenticationService>(TypeNames.authenticationService)
      .to(AuthenticationService)
      .inSingletonScope();
    bind<NotificationCenter>(TypeNames.notificationCenter)
      .to(NotificationCenter)
      .inSingletonScope();
    bind<IDashboardService>(TypeNames.dashboardService)
      .to(DashboardService)
      .inSingletonScope();
    bind<ISidebarFactory>(TypeNames.sidebarFactory)
      .to(SidebarFactory)
      .inSingletonScope();
  }

  public constructor(loggerConfiguration: LoggerConfiguration) {
    super((bind) => Module.configure(bind, loggerConfiguration));
  }
}
