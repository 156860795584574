import * as React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Routes } from "../../components/navigation/Routes";
import { PasswordRecoveryForm } from "../../components/password-recovery/PasswordRecoveryForm";
import dataUris from "../../components/resources/data-uris.json";
import { IAuthenticationService } from "../../infrastructure/authentication";
import { inject } from "../../infrastructure/injection";
import {
  NotificationCenter,
  NotificationTypes,
} from "../../infrastructure/notification";
import { TypeNames } from "../../infrastructure/TypeNames";

const styles = () =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      height: "100vh",
      flexDirection: "column",
      justifyContent: "center",
    },
  });

type IRootProps = WithStyles<typeof styles> &
  RouteComponentProps &
  WithTranslation;

class RootState {
  public password: string = "";
  public passwordAssurance: string = "";
  public passwordError?: string;
  public passwordAssuranceError?: string;
}

class RootComponent extends React.Component<IRootProps, RootState> {
  @inject(TypeNames.notificationCenter)
  private readonly _notificationCenter: NotificationCenter;
  @inject(TypeNames.authenticationService)
  private readonly _authenticationService: IAuthenticationService;
  public constructor(props: IRootProps) {
    super(props);
    this.state = new RootState();
  }

  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <PasswordRecoveryForm
          password={this.state.password}
          passwordAssurance={this.state.passwordAssurance}
          logo={dataUris.logo}
          onPasswordChange={(password: string) =>
            this.handlePasswordChanged(password)
          }
          onPasswordAssuranceChange={(passwordAssurance: string) =>
            this.handlePasswordAssuranceChanged(passwordAssurance)
          }
          onResetPasswordClick={() => this.handleResetPasswordClicked()}
          passwordError={this.state.passwordError}
          passwordAssuranceError={this.state.passwordAssuranceError}
        />
      </div>
    );
  }

  private handlePasswordChanged(password: string): void {
    this.setState({ password });
  }

  private handlePasswordAssuranceChanged(passwordAssurance: string): void {
    this.setState({ passwordAssurance });
  }

  private async handleResetPasswordClicked(): Promise<void> {
    const { t } = this.props;
    const codeIndex = this.props.location.search.indexOf("?code=");
    let passwordError, passwordAssuranceError;
    if (this.state.password !== this.state.passwordAssurance) {
      passwordError = t("labels.password-recovery.errors.password-assurance");
      passwordAssuranceError = t(
        "labels.password-recovery.errors.password-assurance",
      );
    }
    if (this.state.password.trim() === "") {
      passwordError = t("labels.password-recovery.errors.no-password");
    }
    if (this.state.passwordAssurance.trim() === "") {
      passwordAssuranceError = t(
        "labels.password-recovery.errors.no-password-assurance",
      );
    }
    this.setState({ passwordError, passwordAssuranceError });
    if (passwordError != undefined || passwordAssuranceError != undefined) {
      return;
    }
    if (codeIndex <= -1) {
      this._notificationCenter.sendNotification({
        notificationType: NotificationTypes.error,
        text: t("labels.password-recovery.errors.failed"),
      });
      return;
    }
    try {
      const codeEndIndex = this.props.location.search.indexOf("&", codeIndex);
      const code = this.props.location.search.substring(
        codeIndex + 6,
        codeEndIndex < 0 ? undefined : codeEndIndex,
      );
      await this._authenticationService.resetPassword(
        this.state.password,
        this.state.passwordAssurance,
        code,
      );
      this.props.history.push(Routes.signIn);
    } catch (e) {
      this._notificationCenter.sendNotification({
        notificationType: NotificationTypes.error,
        text: this.props.t("labels.password-recovery.errors.failed"),
      });
    }
  }
}

export const Root = withRouter(
  withTranslation()(withStyles(styles)(RootComponent)),
);
