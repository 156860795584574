module.exports = {
  "cultureCode": {
    "value": "de"
  },
  "translation": {
    "labels": {
      "language": {
        "german": "Deutsch",
        "english": "English"
      },
      "machine-types": {
        "frontPacker": "Frontpacker",
        "cultivator": "Grubber",
        "weeder": "Hackstriegel",
        "rotaryHarrow": "Kreiselegge",
        "lightCultivator": "Leichtgrubber",
        "cornHoe": "Maishacke",
        "rotaryMulcher": "Mulcher",
        "plow": "Pflug",
        "sowingCombination": "Saekombination",
        "discHarrow": "Scheibenegge",
        "roller": "Walze",
        "rotaryTedder": "Kreiselheuer",
        "mower": "Mähwerk",
        "swatherRake": "Schwader",
        "grasslandHarrow": "Grünlandstriegel",
        "pushOffTrailer": "Abschiebewagen",
        "slurryTanker": "Güllefass",
        "slurryTransport": "Gülletransport",
        "loadingWagon": "Ladewagen",
        "manureSpreader": "Miststreuer"
      },
      "machine-categories": {
        "tillage": "Bodenbearbeitung",
        "grasslandCultivation": "Grünlandbearbeitung",
        "transport": "Transport"
      },
      "machine-states": {
        "active": "Aktiv",
        "inactive": "Inaktiv",
        "archived": "Archiviert"
      },
      "sign-in": {
        "mail": "E-Mail-Addresse",
        "password": "Passwort",
        "header-title": "Anmelden",
        "no-account": "No account yet?",
        "title": "START MACHINE TRACKING",
        "subtitle": "Behalten Sie die Übersicht und tracken Sie Ihre Maschinenbewegungen",
        "errors": {
          "failed": "Anmelden fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.",
          "mail": "Bitte geben sie eine E-Mail Addresse an",
          "password": "Bitte geben sie ein Passwort ein"
        }
      },
      "sidebar": {
        "welcome": "Willkommen zurück,"
      },
      "dashboard": {
        "title": "Dashboard",
        "active-machines": "Aktive Maschinen",
        "overview": "Übersicht",
        "tillage": "Bodenbearbeitung",
        "grassland-cultivation": "Grünlandbearbeitung",
        "transports": "Fuhren",
        "operating-hours": "Betriebsstunden",
        "records": "Aufzeichnungen",
        "marker-information": "Die roten Marker auf der Übersichtskarte, stellen den letzten übermittelten Standort einer Maschine dar."
      },
      "recordings": {
        "title": "Aufzeichnungen",
        "filter": "Filter",
        "machine-type": "Maschinentyp",
        "page": "Seite: ",
        "select": {
          "show-all": "Alle anzeigen",
          "show-less": "Weniger anzeigen",
          "none": "Keine"
        },
        "button-title": "Aufnahme hinzufügen",
        "search": "Suchen",
        "search-placeholder": "Suche nach ID oder Maschine",
        "time": "Uhr",
        "table-headers": {
          "ids": "ID",
          "machine": "Maschine",
          "fields": "Fuhren | Felder",
          "area": "Fläche (Hektar)",
          "date": "Datum",
          "action": "Aktion",
          "all": "Alle",
          "processed": "Bearbeitet",
          "field-area": "Feldfläche",
          "deviation": "Abweichung",
          "search": "Suchen...",
          "status": "Status",
          "all-values": "Alle Werte anzeigen (Fuhren | Felder 0)"
        },
        "actions": {
          "finished": "Fertig",
          "analyzing": "Analysieren",
          "open": "Offen"
        },
        "card": {
          "processed": "Bearbeitet",
          "processed-value": "{{value}} ha",
          "field-area": "Feldfläche",
          "field-area-value": "{{value}} ha",
          "placeholder": "Für die ausgewählte Aufzeichnung konnte kein Feld gefunden werden"
        },
        "info": {
          "title": "Info",
          "machine-type": "Maschinen TYP",
          "name": "Name",
          "recording-begin": "Beginn Aufzeichnung",
          "recording-end": "Ende Aufzeichnung",
          "duration": "Gesamtzeit",
          "duration-value": "{{hours}} Std. {{minutes}} min.",
          "area-processed": "Fläche Bearbeitet",
          "area-processed-value": "{{value}} ha",
          "area-total": "Fläche Gesamt",
          "area-total-value": "{{value}} ha",
          "fields": "Einzelflächen",
          "share": "Teilen",
          "copy-link": "Link kopieren"
        }
      },
      "recordings-detailed": {
        "title": "Aufzeichnungen - Info",
        "subtitle": "Aufzeichnungen > Info",
        "print": "Drucken",
        "copy-link": "Link kopieren",
        "recording-info": {
          "title": "Aufzeichnung - Information",
          "machine-id": "Maschinen ID",
          "machine-type": "Maschinen TYP",
          "name": "Name",
          "recording-begin": "Beginn Aufzeichnung",
          "recording-end": "Ende Aufzeichnung",
          "total-duration": "Gesamtzeit",
          "total-duration-fields": "Gesamtzeit auf Flächen",
          "fields": "Einzelflächen",
          "area-processed": "Fläche Bearbeitet",
          "area-processed-value": "{{value}} ha",
          "area": "Fläche Gesamt (Feldgrenzen)",
          "area-value": "{{value}} ha",
          "transports": "Fuhren"
        },
        "field-info": {
          "title": "Feldinformation",
          "field-entrance": "Feldeintritt",
          "field-exit": "Feldaustritt",
          "work-duration": "Arbeitszeit",
          "field-size": "Größe nach Feldgrenzen",
          "field-size-value": "{{value}} ha",
          "processed-area": "Bearbeitete Fläche",
          "processed-area-value": "{{value}} ha"
        },
        "google-maps": {
          "path": "Weg",
          "area": "Bereich",
          "calculate": "Berechnen",
          "dialog-area-info": "Ihre berechnete Fläche beträgt: ",
          "dialog-distance-info": "Der Abstand zwischen den Punkten beträgt: "
        }
      },
      "recalculation-dialog": {
        "title": "Aufzeichnung - Neuberechnung",
        "total-area": "Gesamtfläche",
        "single-area": "Einzelflächen",
        "processed": "Bearbeitet",
        "processed-value": "{{value}} ha",
        "area": "Feldfläche",
        "area-value": "{{value}} ha",
        "information": "Parameter anpassen um Fehler in der Berechnung zu korrigieren",
        "sliders": {
          "min-distance": "Maximalabstand Fahrspuren (Dist)",
          "min-length": "Mindestlänge Fahrspur (Lng)",
          "max-curvature": "Maximale Winkelabweichung von gerader Fahrspur (Slp)",
          "radius": "Vorgewende Akzeptanz-Radius (Crad)"
        }
      },
      "machines": {
        "title": "Maschinen",
        "filter": "Filter",
        "machine-type": "Maschinentyp",
        "no-movement": "Keine",
        "search-placeholder": "Suche nach ID oder Name",
        "machine-table": {
          "ids": "ID",
          "machine-type": "Maschinentyp",
          "name": "Name",
          "state": "Status",
          "total-area": "Gesamt Fläche (berechnet)",
          "last-moved": "Letzte Bewegung",
          "action": "Aktion"
        }
      },
      "create-machine": {
        "gps-tracker-title": "GPS Tracker",
        "gps-serial-number": "Seriennummer",
        "machine-title": "Maschine",
        "machine-stock-number": "Maschinen Bestandsnummer",
        "machine-name": "Name",
        "machine-work-width": "Arbeitsbreite (cm)",
        "errors": {
          "failed": "Beim Versuch, eine Maschine anzulegen, ist ein Fehler aufgetreten"
        }
      },
      "machines-administration": {
        "title": "Maschinenverwaltung",
        "profile": {
          "title": "Machinen Profil",
          "name": "Name",
          "stock-number": "Maschinen Bestandsnummer",
          "device-id": "Geräte ID (Seriennummer)",
          "category": "Maschinen Kategorie",
          "type": "Maschinen Typ",
          "work-width": "Arbeitsbreite",
          "work-width-value": "{{value}} m",
          "sleep-timer": "Sleep Timer (min. 120)",
          "sleep-timer-value": "{{value}} Sekunden",
          "device-information": "Geräte Information",
          "firmware-version": "Firmware Version",
          "iccid": "ICCID",
          "info": "Willkommen in der Maschinenverwaltung, nutzen Sie das Maschinenprofil um einen GPS-Tracker anzumelden. (Wichtig! Geben Sie unbedingt die zur Maschine passende Maschinen Kategorie, Machinen Typ und Arbeitsbreite an)"
        },
        "statistics": {
          "title": "Maschinen- Statistik",
          "operating-hours": "Betriebsstunden",
          "operating-hours-value": "{{hours}} Std.",
          "traveled-distance": "Zurückgelegter Weg",
          "traveled-distance-value": "{{value}} Km",
          "area-processed": "Fläche Bearbeitet (Berechnet)",
          "area-processed-value": "{{value}} ha",
          "field-area-total": "Feldfläche Gesamt (Feldgrenzen)",
          "field-area-total-value": "{{value}} ha"
        },
        "geoFence": {
          "title": "Geofence",
          "radius": "Radius in Meter",
          "tooltip": "Parkposition der Maschine + Sicherheitsradius markieren",
          "add-button": "Geofence anlegen",
          "success-message": "Geofence wurde erfolgreich gespeichert",
          "error-message": "Geofence konnte nicht gespeichert werden"
        }
      },
      "alerts": {
        "title": "Alarm",
        "filter": "Filter",
        "machine-type": "Maschinentyp",
        "info": {
          "batterylow": "Batteriestatus kritisch !"
        },
        "machine-table": {
          "ids": "Maschinen ID",
          "machine-type": "Maschinentyp",
          "name": "Maschinenname",
          "state": "Status",
          "info": "Info",
          "date": "Datum",
          "action": "Aktion"
        }
      },
      "history": {
        "title": "Historie",
        "path": "Historie",
        "search": "Suchen",
        "machine-information": "Machinen Information",
        "name": "Name",
        "machine-nr": "Machinen Bestandsnummer",
        "machine-id": "Geräte ID",
        "machine-category": "Machinen Kategorie",
        "machine-type": "Machinen Typ",
        "work-width": "Arbeitsbreite",
        "work-width-value": "{{value}} cm",
        "end": "Ende",
        "from": "Von",
        "until": "Bis",
        "search-placeholder": "Suche nach Maschinenname",

        "timeline": {
          "no-entry": "Kein Eintrag für Ihre Suche gefunden.",
          "left-parking-space": "Stellplatz verlassen",
          "enter-parking-space": "Stellplatz erreicht",
          "absent": "Abwesenheit Parkposition (Geofence)",
          "field-entry": "Feldeintritt",
          "field-exit": "Feldaustritt",
          "field": "Feld",
          "fields": "Felder",
          "transports": "Fuhren",
          "upload": "Upload Aufzeichnung ",
          "max-surface": "Gesamtfläche",
          "surface": "Fläche",
          "processed": "Bearbeitet",
          "area": "Feldgrenzen",
          "hektar": "Hektar",
          "start-recording": "Beginn Aufzeichnung",
          "end-recording": "Ende Aufzeichnung",
          "max-time": "Gesamtzeit",
          "work-time": "Arbeitszeit"
        }
      },
      "sign-up": {
        "title": "Registrieren",
        "username": "Benutzer name",
        "mail": "Email",
        "password": "Passwort",
        "password-assurance": "Passwort wiederholen",
        "terms-and-conditions": "Ich stimme den AGB zu",
        "has-account": "Haben Sie schon ein Konto?",
        "errors": {
          "failed": "Beim Versuch, sich zu registrieren, ist ein Fehler aufgetreten",
          "user-name": "Bitte geben sie einen Benutzernamen ein",
          "mail": "Bitte geben sie eine E-Mail Addresse an",
          "no-password": "Bitte geben sie ein Passwort ein",
          "no-password-assurance": "Bitte wiederholen sie ihr Passwort",
          "password-assurance": "Die eingegebenen Passwörter stimmen nicht überein",
          "terms-and-conditions": "Bitte stimmen sie den AGB zu"
        }
      },
      "request-password-recovery": {
        "title": "Passwort wiederherstellen",
        "mail": "Email adresse",
        "success": "Sie sollten in kürze eine E-Mail erhalten, um Ihr Passwort zu ändern",
        "errors": {
          "failed": "Beim Versuch, eine Passwort zurücksetzung anzufordern, ist ein Fehler aufgetreten.",
          "mail": "Bitte geben sie eine E-Mail Addresse an"
        }
      },
      "password-recovery": {
        "title": "Neues passwort",
        "password": "Passwort",
        "password-assurance": "Passwort wiederholen",
        "errors": {
          "failed": "Beim Versuch, das Passwort zurückzusetzen, ist ein Fehler aufgetreten.",
          "no-password": "Bitte geben sie ein Passwort ein",
          "no-password-assurance": "Bitte wiederholen sie ihr Passwort",
          "password-assurance": "Die eingegebenen Passwörter stimmen nicht überein"
        }
      },
      "create-machine-dialog": {
        "title": "Maschine anlegen"
      }
    },
    "actions": {
      "header": {
        "sign-out": "Abmelden",
        "language": {
          "german": "Deutsch",
          "english": "Englisch"
        }
      },
      "sidebar": {
        "dashboard": "Dashboard",
        "recordings": "Aufzeichnungen",
        "machines": "Maschinen",
        "history": "Historie",
        "alerts": "Alarm",
        "miscellaneous": "Sonstige",
        "logout": "Ausloggen"
      },
      "sign-in": {
        "sign-in": "Anmelden",
        "sign-in-now": "Jetzt registrieren",
        "recover-password": "Passwort vergessen",
        "register-device": "Gerät Registrieren"
      },
      "sign-up": {
        "register": "Registrieren",
        "click-here": "Hier klicken"
      },
      "request-password-recovery": {
        "recover": "Passwort zurücksetzen",
        "go-back": "Zur Anmeldung"
      },
      "password-recovery": {
        "recover": "Passwort zurücksetzen"
      },
      "dashboard": {
        "administration": "Bearbeiten",
        "history": "Historie",
        "google-maps": {
          "title": "Machine-ID:"
        }
      },
      "recordings": {
        "table-actions": {
          "delete": "Löschen"
        }
      },
      "recordings-actions": {
        "detailed": "Bearbeiten",
        "delete": "Löschen"
      },
      "recordings-detailed": {
        "export": "Export",
        "export-csv": "Excel CSV",
        "export-link": "Link kopieren",
        "recalculate": "Neuberechnung"
      },
      "recalculation-dialog": {
        "recalculate": "Neuberechnung"
      },
      "machine-actions": {
        "administration": "Bearbeiten",
        "delete": "Löschen"
      },
      "machines-administration": {
        "history": "Historie",
        "statistic-types": {
          "operating-hours": "Betriebsstunden",
          "area-processed": "Fläche Bearbeitet",
          "field-area": "Feldfläche Gesamt"
        }
      },
      "machines": {
        "create": "Maschine anlegen"
      },
      "create-machine": {
        "create": "Maschine anlegen",
        "cancel": "Abbrechen"
      }
    }
  }
}
;