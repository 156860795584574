import {
  Button,
  createStyles,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "30%",
      height: "100%",
      alignSelf: "center",
      flexDirection: "column",
      padding: theme.spacing(2),
    },
    componentContainer: {
      padding: theme.spacing(5, 2),
    },
    logoContainer: {
      display: "flex",
      height: "12%",
      width: "70%",
      alignSelf: "center",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: theme.spacing(30),
    },
    image: {
      resizeMode: "contain",
      height: "100%",
      width: "100%",
    },
    title: {
      textAlign: "center",
    },
  });

export interface IPasswordRecoveryFormProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly password: string;
  readonly passwordAssurance: string;
  readonly logo: string;
  readonly onPasswordChange: (value: string) => void;
  readonly onPasswordAssuranceChange: (value: string) => void;
  readonly onResetPasswordClick: () => void;
  readonly passwordError?: string;
  readonly passwordAssuranceError?: string;
}

export class PasswordRecoveryFormComponent extends React.PureComponent<
  IPasswordRecoveryFormProps
> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.logoContainer}>
          <img className={classes.image} src={this.props.logo} />
        </div>
        <Typography className={classes.title} variant={"h4"}>
          {t("labels.password-recovery.title")}
        </Typography>
        <div className={classes.componentContainer}>
          <TextField
            variant={"outlined"}
            required
            fullWidth
            type={"password"}
            label={t("labels.password-recovery.password")}
            value={this.props.password}
            onChange={(event) =>
              this.props.onPasswordChange(event.target.value)
            }
            error={this.props.passwordError != undefined}
            helperText={this.props.passwordError}
          />
        </div>
        <div className={classes.componentContainer}>
          <TextField
            variant={"outlined"}
            required
            fullWidth
            type={"password"}
            label={t("labels.password-recovery.password-assurance")}
            value={this.props.passwordAssurance}
            onChange={(event) =>
              this.props.onPasswordAssuranceChange(event.target.value)
            }
            error={this.props.passwordAssuranceError != undefined}
            helperText={this.props.passwordAssuranceError}
          />
        </div>
        <div className={classes.componentContainer}>
          <Button
            fullWidth
            color={"primary"}
            variant={"contained"}
            onClick={() => this.props.onResetPasswordClick()}>
            {t("actions.password-recovery.recover")}
          </Button>
        </div>
      </div>
    );
  }
}

export const PasswordRecoveryForm = withTranslation()(
  withStyles(styles)(PasswordRecoveryFormComponent),
);
