import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ContentHeader } from "../../components/dashboard";
import { BasePage } from "../../components/general/BasePage";
import { Routes } from "../../components/navigation/Routes";
import { Pagination } from "../../components/pagintaion/Pagination";
import { RecordingsCard, RecordingsTable } from "../../components/recordings";
import { EmptyRecordingsCard } from "../../components/recordings/EmptyRecordingsCard";
import {
  IApplicationStoreContextConsumerProps,
  withApplicationStateStoreContext,
} from "../../infrastructure/application-state";
import { inject } from "../../infrastructure/injection";
import {
  IMachineCategory,
  IMachinesService,
} from "../../infrastructure/machines";
import {
  IRecording,
  IRecordingsService,
} from "../../infrastructure/recordings";
import { TypeNames } from "../../infrastructure/TypeNames";

const styles = (theme: Theme) =>
  createStyles({
    head: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      paddingBottom: theme.spacing(3),
    },
    body: {
      flex: 15,
      display: "flex",
      flexDirection: "column",
    },
    tableContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    resizeTableContainer: {
      height: 500,
      display: "flex",
      flexDirection: "column",
    },
    mapChild: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: theme.spacing(2, 2, 0, 0),
    },
    mapLastChild: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingTop: theme.spacing(2),
    },
    mapContainer: {
      position: "sticky",
      bottom: 0,
      display: "flex",
      flex: 1,
      flexDirection: "row",
      backgroundColor: theme.palette.background.default,
    },
  });

type IRootProps = WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps &
  IApplicationStoreContextConsumerProps;

class RootState {
  public search: string = "";
  public selectedMachineType: string = "";
  public categories: IMachineCategory[] = [];
  public recordingsActionId?: number;
  public zoom = 15;
  public records: IRecording[] = [];
  public isRecordingsActionMenuOpen: boolean = false;
  public selectedRecordingIds: number = -1;
  public checkedRecordingIds: number[] = [];
  public selectedRecordingData?: IRecording;
  public recordingsMenuAnchor?: HTMLElement;
  public sortingKey: string = "date";
  public sortInverse: boolean = false;
  public isDialogOpen: boolean = false;
  public isExpanded: boolean = false;
  public isLoading: boolean = true;
  public currentPage: number = 1;
  public maxPages: number = 1;
  public showAllValues: boolean = false;
}

class RootComponent extends React.Component<IRootProps, RootState> {
  @inject(TypeNames.recordingsService)
  private readonly _recordingsService: IRecordingsService;

  @inject(TypeNames.machinesService)
  private readonly _machineService: IMachinesService;
  public constructor(props: IRootProps) {
    super(props);
    this.state = new RootState();
  }
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <BasePage
        currentBaseRoute={Routes.recordings}
        onRouteClick={(newRoute: Routes) => this.props.history.push(newRoute)}>
        <div className={classes.head}>
          <ContentHeader
            iconProps={{
              isIcon: true,
            }}
            title={t("labels.recordings.title")}
          />
        </div>
        <div className={classes.body}>
          <div className={classes.tableContainer}>
            <RecordingsTable
              isLoading={this.state.isLoading}
              isExpanded={this.state.isExpanded}
              onShowAllButtonClick={() => this.handleShowAllButtonClicked()}
              onMachineCategoryDialogClicked={(isDialogOpen) =>
                this.handleMachineCategoryDialogOpen(isDialogOpen)
              }
              isDialogOpen={this.state.isDialogOpen}
              sortingKey={this.state.sortingKey}
              onSortingClick={(sortingKey) =>
                this.handleSortingClicked(sortingKey)
              }
              sortInverse={this.state.sortInverse}
              selectedDataId={this.state.selectedRecordingIds}
              checkedDataIds={this.state.checkedRecordingIds}
              search={this.state.search}
              selectedMachineType={this.state.selectedMachineType}
              onMachineChange={(selectedMachineType: string) =>
                this.handleMachineChanged(selectedMachineType)
              }
              onSearchChange={(search: string) =>
                this.handleSearchChanged(search)
              }
              onSearchClick={() => this.handleSearchClicked()}
              onActionOpen={(id) => this.handleActionOpen(id)}
              isRecordingsActionMenuOpen={this.state.isRecordingsActionMenuOpen}
              onActionClose={() => this.handleActionClose()}
              onDetailedClick={() => this.handleOnDetailedClicked()}
              onDeleteClick={() => this.handleOnDeletedClicked()}
              recordingsMenuAnchor={this.state.recordingsMenuAnchor}
              data={this.state.records}
              onSelect={(recordingId) =>
                this.handleRecordingSelected(recordingId)
              }
              onCheck={(recordingId, checked) =>
                this.handleRecordingChecked(recordingId, checked)
              }
              categories={this.state.categories}
              onAllClicked={() => this.handleAllClicked()}
              showAllValues={this.state.showAllValues}
              onShowAllValuesClicked={() => this.handleOnShowAllValuesPressed()}
            />
          </div>
          <div className={classes.mapContainer}>
            {this.renderRecordingFields()}
          </div>
          <Pagination
            onPagePress={(page: number) => this.handleOnPagePress(page)}
            currentPage={this.state.currentPage}
            maxPages={this.state.maxPages}
          />
        </div>
      </BasePage>
    );
  }

  public async componentDidMount(): Promise<void> {
    await this.getMachineCategories();
    await this.getRecordings();
  }

  private handleSortingClicked(sortingKey: string): void {
    switch (sortingKey) {
      case "id":
      case "machine":
      case "fields":
      case "date":
      case "state":
        {
          this.setState({ sortInverse: !this.state.sortInverse, sortingKey });
        }
        break;
      default:
        break;
    }
  }

  private async getRecordings(): Promise<void> {
    if (this.props.store.state.jwt != undefined) {
      this.setState({ isLoading: true });
      const records = await this._recordingsService.getRecordings(
        this.props.store.state.jwt,
        this.state.currentPage,
        this.state.search,
        this.state.selectedMachineType,
        this.state.showAllValues,
      );
      const pageCount = await this._recordingsService.getRecordingsPages(
        this.props.store.state.jwt,
        this.state.search,
        this.state.selectedMachineType,
        this.state.showAllValues,
      );
      this.setState({ records, maxPages: pageCount, isLoading: false });
    }
  }
  private async getMachineCategories(): Promise<void> {
    if (this.props.store.state.jwt == undefined) {
      return;
    }
    const categories = await this._machineService.getMachineCategories(
      this.props.store.state.jwt,
    );
    this.setState({ categories });
  }
  private handleActionOpen(recordingsId: number): void {
    const route = Routes.recordingsDetailed.replace(
      ":recordingId",
      recordingsId.toString(),
    );
    this.props.history.push(route);
  }
  private handleActionClose(): void {
    this.setState({
      isRecordingsActionMenuOpen: false,
      recordingsActionId: undefined,
      recordingsMenuAnchor: undefined,
    });
  }
  private handleMachineChanged(selectedMachineType: string): void {
    this.setState({ selectedMachineType, isDialogOpen: false }, async () => {
      this.setState({ currentPage: 1 }, async () => await this.getRecordings());
    });
  }
  private handleSearchChanged(search: string): void {
    this.setState({ search });
  }

  private handleOnDetailedClicked(): void {
    if (this.state.recordingsActionId != undefined) {
      const route = Routes.recordingsDetailed.replace(
        ":recordingId",
        this.state.recordingsActionId.toString(),
      );
      this.props.history.push(route);
    }
  }

  private async handleOnDeletedClicked(): Promise<void> {
    this.setState({ isLoading: true });
    for (const x of this.state.checkedRecordingIds) {
      await this._recordingsService.deleteRecording(
        x,
        this.props.store.state.jwt,
      );
    }
    await this.getRecordings();
    this.setState({
      isLoading: false,
      checkedRecordingIds: [],
      selectedRecordingIds: -1,
      selectedRecordingData: undefined,
    });
  }

  private async handleRecordingSelected(
    selectedRecordingId: number,
  ): Promise<void> {
    const alreadyChecked =
      selectedRecordingId === this.state.selectedRecordingIds;
    if (alreadyChecked) {
      this.setState({
        selectedRecordingIds: -1,
        selectedRecordingData: undefined,
      });
    } else {
      if (this.props.store.state.jwt != undefined) {
        const recording = await this._recordingsService.getRecording(
          this.props.store.state.jwt,
          selectedRecordingId,
        );
        this.setState({
          selectedRecordingIds: selectedRecordingId,
          selectedRecordingData: recording,
        });
      }
    }
  }

  private handleRecordingChecked(recordingId: number, checked: boolean): void {
    if (checked) {
      const checkedRecordingIds = this.state.checkedRecordingIds;
      checkedRecordingIds.splice(
        this.state.checkedRecordingIds.indexOf(recordingId),
        1,
      );
      this.setState({
        checkedRecordingIds,
      });
    } else {
      const checkedRecordingIds = this.state.checkedRecordingIds;
      checkedRecordingIds.push(recordingId);
      this.setState({
        checkedRecordingIds,
      });
    }
  }

  private handleAllClicked(): void {
    if (this.state.checkedRecordingIds.length === this.state.records.length) {
      this.setState({ checkedRecordingIds: [] });
    } else {
      const checkedRecordingIds = this.state.records.map((x) => x.id);
      this.setState({ checkedRecordingIds });
    }
  }

  private renderRecordingFields(): React.ReactNode {
    const { classes } = this.props;
    const recording = this.state.selectedRecordingData;
    if (recording != undefined) {
      if (recording.fields.length < 1) {
        return (
          <div className={classes.mapLastChild}>
            <EmptyRecordingsCard />
          </div>
        );
      }
      return recording.fields.map((field, index) => {
        if (recording.fields.length - 1 === index) {
          return (
            <div key={index} className={classes.mapLastChild}>
              <RecordingsCard
                zoom={this.state.zoom}
                processed={field.processedArea}
                fieldArea={field.fieldArea}
                field={field}
              />
            </div>
          );
        } else {
          return (
            <div key={index} className={classes.mapChild}>
              <RecordingsCard
                zoom={this.state.zoom}
                processed={field.processedArea}
                fieldArea={field.fieldArea}
                field={field}
              />
            </div>
          );
        }
      });
    }
    return false;
  }

  private handleMachineCategoryDialogOpen(isDialogOpen: boolean): void {
    this.setState({ isDialogOpen });
  }
  private handleShowAllButtonClicked(): void {
    this.setState({
      isExpanded: !this.state.isExpanded,
      isDialogOpen: true,
    });
  }

  private handleOnPagePress(page: number): void {
    this.setState({ currentPage: page }, async () => {
      await this.getRecordings();
    });
  }

  private async handleSearchClicked(): Promise<void> {
    this.setState({ currentPage: 1 }, async () => await this.getRecordings());
  }

  private async handleOnShowAllValuesPressed(): Promise<void> {
    this.setState(
      { showAllValues: !this.state.showAllValues, currentPage: 1 },
      async () => await this.getRecordings(),
    );
  }
}

export const Root = withApplicationStateStoreContext(
  withRouter(withTranslation()(withStyles(styles)(RootComponent))),
);
