import { Menu, MenuItem } from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

export interface ILanguageDropDownProps extends WithTranslation {
  readonly isLanguageMenuOpen: boolean;
  readonly anchorElement: (EventTarget & HTMLElement) | undefined;
  readonly onMenuClose: () => void;
  readonly onLanguageClick: (language: string) => void;
}

class LanguageDropDownComponent extends React.Component<
  ILanguageDropDownProps
> {
  public render(): React.ReactNode {
    const { t } = this.props;
    return (
      <Menu
        open={this.props.isLanguageMenuOpen}
        anchorEl={this.props.anchorElement}
        keepMounted
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => this.props.onMenuClose()}>
        <MenuItem onClick={() => this.props.onLanguageClick("de")}>
          {t("labels.language.german")}
        </MenuItem>
        <MenuItem onClick={() => this.props.onLanguageClick("en")}>
          {t("labels.language.english")}
        </MenuItem>
      </Menu>
    );
  }
}

export const LanguageDropDown = withTranslation()(LanguageDropDownComponent);
