import {
  Checkbox,
  createStyles,
  Link,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import PageviewIcon from "@material-ui/icons/Pageview";
import moment from "moment";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IMachine } from "../../infrastructure/machines";
import { IRecording, RecordingState } from "../../infrastructure/recordings";
import { Routes } from "../navigation/Routes";
import { RecordingsAnalyzingAction } from "./RecordingsAnalyzingAction";
import { RecordingsFinishedAction } from "./RecordingsFinishedAction";
import { RecordingsOpenAction } from "./RecordingsOpenAction";

const styles = (theme: Theme) =>
  createStyles({
    cell: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    areaCell: {
      borderRightStyle: "dashed",
      borderLeftStyle: "dashed",
      borderRightWidth: 1,
      borderLeftWidth: 1,
      borderColor: theme.palette.divider,
    },
    processedCell: {
      borderLeftStyle: "dashed",
      borderLeftWidth: 1,
      borderColor: theme.palette.divider,
    },
    deviationCell: {
      borderRightStyle: "dashed",
      borderRightWidth: 1,
      borderColor: theme.palette.divider,
    },
    allIds: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(2),
    },
    test: {
      flex: 1,
      display: "flex",
    },
    centerCell: {
      justifyContent: "center",
      alignItems: "center",
    },
    dateText: {
      textAlign: "center",
      alignSelf: "center",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    gpsText: {
      fontWeight: "bold",
      opacity: 0.8,
    },
    fieldText: {
      fontWeight: "bold",
      opacity: 0.5,
    },
    column: {
      cursor: "pointer",
    },
    selectedColumn: {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
    },
    icon: {
      backgroundColor: "white",
      borderRadius: 5,
    },
  });

export interface IRecordingsTableBodyProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly data: IRecording[];
  readonly onActionOpen: (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    recordingId: number,
  ) => void;
  readonly onSelect: (id: number, checked: boolean) => void;
  readonly onCheck: (id: number, checked: boolean) => void;
  readonly selectedDataId: number;
  readonly checkedDataIds: number[];
  readonly sortInverse: boolean;
  readonly sortingKey: string;
}

class RecordingsTableBodyComponent extends React.Component<
  IRecordingsTableBodyProps
> {
  public constructor(props: IRecordingsTableBodyProps) {
    super(props);
  }
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <TableBody>
        {this.getSortedData().map((item, index) => {
          const processed =
            item.processedArea == undefined ? 0 : item.processedArea;
          const fieldArea = item.fieldArea == undefined ? 0 : item.fieldArea;
          const deviation = processed - fieldArea;
          return (
            <TableRow
              tabIndex={index}
              key={item.id}
              className={
                this.props.selectedDataId === item.id
                  ? classes.selectedColumn
                  : classes.column
              }
              onClick={() =>
                this.props.onSelect(
                  item.id,
                  this.props.selectedDataId === item.id,
                )
              }>
              <TableCell>
                <Checkbox
                  onClick={(event) => {
                    this.props.onCheck(
                      item.id,
                      this.props.checkedDataIds.find((x) => x === item.id) !=
                        undefined,
                    );
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                  checked={
                    this.props.checkedDataIds.find((x) => x === item.id) !=
                    undefined
                  }
                />
              </TableCell>
              <TableCell>
                <Typography variant={"body1"}>{item.id}</Typography>
                <div className={classes.row}>
                  <Typography className={classes.gpsText} variant={"body1"}>
                    {RecordingsTableBodyComponent.getGpsId(
                      item.machine as IMachine,
                    )}
                  </Typography>
                  <Typography>{" - "}</Typography>
                  <Typography className={classes.fieldText} variant={"body1"}>
                    {item.file}
                  </Typography>
                </div>
              </TableCell>
              <TableCell>
                <Typography variant={"body1"}>
                  {(item.machine as IMachine)?.name}
                </Typography>
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                <Typography variant={"body1"}>
                  {item.transports} | {item.fieldsCount}
                </Typography>
              </TableCell>
              <TableCell className={classes.processedCell}>
                <Typography variant={"body1"}>
                  {processed.toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={"body1"}>
                  {fieldArea.toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell className={classes.deviationCell}>
                <Typography variant={"body1"}>
                  {deviation.toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell className={classes.centerCell}>
                <Typography className={classes.dateText} variant={"body1"}>
                  {moment(item.date).format("DD.MM.YYYY")}
                </Typography>
                <Typography className={classes.dateText} variant={"body1"}>
                  {`${moment(item.date).format("HH:mm")} ${t(
                    "labels.recordings.time",
                  )}`}
                </Typography>
              </TableCell>
              <TableCell>
                {RecordingsTableBodyComponent.renderAction(item.state)}
              </TableCell>
              <TableCell>{this.renderActionButton(item.state, item)}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }
  private getSortedData(): IRecording[] {
    let data = this.props.data;
    switch (this.props.sortingKey) {
      case "id":
        data = this.props.data.sort((a, b) => a.id - b.id);
        break;
      case "machine":
        data = this.props.data.sort((a, b) =>
          (a.machine as IMachine)?.name > (b.machine as IMachine)?.name
            ? 1
            : (b.machine as IMachine)?.name > (a.machine as IMachine)?.name
            ? -1
            : 0,
        );
        break;
      case "fields":
        data = this.props.data.sort(
          (a, b) =>
            b.fieldsCount + b.transports - (a.fieldsCount + a.transports),
        );
        break;
      case "area":
        break;
      case "date":
        data = this.props.data.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
        );
        break;
      case "state":
        data = this.props.data.sort((a, b) => {
          const map = {
            [RecordingState.open]: 1,
            [RecordingState.inProgress]: 2,
            [RecordingState.finished]: 3,
          };
          return map[a.state] < map[b.state]
            ? -1
            : map[a.state] > map[b.state]
            ? 1
            : 0;
        });
        break;
    }
    if (this.props.sortInverse) {
      data.reverse();
    }
    return data;
  }

  private renderActionButton(
    state: RecordingState,
    recording: IRecording,
  ): React.ReactNode {
    if (state === RecordingState.finished) {
      return (
        <Link
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          onClick={(event: any) => {
            this.props.onActionOpen(event, recording.id);
            event.stopPropagation();
            event.preventDefault();
          }}>
          <a
            href={Routes.recordingsDetailed.replace(
              ":recordingId",
              recording.id.toString(),
            )}>
            <PageviewIcon
              fontSize={"large"}
              color={"primary"}
              className={this.props.classes.icon}
            />
          </a>
        </Link>
      );
    }
    return false;
  }
  private static renderAction(action: RecordingState): React.ReactNode {
    switch (action) {
      case RecordingState.finished:
        return <RecordingsFinishedAction />;
      case RecordingState.inProgress:
        return <RecordingsAnalyzingAction />;
      case RecordingState.open:
        return <RecordingsOpenAction />;
      default:
        return false;
    }
  }

  private static getGpsId(machine?: IMachine): string | undefined {
    if (machine != undefined) {
      const gpsTracker = machine.gpsTracker;
      if (gpsTracker != undefined) {
        return gpsTracker.serialNumber;
      }
    }
    return;
  }
}

export const RecordingsTableBody = withTranslation()(
  withStyles(styles)(RecordingsTableBodyComponent),
);
