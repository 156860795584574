import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Routes } from "../../components/navigation/Routes";
import { SignInForm } from "../../components/sign-in/SignInForm";
import {
  ActionNames,
  IApplicationStoreContextConsumerProps,
  withApplicationStateStoreContext,
} from "../../infrastructure/application-state";
import { IAuthenticatedAction } from "../../infrastructure/application-state/actions";
import {
  IAuthenticationResponse,
  IAuthenticationService,
} from "../../infrastructure/authentication";
import { inject } from "../../infrastructure/injection";
import {
  NotificationCenter,
  NotificationTypes,
} from "../../infrastructure/notification";
import { TypeNames } from "../../infrastructure/TypeNames";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      height: "100vh",
      flexDirection: "row",
      backgroundColor: theme.palette.background.paper,
    },
  });

type IRootProps = WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps &
  IApplicationStoreContextConsumerProps;

class RootState {
  public email: string = "";
  public password: string = "";
  public emailError?: string;
  public passwordError?: string;
}

class RootComponent extends React.Component<IRootProps, RootState> {
  @inject(TypeNames.notificationCenter)
  private readonly _notificationCenter: NotificationCenter;
  @inject(TypeNames.authenticationService)
  private readonly _authenticationService: IAuthenticationService;
  public constructor(props: IRootProps) {
    super(props);
    this.state = new RootState();
  }

  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <SignInForm
          onSignInNowClick={() => this.props.history.push(Routes.signUp)}
          onRecoverPasswordClick={() =>
            this.props.history.push(Routes.requestPasswordRecovery)
          }
          onSignInClick={() => this.handleSignInClicked()}
          email={this.state.email}
          password={this.state.password}
          onPasswordChange={(password: string) =>
            this.handlePasswordChanged(password)
          }
          onEmailChange={(email: string) => this.handleEmailChanged(email)}
          emailError={this.state.emailError}
          passwordError={this.state.passwordError}
        />
      </div>
    );
  }

  private handleEmailChanged(email: string) {
    this.setState({ email });
  }

  private handlePasswordChanged(password: string) {
    this.setState({ password });
  }
  private async handleSignInClicked(): Promise<void> {
    const { t } = this.props;
    let emailError, passwordError;
    if (this.state.email.trim() === "") {
      emailError = t("labels.sign-in.errors.mail");
    }
    if (this.state.password.trim() === "") {
      passwordError = t("labels.sign-in.errors.password");
    }
    this.setState({ emailError, passwordError });
    if (emailError != undefined || passwordError != undefined) {
      return;
    }
    try {
      const response: IAuthenticationResponse = await this._authenticationService.authenticate(
        this.state.email,
        this.state.password,
      );
      const action: IAuthenticatedAction = {
        name: ActionNames.authenticatedAction,
        user: response.user,
        jwt: response.jwt,
      };
      this.props.store.dispatch<IAuthenticatedAction>(action);
      this.props.history.push(Routes.dashboard);
    } catch (e) {
      this._notificationCenter.sendNotification({
        notificationType: NotificationTypes.error,
        text: t("labels.sign-in.errors.failed"),
      });
    }
  }
}

export const Root = withApplicationStateStoreContext(
  withRouter(withTranslation()(withStyles(styles)(RootComponent))),
);
