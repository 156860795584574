import { withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { VictoryAxis, VictoryBar, VictoryChart } from "victory";
import { IBarData } from "../../infrastructure/bar-chart/IBarData";
import { StatisticTypes } from "../../infrastructure/machines";
import { EnumDropdown } from "../general/EnumDropdown";

interface IBarChartProps extends WithStyles<typeof styles>, WithTranslation {
  readonly data: IBarData[];
  readonly selectedMonths: string[];
  readonly selectedDataType: StatisticTypes;
  readonly selectedDataTypeChange: (dataType: StatisticTypes) => void;
}

const styles = {
  root: {
    flex: 1,
    display: "flex",
  },
  leftAxis: {
    tickLabels: {
      fontSize: 8,
    },
    axisLabel: {
      fontSize: 8,
    },
  },
  bottomAxis: {
    tickLabels: {
      fontSize: 7,
    },
  },
  bars: {
    data: {
      fill: "#63a5ff",
    },
  },
  domainPadding: {
    x: 15,
  },
};

class BarChartComponent extends React.Component<IBarChartProps> {
  public render(): React.ReactNode {
    const data = this.props.data.map((bar) => ({ x: bar.key, y: bar.value }));
    return (
      <>
        <EnumDropdown
          enumT={StatisticTypes}
          value={this.props.selectedDataType}
          onChange={(value) =>
            this.props.selectedDataTypeChange(value as StatisticTypes)
          }
          translationsPath={"actions.machines-administration.statistic-types"}
        />
        <VictoryChart domainPadding={styles.domainPadding} padding={50}>
          <VictoryAxis
            style={styles.bottomAxis}
            name={this.props.t(
              "actions.machines-administration.statistic-types" +
                this.props.selectedDataType,
            )}
          />
          <VictoryAxis
            dependentAxis
            domain={[0, 20]}
            label={
              this.props.selectedDataType == StatisticTypes.operatingHours
                ? this.props.t(
                    "labels.machines-administration.statistics.operating-hours-value",
                  )
                : this.props.t(
                    "labels.machines-administration.statistics.field-area-total-value",
                  )
            }
            orientation={"left"}
            style={styles.leftAxis}
          />
          <VictoryBar
            style={styles.bars}
            categories={{ x: this.props.selectedMonths }}
            data={data}
          />
        </VictoryChart>
      </>
    );
  }
}

export const BarChart = withTranslation()(
  withStyles(styles)(BarChartComponent),
);
