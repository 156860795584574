import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Routes } from "../../components/navigation/Routes";
import { RequestPasswordRecoveryForm } from "../../components/request-password-recovery/RequestPasswordRecoveryForm";
import { IAuthenticationService } from "../../infrastructure/authentication";
import { inject } from "../../infrastructure/injection";
import {
  NotificationCenter,
  NotificationTypes,
} from "../../infrastructure/notification";
import { TypeNames } from "../../infrastructure/TypeNames";

const styles = () =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      height: "100vh",
      flexDirection: "column",
      justifyContent: "center",
    },
  });

type IRootProps = WithStyles<typeof styles> &
  RouteComponentProps &
  WithTranslation;

class RootState {
  public email: string = "";
  public emailError?: string;
}

class RootComponent extends React.Component<IRootProps, RootState> {
  @inject(TypeNames.notificationCenter)
  private readonly _notificationCenter: NotificationCenter;
  @inject(TypeNames.authenticationService)
  private readonly _authenticationService: IAuthenticationService;
  public constructor(props: IRootProps) {
    super(props);
    this.state = new RootState();
  }

  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <RequestPasswordRecoveryForm
          email={this.state.email}
          onEmailChange={(email: string) => this.handleEmailChanged(email)}
          onRequestRecoveryClick={() => this.handleRequestRecoveryClicked()}
          onBackButtonClick={() => this.props.history.push(Routes.signIn)}
          emailError={this.state.emailError}
        />
      </div>
    );
  }
  private handleEmailChanged(email: string): void {
    this.setState({ email });
  }
  private async handleRequestRecoveryClicked(): Promise<void> {
    const { t } = this.props;
    let emailError;
    if (this.state.email.trim() === "") {
      emailError = t("labels.request-password-recovery.errors.mail");
    }
    this.setState({ emailError });
    if (emailError != undefined) {
      return;
    }
    try {
      await this._authenticationService.recoverPassword(this.state.email);
      this._notificationCenter.sendNotification({
        notificationType: NotificationTypes.success,
        text: t("labels.request-password-recovery.success"),
      });
      this.props.history.goBack();
    } catch (e) {
      this._notificationCenter.sendNotification({
        notificationType: NotificationTypes.error,
        text: t("labels.request-password-recovery.errors.failed"),
      });
    }
  }
}

export const Root = withRouter(
  withTranslation()(withStyles(styles)(RootComponent)),
);
