import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Search } from "../general/Search";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(3),
    },
    tableHead: {
      flex: 1,
      display: "flex",
    },
    tableRow: {
      flex: 1,
      display: "flex",
    },
    actionContainer: {
      flex: 7,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    select: {
      display: "flex",
      width: "100%",
    },
    formControl: {
      flex: 1,
      display: "flex",
    },
    showAllButtonContainer: {
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      textAlign: "center",
    },
    showAllButton: {
      textTransform: "none",
    },
    closeIcon: {
      display: "flex",
      fontSize: 12,
      opacity: 0.5,
    },
    selectedValueContainer: {
      display: "flex",
      flexDirection: "row",
    },
    value: {
      display: "flex",
      textAlign: "center",
    },
    valueContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    allValueContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingLeft: 5,
      paddingTop: 5,
    },
    allValueText: { alignSelf: "center" },
  });

export interface ITableActionHeaderProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly selectedMachineType: string;
  readonly machineTypes: string[];
  readonly search: string;
  readonly onMachineChanged: (value: string) => void;
  readonly onSearchChanged: (value: string) => void;
  readonly onShowAllButtonClick: () => void;
  readonly onOpen: () => void;
  readonly onClose: () => void;
  readonly isExpanded: boolean;
  readonly isOpen: boolean;
  readonly isSelected: boolean;
  readonly onSearchClick: () => void;
  readonly showAllValues: boolean;
  readonly onShowAllValuesChanged: () => void;
}

class TableActionHeaderComponent extends React.Component<
  ITableActionHeaderProps
> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>{t("labels.machines.machine-type")}</InputLabel>
          <Select
            onClose={() => {
              this.props.onClose();
            }}
            renderValue={(selectedValue: unknown) =>
              this.renderSelectValue(selectedValue as string)
            }
            onOpen={() => this.props.onOpen()}
            label={t("labels.machines.machine-type")}
            color={"primary"}
            variant={"outlined"}
            open={this.props.isOpen}
            value={this.props.selectedMachineType}
            onChange={(event) => {
              const value = event.target.value as string;
              if (value === "select-show") {
                event.stopPropagation();
                this.props.onShowAllButtonClick();
              } else {
                this.props.onMachineChanged(value);
              }
            }}>
            <MenuItem value={""}>{t("labels.recordings.select.none")}</MenuItem>
            {this.renderMachineTypes()}
            <MenuItem
              key={"select-something-button"}
              color={"primary"}
              onClick={() => this.props.onShowAllButtonClick()}
              className={classes.showAllButton}
              value={"select-show"}>
              {this.props.isExpanded ? (
                <Typography variant={"h6"}>
                  {t("labels.recordings.select.show-less")}
                </Typography>
              ) : (
                <Typography variant={"h6"}>
                  {t("labels.recordings.select.show-all")}
                </Typography>
              )}
            </MenuItem>
          </Select>
        </FormControl>
        <div className={classes.actionContainer}>
          <div>
            <Search
              value={this.props.search}
              onChange={(value) => this.props.onSearchChanged(value)}
              placeholder={t("labels.recordings.search-placeholder")}
              onSearchClick={() => this.props.onSearchClick()}
            />
            <div className={classes.allValueContainer}>
              <Typography className={classes.allValueText}>
                {t("labels.recordings.table-headers.all-values")}
              </Typography>
              <Switch
                color={"primary"}
                checked={this.props.showAllValues}
                onChange={() => this.props.onShowAllValuesChanged()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  private renderMachineTypes(): React.ReactNode {
    const { t } = this.props;
    return this.props.machineTypes.map((x, index) => {
      if (!this.props.isExpanded && index > 4) {
        return undefined;
      }
      return (
        <MenuItem key={index} color={"primary"} value={x}>
          {t(`labels.machine-types.${x}`)}
        </MenuItem>
      );
    });
  }

  private renderSelectValue(selectedValue: string): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <Typography className={classes.value}>
        {t(`labels.machine-types.${selectedValue}`)}
      </Typography>
    );
  }
}

export const TableActionHeader = withTranslation()(
  withStyles(styles)(TableActionHeaderComponent),
);
