import { injectable } from "inversify";
import { inject } from "../injection";
import { TypeNames } from "../TypeNames";
import { IMachine } from "./IMachine";
import { IMachineCategory } from "./IMachineCategory";
import { IMachinesFactory } from "./IMachinesFactory";
import { IMachinesRepository } from "./IMachinesRepository";
import { IMachinesService } from "./IMachinesService";
import { INewMachine } from "./INewMachine";
import { IStatistic } from "./IStatistic";

@injectable()
export class MachinesService implements IMachinesService {
  @inject(TypeNames.machinesRepository)
  private readonly _machinesRepository: IMachinesRepository;
  @inject(TypeNames.machinesFactory)
  private readonly _machinesFactory: IMachinesFactory;

  public async getMachine(jwt: string, machineId: number): Promise<IMachine> {
    return this._machinesRepository.getMachine(jwt, machineId);
  }

  public async getMachineCategories(jwt: string): Promise<IMachineCategory[]> {
    return this._machinesRepository.getMachineCategories(jwt);
  }

  public async getMachinesPages(jwt: string, search: string): Promise<number> {
    const machineCount = await this._machinesRepository.getMachinesCount(
      jwt,
      search,
    );
    return Math.ceil(machineCount / 100);
  }

  public async getMachinesCount(jwt: string, search: string): Promise<number> {
    return this._machinesRepository.getMachinesCount(jwt, search);
  }

  public async getMachines(
    jwt: string,
    currentPage: number,
    search: string,
    limit?: number,
  ): Promise<IMachine[]> {
    return this._machinesRepository.getMachines(
      jwt,
      currentPage,
      search,
      limit,
    );
  }

  public async getStatistics(
    jwt: string,
    machineId: number,
    month: string,
  ): Promise<IStatistic> {
    const statistics = await this._machinesRepository.getStatistics(
      jwt,
      machineId,
      month,
    );
    return this._machinesFactory.statistics(statistics, month);
  }

  public async postMachine(jwt: string, machine: INewMachine): Promise<void> {
    await this._machinesRepository.postMachine(jwt, machine);
  }

  public async updateMachine(machine: IMachine, jwt?: string): Promise<void> {
    if (jwt != undefined) {
      await this._machinesRepository.updateMachine(jwt, machine);
    } else {
      throw new Error("Not authorized");
    }
  }

  public async getMachineTypes(jwt: string): Promise<string[]> {
    return this._machinesRepository.getMachineTypes(jwt);
  }
  public async deleteMachine(machineId: number, jwt: string): Promise<void> {
    await this._machinesRepository.deleteMachine(machineId, jwt);
  }
}
