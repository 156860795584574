import {
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  FormHelperText,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require("../../resources/images/logo.jpg");

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      height: "100vh",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: theme.palette.background.paper,
    },
    container: {
      display: "flex",
      width: "30%",
      height: "100%",
      alignSelf: "center",
      flexDirection: "column",
      padding: theme.spacing(2),
    },
    componentContainer: {
      padding: theme.spacing(5, 2),
    },
    logoContainer: {
      display: "flex",
      height: "12%",
      width: "70%",
      alignSelf: "center",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: theme.spacing(10),
    },
    logo: {
      resizeMode: "contain",
      height: "100%",
      width: "100%",
    },
    signInTextContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    fadeText: {
      textAlign: "center",
      fontSize: 15,
      fontWeight: 500,
      opacity: 0.6,
    },
    signInText: {
      textAlign: "center",
      fontSize: 15,
      fontWeight: 600,
      opacity: 0.7,
    },
  });

export interface ISignUpFormProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly userName: string;
  readonly email: string;
  readonly password: string;
  readonly passwordAssurance: string;
  readonly checkedTermsAndService: boolean;
  readonly onUsernameChange: (userName: string) => void;
  readonly onEmailChange: (email: string) => void;
  readonly onCheckboxChange: () => void;
  readonly onSignUpClick: () => void;
  readonly onBackClick: () => void;
  readonly onPasswordChange: (password: string) => void;
  readonly onPasswordAssuranceChange: (passwordAssurance: string) => void;
  readonly userNameError?: string;
  readonly mailError?: string;
  readonly passwordError?: string;
  readonly passwordAssuranceError?: string;
  readonly termsAndConditionsError?: string;
}

export class SignUpFormComponent extends React.PureComponent<ISignUpFormProps> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={logo} />
            <Typography variant={"h4"}>{t("labels.sign-up.title")}</Typography>
          </div>
          <div className={classes.componentContainer}>
            <TextField
              color={"secondary"}
              variant={"outlined"}
              required
              fullWidth
              label={t("labels.sign-up.username")}
              value={this.props.userName}
              onChange={(event) =>
                this.props.onUsernameChange(event.target.value)
              }
              error={this.props.userNameError != undefined}
              helperText={this.props.userNameError}
            />
          </div>
          <div className={classes.componentContainer}>
            <TextField
              color={"secondary"}
              variant={"outlined"}
              required
              fullWidth
              type={"email"}
              label={t("labels.sign-up.mail")}
              value={this.props.email}
              onChange={(event) => this.props.onEmailChange(event.target.value)}
              error={this.props.mailError != undefined}
              helperText={this.props.mailError}
            />
          </div>
          <div className={classes.componentContainer}>
            <TextField
              color={"secondary"}
              variant={"outlined"}
              required
              fullWidth
              type={"password"}
              label={t("labels.sign-up.password")}
              value={this.props.password}
              onChange={(event) =>
                this.props.onPasswordChange(event.target.value)
              }
              error={this.props.passwordError != undefined}
              helperText={this.props.passwordError}
            />
          </div>
          <div className={classes.componentContainer}>
            <TextField
              color={"secondary"}
              variant={"outlined"}
              required
              fullWidth
              type={"password"}
              label={t("labels.sign-up.password-assurance")}
              value={this.props.passwordAssurance}
              onChange={(event) =>
                this.props.onPasswordAssuranceChange(event.target.value)
              }
              error={this.props.passwordAssuranceError != undefined}
              helperText={this.props.passwordAssuranceError}
            />
          </div>
          {/* TODO: insert reCAPTCHA */}
          <div className={classes.componentContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  required
                  checked={this.props.checkedTermsAndService}
                  onChange={() => this.props.onCheckboxChange()}
                />
              }
              label={t("labels.sign-up.terms-and-conditions")}
              labelPlacement={"end"}
            />
            <FormHelperText
              error={this.props.termsAndConditionsError != undefined}>
              {this.props.termsAndConditionsError}
            </FormHelperText>
          </div>
          <div className={classes.componentContainer}>
            <Button
              fullWidth
              color={"primary"}
              variant={"contained"}
              onClick={() => this.props.onSignUpClick()}>
              {t("actions.sign-up.register")}
            </Button>
          </div>
          <div className={classes.signInTextContainer}>
            <Typography className={classes.fadeText}>
              {t("labels.sign-up.has-account")}
            </Typography>
            <Button
              className={classes.signInText}
              variant={"text"}
              onClick={() => this.props.onBackClick()}>
              {t("actions.sign-up.click-here")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export const SignUpForm = withTranslation()(
  withStyles(styles)(SignUpFormComponent),
);
