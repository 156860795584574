import axios from "axios";
import { injectable } from "inversify";
import { ConfigService } from "../config-service";
import { IAlert } from "./IAlert";
import { IAlertsService } from "./IAlertsService";

@injectable()
export class AlertsService implements IAlertsService {
  public async getAlerts(jwt: string): Promise<IAlert[]> {
    const url = `${ConfigService.get((config) => config.baseUrl)}/alerts`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: IAlert[] = response.data.map((alert: any) => {
      const res: IAlert = {
        id: alert.id,
        date: alert.date,
        type: alert.type,
        machineId: alert.machine.id,
        machineName: alert.machine.name,
        machineType: alert.machine.machine_type.typeName,
        maxVoltage: alert.machine.gps_tracker.maxVoltage,
        currentVoltage: alert.machine.gps_tracker.voltage,
      };
      return res;
    });
    return data;
  }

  public async getCount(jwt: string, userId: string): Promise<number> {
    const url = `${ConfigService.get(
      (config) => config.baseUrl,
    )}/alerts/count?machine.user=${userId}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
  }

  public async deleteAlert(jwt: string, id: number): Promise<void> {
    const url = `${ConfigService.get((config) => config.baseUrl)}/alerts/${id}`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
    };
    await axios.delete(url, { headers });
  }
}
