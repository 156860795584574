import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  IApplicationStoreContextConsumerProps,
  withApplicationStateStoreContext,
} from "../../infrastructure/application-state";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require("../../resources/images/logo.jpg");
export type ISidebarHeaderProps = WithStyles &
  WithTranslation &
  IApplicationStoreContextConsumerProps;

const styles = () =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    logo: {
      resizeMode: "contain",
      height: "100%",
      width: "100%",
    },
  });

class SidebarHeaderComponent extends React.PureComponent<ISidebarHeaderProps> {
  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <img className={classes.logo} src={logo} />
      </div>
    );
  }
}
export const SidebarHeader = withApplicationStateStoreContext(
  withTranslation()(withStyles(styles)(SidebarHeaderComponent)),
);
