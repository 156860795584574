import {
  Card,
  createStyles,
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IMachineCategory } from "../../infrastructure/machines";
import { IRecording } from "../../infrastructure/recordings";
import { LoadingIndicator } from "../general/LoadingIndicator";
import { TableActionHeader } from "./RecordingsTableActionHeader";
import { RecordingsTableBody } from "./RecordingsTableBody";
import { RecordingsTableHead } from "./RecordingsTableHead";

const styles = () =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
    },
    tableContainer: {
      flex: 1,
      display: "flex",
    },
    resizedTableContainer: {
      height: "50vh",
    },
    editIcon: {
      display: "flex",
      justifyContent: "center",
    },
    deleteIcon: {
      display: "flex",
      justifyContent: "center",
    },
    loadingIndicatorContainer: {
      flex: 1,
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
  });

export interface IRecordingsTableProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly data: IRecording[];
  readonly onSearchChange: (value: string) => void;
  readonly onMachineChange: (value: string) => void;
  readonly isRecordingsActionMenuOpen: boolean;
  readonly recordingsMenuAnchor: HTMLElement | undefined;
  readonly onActionClose: () => void;
  readonly onDetailedClick: () => void;
  readonly onDeleteClick: () => void;
  readonly onActionOpen: (recordingId: number) => void;
  readonly onSelect: (recordingId: number, checked: boolean) => void;
  readonly onCheck: (recordingId: number, checked: boolean) => void;
  readonly onSortingClick: (sortingKey: string) => void;
  readonly onMachineCategoryDialogClicked: (isDialogOpen: boolean) => void;
  readonly onShowAllButtonClick: () => void;
  readonly selectedMachineType: string;
  readonly search: string;
  readonly categories: IMachineCategory[];
  readonly selectedDataId: number;
  readonly checkedDataIds: number[];
  readonly sortInverse: boolean;
  readonly sortingKey: string;
  readonly isDialogOpen: boolean;
  readonly isExpanded: boolean;
  readonly isLoading: boolean;
  readonly onSearchClick: () => void;
  readonly onAllClicked: () => void;
  readonly onShowAllValuesClicked: () => void;
  readonly showAllValues: boolean;
}

class RecordingsTableState {
  public isMenuOpen = false;
  public menuAnchor?: Element;
}

class RecordingsTableComponent extends React.PureComponent<
  IRecordingsTableProps,
  RecordingsTableState
> {
  public constructor(props: IRecordingsTableProps) {
    super(props);
    this.state = new RecordingsTableState();
  }

  public render(): React.ReactNode {
    const { t, classes } = this.props;
    if (this.props.isLoading) {
      return (
        <div className={classes.loadingIndicatorContainer}>
          <LoadingIndicator />
        </div>
      );
    }
    return (
      <Card className={classes.root}>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={10}>
                  <TableActionHeader
                    isSelected={false}
                    onClose={() =>
                      this.props.onMachineCategoryDialogClicked(false)
                    }
                    onOpen={() =>
                      this.props.onMachineCategoryDialogClicked(true)
                    }
                    isOpen={this.props.isDialogOpen}
                    onShowAllButtonClick={() => {
                      this.props.onShowAllButtonClick();
                    }}
                    isExpanded={this.props.isExpanded}
                    onMachineChanged={(value) =>
                      this.props.onMachineChange(value)
                    }
                    onSearchChanged={(value) =>
                      this.props.onSearchChange(value)
                    }
                    onSearchClick={() => this.props.onSearchClick()}
                    machineTypes={this.getMachineTypes()}
                    selectedMachineType={this.props.selectedMachineType}
                    search={this.props.search}
                    showAllValues={this.props.showAllValues}
                    onShowAllValuesChanged={() =>
                      this.props.onShowAllValuesClicked()
                    }
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <RecordingsTableHead
              sortingKey={this.props.sortingKey}
              sortInverse={this.props.sortInverse}
              onSortingClick={(sortingKey) =>
                this.props.onSortingClick(sortingKey)
              }
              recordings={this.props.data}
              onAllClicked={() => this.props.onAllClicked()}
              allChecked={
                this.props.checkedDataIds.length === this.props.data.length &&
                this.props.data.length > 0
              }
              onAllMenuClicked={(allMenuRef) =>
                this.handleOnAllMenuClicked(allMenuRef)
              }
            />
            <RecordingsTableBody
              data={this.props.data}
              selectedDataId={this.props.selectedDataId}
              checkedDataIds={this.props.checkedDataIds}
              onCheck={(recordingId, checked) =>
                this.props.onCheck(recordingId, checked)
              }
              onActionOpen={(event, recordingId) =>
                this.props.onActionOpen(recordingId)
              }
              onSelect={(recordingId, checked) =>
                this.props.onSelect(recordingId, checked)
              }
              sortingKey={this.props.sortingKey}
              sortInverse={this.props.sortInverse}
            />
          </Table>
        </TableContainer>
        <Menu
          keepMounted
          open={this.props.isRecordingsActionMenuOpen}
          anchorEl={this.props.recordingsMenuAnchor}
          onClose={() => this.props.onActionClose()}>
          <MenuItem onClick={() => this.props.onDetailedClick()}>
            {t("actions.recordings-actions.detailed")}
            <ListItemIcon className={classes.editIcon}>
              <Edit color={"action"} fontSize="small" />
            </ListItemIcon>
          </MenuItem>
        </Menu>
        <Menu
          keepMounted
          open={this.state.isMenuOpen}
          anchorEl={this.state.menuAnchor}
          onClose={() =>
            this.setState({ isMenuOpen: false, menuAnchor: undefined })
          }>
          <MenuItem
            onClick={() => {
              this.setState({ isMenuOpen: false, menuAnchor: undefined });
              this.props.onDeleteClick();
            }}>
            {t("actions.recordings.table-actions.delete") +
              " (" +
              this.props.checkedDataIds.length +
              ")"}
          </MenuItem>
        </Menu>
      </Card>
    );
  }

  private getMachineTypes(): string[] {
    if (this.props.categories.length > 0) {
      return this.props.categories
        .map((x) => x.machineTypes)
        .reduce((a, b) => a.concat(b));
    }
    return [];
  }

  private handleOnAllMenuClicked(allMenuRef: HTMLElement | null) {
    if (allMenuRef != null) {
      this.setState({ isMenuOpen: true, menuAnchor: allMenuRef });
    }
  }
}

export const RecordingsTable = withTranslation()(
  withStyles(styles)(RecordingsTableComponent),
);
