import {
  createStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IAlertsService } from "../../infrastructure/alerts";
import {
  IApplicationStoreContextConsumerProps,
  withApplicationStateStoreContext,
} from "../../infrastructure/application-state";
import { inject } from "../../infrastructure/injection";
import { ISidebarFactory } from "../../infrastructure/sidebar/ISidebarFactory";
import { ISidebarItems } from "../../infrastructure/sidebar/ISidebarItems";
import { TypeNames } from "../../infrastructure/TypeNames";
import { Routes } from "../navigation/Routes";
import { SidebarHeader } from "./SidebarHeader";

const styles = (theme: Theme) =>
  createStyles({
    alert: {
      color: theme.palette.error.main,
    },
    root: {
      display: "flex",
      width: "15%",
      height: "100%",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper,
    },
    drawerPaper: {
      width: "15%",
    },
    drawerContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    },
    drawerCategoryContainer: {
      display: "flex",
      flexDirection: "column",
    },
    footer: {
      flex: 1,
      display: "flex",
      width: "100%",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    listItem: {
      padding: theme.spacing(0, 3, 4, 3),
    },
  });

export interface ISidebarProps
  extends WithStyles<typeof styles>,
    IApplicationStoreContextConsumerProps,
    WithTranslation {
  readonly currentBaseRoute: Routes;
  readonly onRouteClick: (route: Routes) => void;
  readonly onLogoutClick: () => void;
}

class SidebarState {
  public items: ISidebarItems[] = [];
  public alarmCount: number = 0;
}

class SidebarComponent extends React.Component<ISidebarProps, SidebarState> {
  @inject(TypeNames.sidebarFactory)
  private readonly _sidebarFactory: ISidebarFactory;
  @inject(TypeNames.alertsService)
  private readonly _alertsService: IAlertsService;

  constructor(props: ISidebarProps) {
    super(props);
    this.state = new SidebarState();
  }
  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <Drawer
        variant={"permanent"}
        className={classes.root}
        classes={{ paper: classes.drawerPaper }}>
        <div className={classes.drawerContainer}>
          <div className={classes.drawerCategoryContainer}>
            <SidebarHeader />
          </div>
          <div className={classes.drawerCategoryContainer}>
            <List>{this.state.items.map((x) => this.renderListItem(x))}</List>
          </div>
          {this.renderFooter()}
        </div>
      </Drawer>
    );
  }

  public async componentDidMount(): Promise<void> {
    const items = this._sidebarFactory.getSidebarItems();
    let alarmCount = 0;
    if (
      this.props.store.state.jwt != undefined &&
      this.props.store.state.user != undefined
    ) {
      alarmCount = await this._alertsService.getCount(
        this.props.store.state.jwt,
        this.props.store.state.user.id,
      );
    }
    this.setState({ items, alarmCount });
  }

  private getColor(route: Routes): "initial" | "primary" {
    return route === this.props.currentBaseRoute ? "primary" : "initial";
  }

  private renderListItem(item: ISidebarItems): React.ReactNode {
    return (
      <ListItem
        key={item.id}
        classes={{ container: this.props.classes.listItem }}
        onClick={() => this.props.onRouteClick(item.route)}
        button>
        <ListItemIcon
          className={
            item.route === "/alerts" && this.state.alarmCount > 0
              ? this.props.classes.alert
              : ""
          }>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={this.props.t(item.translationKey)}
          primaryTypographyProps={{
            color: this.getColor(item.route),
          }}
        />
      </ListItem>
    );
  }

  private renderFooter(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.footer}>
        <List>
          <ListItem
            classes={{ container: classes.listItem }}
            onClick={() => this.props.onLogoutClick()}
            button>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText
              primary={t("actions.sidebar.logout")}
              primaryTypographyProps={{
                color: this.getColor(Routes.settings),
              }}
            />
          </ListItem>
        </List>
      </div>
    );
  }
}

export const Sidebar = withApplicationStateStoreContext(
  withTranslation()(withStyles(styles)(SidebarComponent)),
);
