import { ReducerBase } from "@econsor-mobile/flux";
import { ActionNames } from "./ActionNames";
import { IAuthenticatedAction } from "./actions";
import { IApplicationState } from "./IApplicationState";

export class ApplicationReducer extends ReducerBase<IApplicationState> {
  protected initialize(): void {
    this.registerHandler(
      ActionNames.authenticatedAction,
      this.handleAuthenticatedAction.bind(this),
    );
    this.registerHandler(
      ActionNames.unauthenticatedAction,
      this.handleUnauthenticatedAction.bind(this),
    );
  }

  private handleAuthenticatedAction(
    currentState: IApplicationState,
    action: IAuthenticatedAction,
  ): IApplicationState {
    window.localStorage.setItem("jwt", action.jwt);
    window.localStorage.setItem("user", JSON.stringify(action.user));
    return {
      ...currentState,
      user: action.user,
      jwt: action.jwt,
    };
  }

  private handleUnauthenticatedAction(
    currentState: IApplicationState,
  ): IApplicationState {
    return {
      ...currentState,
      user: undefined,
      jwt: undefined,
    };
  }
}
