import { createMuiTheme } from "@material-ui/core";
import { ThemeOptions } from "@material-ui/core";

const theme: ThemeOptions = {
  spacing: 5,
  palette: {
    primary: {
      main: "#9AB02D",
    },
    secondary: {
      main: "#1550e0",
    },
    background: {
      default: "#ECEFF1",
      paper: "#ffffff",
    },
    grey: {
      A100: "#e5e3de",
      A200: "#4e4e4e",
    },
    text: {
      primary: "#000000",
      secondary: "#7f7f7f",
      hint: "#ffffff",
    },
  },
  typography: {
    //Regular Text
    body1: {
      fontSize: 16,
      color: "#000000",
      opacity: 0.9,
    },
    //Regular Title
    body2: {
      fontSize: 20,
      color: "#000000",
      opacity: 0.9,
    },
    //Breadcrumb
    subtitle1: {
      fontSize: 20,
      color: "#000000",
      fontWeight: "lighter",
      fontStyle: "alpha",
      opacity: 0.4,
    },
    //Secondary Text
    subtitle2: {
      fontSize: 16,
      color: "#000000",
      fontWeight: "bold",
      fontStyle: "alpha",
      opacity: 0.6,
    },
    // Tertiary Title
    caption: {
      fontSize: 16,
      color: "#000000",
      fontStyle: "alpha",
      opacity: 0.4,
    },
    //Primary Icon
    h1: {
      color: "#000000",
      opacity: 0.6,
    },
    //Secondary Icon
    h2: {
      color: "#000000",
      fontStyle: "alpha",
      opacity: 0.4,
    },
    // Primary Title
    h3: {
      fontSize: 40,
      fontWeight: "bold",
      color: "#9AB02D",
    },
    h4: {
      fontSize: 24,
      color: "#9AB02D",
      fontWeight: 700,
      fontStyle: "alpha",
      opacity: 0.6,
    },
    h5: {
      fontSize: 16,
      color: "#9AB02D",
      fontWeight: "bold",
      fontStyle: "alpha",
      opacity: 0.6,
    },
    h6: {
      fontSize: 14,
      color: "#468eff",
      fontWeight: "bold",
      fontStyle: "alpha",
      opacity: 0.6,
    },
  },
};
export const Theme = createMuiTheme(theme);
