import {
  createStyles,
  Divider,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IRecordingsInfo } from "../../infrastructure/recordings";
import { RecordingsShareOverview } from "./RecordingsShareOverview";

const styles = () =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    rowSpaced: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    shareContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      fontSize: 12,
    },
    divider: {
      display: "flex",
      flex: 1,
    },
  });

export interface IRecordingsInfoProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly data?: IRecordingsInfo;
  readonly onShareClick: () => void;
  readonly onShareLinkClick: () => void;
}

class RecordingsInfoComponent extends React.PureComponent<
  IRecordingsInfoProps
> {
  public render(): React.ReactNode {
    if (this.props.data == undefined) {
      return false;
    }
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.row}>
          <Divider
            className={classes.divider}
            orientation={"horizontal"}
            variant={"middle"}
          />
          <Typography className={classes.text} variant={"body1"}>
            {t("labels.recordings.info.title")}
          </Typography>
          <Divider
            className={classes.divider}
            orientation={"horizontal"}
            variant={"middle"}
          />
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.text} variant={"body1"}>
            {t("labels.recordings.info.machine-type")}
          </Typography>
          <Typography className={classes.text} variant={"body1"}>
            {t(
              `labels.machine-categories.${this.props.data.machineCategories}`,
            )}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.text} variant={"body1"}>
            {t("labels.recordings.info.name")}
          </Typography>
          <Typography className={classes.text} variant={"body1"}>
            {this.props.data.name}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.text} variant={"body1"}>
            {t("labels.recordings.info.recording-begin")}
          </Typography>
          <Typography className={classes.text} variant={"body1"}>
            {this.props.data.recordingBegin.format("HH:mm")}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.text} variant={"body1"}>
            {t("labels.recordings.info.recording-end")}
          </Typography>
          <Typography className={classes.text} variant={"body1"}>
            {this.props.data.recordingEnd.format("HH:mm")}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.text} variant={"body1"}>
            {t("labels.recordings.info.duration")}
          </Typography>
          <Typography className={classes.text} variant={"body1"}>
            {t("labels.recordings.info.duration-value", {
              hours: this.props.data.duration.hours(),
              minutes: this.props.data.duration.minutes(),
            })}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.text} variant={"body1"}>
            {t("labels.recordings.info.area-processed")}
          </Typography>
          <Typography className={classes.text} variant={"body1"}>
            {t("labels.recordings.info.area-processed-value", {
              value: this.props.data.areaProcessed,
            })}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.text} variant={"body1"}>
            {t("labels.recordings.info.area-total")}
          </Typography>
          <Typography className={classes.text} variant={"body1"}>
            {t("labels.recordings.info.area-total-value", {
              value: this.props.data.areaTotal,
            })}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.text} variant={"body1"}>
            {t("labels.recordings.info.fields")}
          </Typography>
          <Typography className={classes.text} variant={"body1"}>
            {this.props.data.fields}
          </Typography>
        </div>
        <div className={classes.row}>
          <Divider
            className={classes.divider}
            orientation={"horizontal"}
            variant={"middle"}
          />
          <Typography className={classes.text} variant={"body1"}>
            {t("labels.recordings.info.share")}
          </Typography>
          <Divider
            className={classes.divider}
            orientation={"horizontal"}
            variant={"middle"}
          />
        </div>
        <div className={classes.shareContainer}>
          <RecordingsShareOverview
            onShareClick={() => this.props.onShareClick()}
            onShareLinkClick={() => this.props.onShareLinkClick()}
          />
        </div>
      </div>
    );
  }
}

export const RecordingsInfo = withTranslation()(
  withStyles(styles)(RecordingsInfoComponent),
);
