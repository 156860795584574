import { interfaces } from "inversify";

export class InjectionContainerLocator {
  public static setContainer(container: interfaces.Container): void {
    this._instance = container;
  }

  public static get(): interfaces.Container {
    return this._instance;
  }

  private static _instance: interfaces.Container;
}
