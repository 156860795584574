import { IconButton, Paper, Theme, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { IRecordingField } from "../../infrastructure/recordings";
import { GoogleMap } from "../maps";
import { MapType } from "../maps/MapType";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(3),
      borderRadius: theme.spacing(1),
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    smallMapContainer: {
      width: "100%",
      height: "100%",
      padding: theme.spacing(0, 5),
    },
  });

export interface IMiniMapProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly fieldIndex: number;
  readonly fields: IRecordingField[];
  readonly zoom: number;
  readonly onPreviousFieldClick: () => void;
  readonly onNextFieldClick: () => void;
  readonly onGoogleApiLoaded: (map: MapType, maps: MapType) => void;
}

class MiniMapComponent extends React.PureComponent<IMiniMapProps> {
  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <Paper elevation={1} className={classes.root}>
        <div className={classes.row}>
          <IconButton
            disabled={this.props.fieldIndex === 0}
            onClick={() => this.props.onPreviousFieldClick()}>
            <ArrowLeft />
          </IconButton>
          <Typography>{this.props.fieldIndex + 1}</Typography>
          <IconButton
            disabled={this.props.fieldIndex === this.props.fields.length - 1}
            onClick={() => this.props.onNextFieldClick()}>
            <ArrowRight />
          </IconButton>
        </div>
        <div className={classes.smallMapContainer}>
          <GoogleMap
            isAreaMap
            mapCenter={this.props.fields[this.props.fieldIndex].centerPoint}
            zoom={this.props.zoom}
            onGoogleApiLoaded={(map, maps) =>
              this.props.onGoogleApiLoaded(map, maps)
            }
          />
        </div>
      </Paper>
    );
  }
}
export const MiniMap = withTranslation()(withStyles(styles)(MiniMapComponent));
