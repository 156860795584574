import * as developmentConfig from "../../resources/config/development.json";
import * as productionConfig from "../../resources/config/production.json";
import { IConfig } from "./IConfig";

export class ConfigProvider {
  public static provide(): IConfig {
    if (
      process.env.NODE_ENV != undefined &&
      process.env.NODE_ENV === "production"
    ) {
      return productionConfig;
    }
    return developmentConfig;
  }
}
