import {
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      border: `1px solid #c8c800`,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(0, 1),
      alignItems: "center",
    },
    text: {
      color: "#c8c800",
    },
  });

export type IRecordingsOpenActionProps = WithStyles<typeof styles> &
  WithTranslation;

class RecordingsOpenActionComponent extends React.PureComponent<
  IRecordingsOpenActionProps
> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.text}>
          {t("labels.recordings.actions.open")}
        </Typography>
      </div>
    );
  }
}

export const RecordingsOpenAction = withTranslation()(
  withStyles(styles)(RecordingsOpenActionComponent),
);
