import { Card, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

const styles = () =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  });

export interface IInfoTextBoxProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly name: string;
  readonly value: string;
}

class InfoTextBoxComponent extends React.PureComponent<IInfoTextBoxProps> {
  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <Typography variant={"body1"}>{this.props.value}</Typography>
        <Typography variant={"caption"}>{this.props.name}</Typography>
      </Card>
    );
  }
}
export const InfoTextBox = withTranslation()(
  withStyles(styles)(InfoTextBoxComponent),
);
