import Error from "@material-ui/icons/Error";
import History from "@material-ui/icons/History";
import Router from "@material-ui/icons/Router";
import Send from "@material-ui/icons/Send";
import SystemUpdateAlt from "@material-ui/icons/SystemUpdateAlt";
import { injectable } from "inversify";
import React from "react";
import { Routes } from "../../components/navigation/Routes";
import { ISidebarFactory } from "./ISidebarFactory";
import { ISidebarItems } from "./ISidebarItems";

@injectable()
export class SidebarFactory implements ISidebarFactory {
  public getSidebarItems(): ISidebarItems[] {
    return [
      {
        id: "1",
        translationKey: "actions.sidebar.dashboard",
        icon: <Send />,
        route: Routes.dashboard,
      },
      {
        id: "2",
        translationKey: "actions.sidebar.recordings",
        icon: <SystemUpdateAlt />,
        route: Routes.recordings,
      },
      {
        id: "3",
        translationKey: "actions.sidebar.machines",
        icon: <Router />,
        route: Routes.machines,
      },
      {
        id: "4",
        translationKey: "actions.sidebar.history",
        icon: <History />,
        route: Routes.history,
      },
      {
        id: "5",
        translationKey: "actions.sidebar.alerts",
        icon: <Error />,
        route: Routes.alerts,
      },
      /*{
        id: "6",
        translationKey: "actions.sidebar.miscellaneous",
        icon: <Flag />,
        route: Routes.miscellaneous,
      },
      */
    ];
  }
}
