/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  ActionNames,
  IApplicationStoreContextConsumerProps,
  withApplicationStateStoreContext,
} from "../../infrastructure/application-state";
import { Routes } from "../navigation/Routes";
import { Sidebar } from "../sidebar/Sidebar";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      height: "100vh",
      flexDirection: "column",
    },
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
    },
    mainView: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
      borderLeft: `1px solid ${theme.palette.background.default}`,
      borderTop: `1px solid ${theme.palette.background.default}`,
      backgroundColor: theme.palette.background.default,
    },
  });

export interface IBasePageProps
  extends WithStyles<typeof styles>,
    IApplicationStoreContextConsumerProps,
    RouteComponentProps {
  readonly currentBaseRoute: Routes;
  readonly onRouteClick: (newRoute: Routes) => void;
  readonly children: React.ReactNode;
}

class BasePageComponent extends React.Component<IBasePageProps> {
  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Sidebar
            // @ts-ignore
            onLogoutClick={() => this.handleLogoutClicked()}
            currentBaseRoute={this.props.currentBaseRoute}
            // @ts-ignore
            onRouteClick={(newRoute) => this.props.onRouteClick(newRoute)}
          />
          <div className={classes.mainView}>{this.props.children}</div>
        </div>
      </div>
    );
  }

  private handleLogoutClicked(): void {
    this.props.store.dispatch({ name: ActionNames.unauthenticatedAction });
    this.props.history.push(Routes.signIn);
  }
}

export const BasePage = withApplicationStateStoreContext(
  withStyles(styles)(withRouter(BasePageComponent)),
);
