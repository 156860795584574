import {
  Card,
  Checkbox,
  createStyles,
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import moment from "moment";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IAlert } from "../../infrastructure/alerts";
import { BatteryPercentageService } from "../../infrastructure/battery-percentage/BatteryPercentageService";
import { SortingButton } from "../general/SortingButton";
import BatteryChargingFull from "@material-ui/icons/BatteryChargingFull";

const styles = () =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
    },
    tableContainer: {
      height: "85vh",
    },
    editIcon: {
      display: "flex",
      justifyContent: "center",
    },
    deleteIcon: {
      display: "flex",
      justifyContent: "center",
    },
    idCell: {
      display: "flex",
      alignItems: "center",
    },
  });

export interface IAlertsTableProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly alerts: IAlert[];
  readonly selectedAlertIds: number[];
  readonly isAllMenuOpen: boolean;
  readonly onAllMenuClicked: (open: boolean) => void;
  readonly onSelectClick: (alert: number, checked: boolean) => void;
  readonly onSelectAllClick: () => void;
  readonly onDeleteClick: () => void;
}

class AlertsTableComponent extends React.Component<IAlertsTableProps> {
  private allMenuRef: null | HTMLElement = null;

  public constructor(props: IAlertsTableProps) {
    super(props);
  }

  public render(): React.ReactNode {
    const { t, classes } = this.props;

    return (
      <Card className={classes.root}>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div
                    ref={(ref) => (this.allMenuRef = ref)}
                    className={classes.idCell}>
                    <Checkbox
                      onClick={(event) => {
                        this.props.onSelectAllClick();
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                      checked={
                        this.props.alerts.length > 0 &&
                        this.props.selectedAlertIds.length ===
                          this.props.alerts.length
                      }
                    />
                    <SortingButton
                      sortingKey={"all"}
                      selectedSortingKey={"all"}
                      sortInverse={true}
                      onSortingClick={() => this.props.onAllMenuClicked(true)}
                    />
                  </div>
                </TableCell>
                <TableCell>
                  <Typography variant={"subtitle2"}>
                    {t("labels.alerts.machine-table.ids")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"subtitle2"}>
                    {t("labels.alerts.machine-table.machine-type")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"subtitle2"}>
                    {t("labels.alerts.machine-table.name")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"subtitle2"}>
                    {t("labels.alerts.machine-table.info")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"subtitle2"}>
                    {t("labels.alerts.machine-table.date")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <BatteryChargingFull color={"primary"} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.alerts.map((alert, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Checkbox
                      checked={
                        this.props.selectedAlertIds.find(
                          (id) => id === alert.id,
                        ) != undefined
                      }
                      onChange={(event, checked) =>
                        this.props.onSelectClick(alert.id, checked)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant={"body1"}>{alert.machineId}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={"body1"}>
                      {t(`labels.machine-types.${alert.machineType}`)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={"body1"}>
                      {alert.machineName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={"body1"}>
                      {t(`labels.alerts.info.${alert.type}`)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={"body1"}>
                      {moment(alert.date).format("DD.MM.YYYY")}
                    </Typography>
                    <Typography variant={"body1"}>
                      {`${moment(alert.date).format("HH:mm")} ${t(
                        "labels.recordings.time",
                      )}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={"body1"}>
                      {`${BatteryPercentageService.getBatteryPercentageFromValues(
                        alert.currentVoltage,
                        alert.maxVoltage,
                      )}%`}
                    </Typography>
                    <Typography variant={"body1"}>
                      {alert.currentVoltage} Volt
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Menu
            keepMounted
            open={this.props.isAllMenuOpen}
            onClose={() => this.props.onAllMenuClicked(false)}
            anchorEl={this.allMenuRef}>
            <MenuItem onClick={() => this.props.onDeleteClick()}>
              {t("actions.machine-actions.delete")}
              <ListItemIcon className={classes.deleteIcon}>
                <Delete color={"error"} fontSize="small" />
              </ListItemIcon>
            </MenuItem>
          </Menu>
        </TableContainer>
      </Card>
    );
  }
}

export const AlertsTable = withTranslation()(
  withStyles(styles)(AlertsTableComponent),
);
