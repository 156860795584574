import {
  createStyles,
  Icon,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import * as React from "react";

export interface IMarkerProps extends WithStyles<typeof styles> {
  readonly lat: number;
  readonly lng: number;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  readonly onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  readonly label?: string;
  readonly filled?: boolean;
}

const styles = () =>
  createStyles({
    root: {
      position: "absolute",
      transform: "translate(-50%, -50%)",
    },
    label: {
      position: "absolute",
      color: "rgb(1,20,87)",
      top: 2,
      right: 12,
      fontSize: 16,
      fontWeight: "bold",
    },
  });

export class MarkerComponent extends React.PureComponent<IMarkerProps> {
  public render(): React.ReactNode {
    const { classes } = this.props;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const ImageLogo = require("../../resources/images/locationImage.png");
    return this.props.filled ? (
      <div
        key={`${this.props.lat}-${this.props.lng}`}
        className={classes.root}
        onClick={(event) => this.props.onClick?.(event)}>
        <Typography align={"center"} className={classes.label}>
          {this.props.label}
        </Typography>
        <Icon fontSize={"small"}>
          <img height={34} src={ImageLogo} alt={"icon"} />
        </Icon>
      </div>
    ) : (
      <div
        key={`${this.props.lat}-${this.props.lng}`}
        className={classes.root}
        onClick={(event) => this.props.onClick?.(event)}>
        <Typography align={"center"} className={classes.label}>
          {this.props.label}
        </Typography>
        <RoomIcon color={"error"} fontSize={"large"} />
      </div>
    );
  }
}

export const Marker = withStyles(styles)(MarkerComponent);
