import {
  createStyles,
  ListItemIcon,
  Menu,
  MenuItem,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { IMachine } from "../../infrastructure/machines";
import { Routes } from "../navigation/Routes";

const styles = (theme: Theme) =>
  createStyles({
    titleContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(0, 3),
    },
    id: {
      flex: 1,
      display: "flex",
      textAlign: "center",
      justifyContent: "flex-end",
    },
    editIcon: {
      display: "flex",
      justifyContent: "center",
    },
    title: {
      alignText: "center",
      paddingBottom: theme.spacing(2),
    },
    text: {
      alignText: "center",
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
    },
    rowSpaced: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  });

export interface IGoogleMapPinCardProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly onNavigate?: (route: Routes, machineId: number) => void;
  readonly onMarkerMenuClosed: () => void;
  readonly isMarkerMenuOpen: boolean;
  readonly machine?: IMachine;
  readonly markerMenuAnchor?: HTMLElement;
}

class GoogleMapPinCardState {
  public machineId?: number;
}

class GoogleMapPinCardComponent extends React.Component<
  IGoogleMapPinCardProps,
  GoogleMapPinCardState
> {
  public constructor(props: IGoogleMapPinCardProps) {
    super(props);
    this.state = new GoogleMapPinCardState();
  }
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <Menu
        keepMounted
        open={this.props.isMarkerMenuOpen}
        anchorEl={this.props.markerMenuAnchor}
        onClose={() => this.props.onMarkerMenuClosed()}>
        <div className={classes.titleContainer}>
          <div className={classes.rowSpaced}>
            <Typography className={classes.title} variant={"subtitle2"}>
              {t("labels.machines-administration.profile.name")}
            </Typography>
            <Typography className={classes.text} variant={"h4"}>
              {this.props.machine?.name}
            </Typography>
          </div>
          <div className={classes.rowSpaced}>
            <Typography className={classes.title} variant={"subtitle2"}>
              {t("labels.machines-administration.profile.stock-number")}
            </Typography>
            <Typography className={classes.text} variant={"h4"}>
              {this.props.machine?.stockNumber}
            </Typography>
          </div>
          <div className={classes.rowSpaced}>
            <Typography className={classes.title} variant={"subtitle2"}>
              {t("labels.machines-administration.profile.device-id")}
            </Typography>
            <Typography className={classes.text} variant={"h4"}>
              {this.props.machine?.gpsTracker?.serialNumber}
            </Typography>
          </div>
        </div>
        <MenuItem
          onClick={() =>
            this.props.onNavigate?.(
              Routes.machinesAdministration,
              this.props.machine?.id as number,
            )
          }>
          {t("actions.dashboard.administration")}
          <ListItemIcon className={classes.editIcon}>
            <Edit color={"action"} fontSize="small" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          onClick={() =>
            this.props.onNavigate?.(
              Routes.history,
              this.props.machine?.id as number,
            )
          }>
          {t("actions.dashboard.history")}
          <ListItemIcon className={classes.editIcon}>
            <HistoryIcon color={"action"} fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    );
  }
}

export const GoogleMapPinCard = withTranslation()(
  withStyles(styles)(GoogleMapPinCardComponent),
);
