// eslint-disable-next-line import/no-extraneous-dependencies
import * as history from "history";
import * as React from "react";
import { Route, Router } from "react-router-dom";
import * as Alerts from "../../use-cases/alerts";
import * as Dashboard from "../../use-cases/dashboard";
import * as History from "../../use-cases/history";
import * as Machines from "../../use-cases/machines";
import * as MachinesAdministration from "../../use-cases/machines-administration";
import * as PasswordRecovery from "../../use-cases/password-recovery";
import * as Recordings from "../../use-cases/recordings";
import * as RecordingsDetailed from "../../use-cases/recordings-detailed";
import * as RequestPasswordRecovery from "../../use-cases/request-password-recovery";
import * as SignIn from "../../use-cases/sign-in";
import * as SignUp from "../../use-cases/sign-up";
import { PrivateRoute } from "./PrivateRoute";
import { Routes } from "./Routes";

export const RootNavigation: React.FunctionComponent = () => {
  return (
    <Router history={history.createBrowserHistory()}>
      <Route exact path={Routes.signIn} component={SignIn.Root} />
      <Route exact path={Routes.signUp} component={SignUp.Root} />
      <Route
        exact
        path={Routes.requestPasswordRecovery}
        component={RequestPasswordRecovery.Root}
      />
      <Route
        exact
        path={Routes.passwordRecovery}
        component={PasswordRecovery.Root}
      />
      <PrivateRoute exact path={Routes.dashboard} component={Dashboard.Root} />
      <PrivateRoute
        exact
        path={Routes.recordings}
        component={Recordings.Root}
      />
      <PrivateRoute
        exact
        path={Routes.recordingsDetailed}
        component={RecordingsDetailed.Root}
      />
      <PrivateRoute exact path={Routes.machines} component={Machines.Root} />
      <PrivateRoute
        exact
        path={Routes.machinesAdministration}
        component={MachinesAdministration.Root}
      />
      <PrivateRoute exact path={Routes.alerts} component={Alerts.Root} />
      <PrivateRoute exact path={Routes.history} component={History.Root} />
    </Router>
  );
};
