/* eslint-disable jest/require-hook */
import "reflect-metadata";
import {
  ConsoleSink,
  LoggerConfiguration,
  LogLevel,
} from "@econsor-mobile/logging";
import { Bootstrapper } from "./infrastructure/bootstrapper/Bootstrapper";
import translationEn from "./resources/localization/enUS.json";
import translationDe from "./resources/localization/deDE.json";

document.addEventListener("DOMContentLoaded", async () => {
  const bootstrapperLogger = LoggerConfiguration.create()
    .addSink(ConsoleSink.create())
    .setLogLevel(LogLevel.Debug)
    .createLogger("Bootstrapper");

  const application = await Bootstrapper.create(bootstrapperLogger)
    .useTranslation(translationDe)
    .useTranslation(translationEn)
    .bootstrap();

  const applicationRoot = document.querySelector("#app") as HTMLDivElement;
  try {
    application.start(applicationRoot);
  } catch (error: any) {
    bootstrapperLogger.error("", error);
  }
});
