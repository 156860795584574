import {
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IStatistic } from "../../infrastructure/machines/IStatistic";

interface IMachinesStatisticProps
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly statistics: IStatistic;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
    },
    rowSpaced: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    title: {
      paddingBottom: theme.spacing(3),
    },
  });

class MachinesStatisticComponent extends React.Component<
  IMachinesStatisticProps
> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant={"h4"}>
            {t("labels.machines-administration.statistics.title")}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.title} variant={"caption"}>
            {t("labels.machines-administration.statistics.operating-hours")}
          </Typography>
          <Typography variant={"subtitle2"}>
            {t(
              "labels.machines-administration.statistics.operating-hours-value",
              {
                hours: this.props.statistics.operatingHours,
              },
            )}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.title} variant={"caption"}>
            {t("labels.machines-administration.statistics.traveled-distance")}
          </Typography>
          <Typography variant={"subtitle2"}>
            {t(
              "labels.machines-administration.statistics.traveled-distance-value",
              {
                value: this.props.statistics.traveledDistance.toPrecision(3),
              },
            )}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.title} variant={"caption"}>
            {t("labels.machines-administration.statistics.area-processed")}
          </Typography>
          <Typography variant={"subtitle2"}>
            {t(
              "labels.machines-administration.statistics.area-processed-value",
              {
                value: this.props.statistics.areaProcessed,
              },
            )}
          </Typography>
        </div>
        <div className={classes.rowSpaced}>
          <Typography className={classes.title} variant={"caption"}>
            {t("labels.machines-administration.statistics.field-area-total")}
          </Typography>
          <Typography variant={"subtitle2"}>
            {t(
              "labels.machines-administration.statistics.field-area-total-value",
              {
                value: this.props.statistics.totalFieldArea,
              },
            )}
          </Typography>
        </div>
      </div>
    );
  }
}

export const MachinesStatistic = withTranslation()(
  withStyles(styles)(MachinesStatisticComponent),
);
