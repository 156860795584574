import {
  createStyles,
  MenuItem,
  Select,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

interface IArrayDropdownProps<T>
  extends WithStyles<typeof styles>,
    WithTranslation {
  readonly data: T[];
  readonly valueIndex: number;
  readonly onChange: (value: number) => void;
  readonly translationsKey?: string;
  readonly translationsPath: string;
}

const styles = () =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
    },
    input: {
      flex: 1,
      display: "flex",
    },
  });

class ArrayDropdownComponent<T> extends React.Component<
  IArrayDropdownProps<T>
> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <Select
          disableUnderline
          variant={"outlined"}
          color={"primary"}
          className={classes.input}
          value={this.props.valueIndex}
          onChange={(event) =>
            this.props.onChange(event.target.value as number)
          }>
          {this.props.data.map((entry, index) => {
            const key =
              this.props.translationsKey != undefined
                ? entry[this.props.translationsKey as keyof T]
                : entry;
            return (
              <MenuItem value={index} key={index}>
                {t(`${this.props.translationsPath}.${key}`)}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  }
}

export const ArrayDropdown = withTranslation()(
  withStyles(styles)(ArrayDropdownComponent),
);
