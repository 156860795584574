import { Button, Theme, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { LoadingIndicator } from "../general/LoadingIndicator";
import { DialogBox } from "./DialogBox";
import { DialogSlider } from "./DialogSlider";
import { IFieldsAreaInformation } from "./IFieldsAreaInformation";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
    },
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
    },
    column: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    row: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
    },
    rowSpaced: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
    },
    buttonContainer: {
      display: "flex",
      width: "100%",
      padding: theme.spacing(5, 0),
      justifyContent: "center",
    },
    slidersContainer: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: theme.spacing(3.5),
    },
    loadingIndicatorContainer: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: theme.spacing(3.5),
    },
    rightBoxContainer: {
      display: "flex",
      width: "100%",
      padding: theme.spacing(5, 0, 0, 5),
    },
    leftBoxContainer: {
      display: "flex",
      width: "100%",
      padding: theme.spacing(5, 5, 0, 0),
    },
    text: {
      opacity: 0.5,
      textAlign: "center",
      padding: theme.spacing(0, 1),
    },
    singleAreaText: {
      opacity: 0.5,
      textAlign: "center",
      padding: theme.spacing(1, 1),
    },
    titleText: {
      textAlign: "start",
      padding: theme.spacing(3, 1),
    },
    sliderText: {
      opacity: 0.8,
      textAlign: "center",
      paddingLeft: theme.spacing(2),
    },
    fieldInformationContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(),
    },
    leftContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    rightContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
    titlesContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
    },
    titleContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    spacer: {
      flex: 1,
      display: "flex",
    },
  });

export interface IDialogBodyProps extends WithStyles, WithTranslation {
  readonly fieldsAreaInformation: IFieldsAreaInformation[];
  readonly totalProcessed: number;
  readonly totalArea: number;
  readonly minDistance: number;
  readonly minLength: number;
  readonly maxCurvature: number;
  readonly radius: number;
  readonly onMinDistanceChange: (value: number) => void;
  readonly onMinLengthChange: (value: number) => void;
  readonly onMaxCurvatureChange: (value: number) => void;
  readonly onRadiusChange: (value: number) => void;
  readonly onRecalculateClick: () => void;
  readonly isLoading: boolean;
}

class DialogBodyComponent extends React.Component<IDialogBodyProps> {
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.column}>
            <div className={classes.leftBoxContainer}>
              <DialogBox title={""}>
                <Typography variant={"caption"} className={classes.titleText}>
                  {t("labels.recalculation-dialog.total-area")}
                </Typography>
                <div className={classes.rowSpaced}>
                  <Typography variant={"body1"} className={classes.text}>
                    {t("labels.recalculation-dialog.area")}
                  </Typography>
                  <Typography variant={"body1"}>
                    {t("labels.recalculation-dialog.area-value", {
                      value: this.props.totalArea,
                    })}
                  </Typography>
                </div>
                <div className={classes.rowSpaced}>
                  <Typography variant={"body1"} className={classes.text}>
                    {t("labels.recalculation-dialog.processed")}
                  </Typography>
                  <Typography variant={"body1"}>
                    {t("labels.recalculation-dialog.processed-value", {
                      value: this.props.totalProcessed,
                    })}
                  </Typography>
                </div>
              </DialogBox>
            </div>
          </div>
          {this.renderRecalculationFunction()}
          <div className={classes.column}>
            <div className={classes.rightBoxContainer}>
              <DialogBox title={""}>
                <Typography variant={"caption"} className={classes.titleText}>
                  {t("labels.recalculation-dialog.single-area")}
                </Typography>
                <div className={classes.titleContainer}>
                  <div className={classes.spacer} />
                  <div className={classes.titlesContainer}>
                    <Typography
                      variant={"body1"}
                      className={classes.singleAreaText}>
                      {t("labels.recalculation-dialog.area")}
                    </Typography>
                    <Typography
                      variant={"body1"}
                      className={classes.singleAreaText}>
                      {t("labels.recalculation-dialog.processed")}
                    </Typography>
                  </div>
                </div>
                {this.renderFieldInformation()}
              </DialogBox>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private renderRecalculationFunction(): React.ReactNode {
    const { classes, t } = this.props;
    if (this.props.isLoading) {
      return (
        <div className={classes.loadingIndicatorContainer}>
          <LoadingIndicator />
        </div>
      );
    } else {
      return (
        <div className={classes.slidersContainer}>
          <div className={classes.row}>
            <DialogSlider
              value={this.props.minDistance}
              title={t("labels.recalculation-dialog.sliders.min-distance")}
              onChange={(value) => this.props.onMinDistanceChange(value)}>
              {this.props.minDistance}
            </DialogSlider>
          </div>
          <div className={classes.row}>
            <DialogSlider
              value={this.props.minLength}
              title={t("labels.recalculation-dialog.sliders.min-length")}
              onChange={(value) => this.props.onMinLengthChange(value)}>
              {this.props.minLength}
            </DialogSlider>
          </div>
          <div className={classes.row}>
            <DialogSlider
              value={this.props.maxCurvature}
              title={t("labels.recalculation-dialog.sliders.max-curvature")}
              onChange={(value) => this.props.onMaxCurvatureChange(value)}>
              {this.props.maxCurvature}
            </DialogSlider>
          </div>
          <div className={classes.row}>
            <DialogSlider
              value={this.props.radius}
              title={t("labels.recalculation-dialog.sliders.radius")}
              onChange={(value) => this.props.onRadiusChange(value)}>
              {this.props.radius}
            </DialogSlider>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              onClick={() => this.props.onRecalculateClick()}
              variant={"contained"}>
              {t("actions.recalculation-dialog.recalculate")}
            </Button>
          </div>
        </div>
      );
    }
  }

  private renderFieldInformation(): React.ReactNode {
    const { t, classes } = this.props;
    return this.props.fieldsAreaInformation.map((x, index) => (
      <div className={classes.fieldInformationContainer}>
        <div className={classes.leftContainer}>
          <Typography variant={"body1"} className={classes.text}>
            {index + 1}.
          </Typography>
        </div>
        <div className={classes.rightContainer}>
          <Typography variant={"body1"}>
            {t("labels.recalculation-dialog.area-value", {
              value: x.totalArea,
            })}
          </Typography>
          <Typography variant={"body1"}>
            {t("labels.recalculation-dialog.processed-value", {
              value: x.totalProcessed,
            })}
          </Typography>
        </div>
      </div>
    ));
  }
}
export const DialogBody = withTranslation()(
  withStyles(styles)(DialogBodyComponent),
);
