import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ContentHeader } from "../../components/dashboard";
import { BasePage } from "../../components/general/BasePage";
import { CreateMachineDialog } from "../../components/machines/CreateMachineDialog";
import { MachinesTable } from "../../components/machines/MachinesTable";
import { Routes } from "../../components/navigation/Routes";
import { Pagination } from "../../components/pagintaion/Pagination";
import {
  IApplicationStoreContextConsumerProps,
  withApplicationStateStoreContext,
} from "../../infrastructure/application-state";
import { BatteryPercentageService } from "../../infrastructure/battery-percentage/BatteryPercentageService";
import { inject } from "../../infrastructure/injection";
import {
  IMachine,
  IMachineCategory,
  IMachinesService,
  INewMachine,
  MachineState,
} from "../../infrastructure/machines";
import {
  NotificationCenter,
  NotificationTypes,
} from "../../infrastructure/notification";
import { TypeNames } from "../../infrastructure/TypeNames";

const styles = (theme: Theme) =>
  createStyles({
    head: {
      flex: 1,
      display: "flex",
      textAlign: "center",
      alignItems: "center",
      paddingBottom: theme.spacing(3),
    },
    body: {
      flex: 16,
      display: "flex",
      flexDirection: "column",
    },
  });

export type IRootProps = WithStyles<typeof styles> &
  WithTranslation &
  RouteComponentProps &
  IApplicationStoreContextConsumerProps;

class RootState {
  public isMachineActionMenuOpen: boolean = false;
  public isNewMachineDialogOpen: boolean = false;
  public machineActionId?: number;
  public machineMenuAnchor?: HTMLElement;
  public selectedMachineIds: number[] = [];
  public machines: IMachine[] = [];
  public search: string = "";
  public machineCategories: IMachineCategory[] = [];
  public isLoading: boolean = true;
  public sortingKey: string = "id";
  public sortInverse: boolean = false;
  public currentPage: number = 1;
  public maxPages: number = 1;
}

class RootComponent extends React.Component<IRootProps, RootState> {
  @inject(TypeNames.notificationCenter)
  private readonly _notificationCenter: NotificationCenter;
  @inject(TypeNames.machinesService)
  private readonly _machinesService: IMachinesService;
  public constructor(props: IRootProps) {
    super(props);
    this.state = new RootState();
  }
  public render(): React.ReactNode {
    const { classes, t } = this.props;
    return (
      <BasePage
        currentBaseRoute={Routes.machines}
        onRouteClick={(newRoute: Routes) => this.props.history.push(newRoute)}>
        <div className={classes.head}>
          <ContentHeader
            buttonProps={{
              onButtonClick: () => this.handleNewMachineClicked(),
              buttonLabel: t("actions.machines.create"),
            }}
            iconProps={{
              isIcon: true,
            }}
            title={t("labels.machines.title")}
          />
        </div>
        <div className={classes.body}>
          <MachinesTable
            search={this.state.search}
            onSearchChanged={(search) => this.handleSearchChanged(search)}
            onSearchClick={() => this.handleOnSearchClicked()}
            isLoading={this.state.isLoading}
            machines={this.getSortedData()}
            selectedMachineIds={this.state.selectedMachineIds}
            onActionOpen={(event, id) => this.handleActionOpen(event, id)}
            isMachineActionMenuOpen={this.state.isMachineActionMenuOpen}
            machineMenuAnchor={this.state.machineMenuAnchor}
            onActionClose={() => this.handleActionClose()}
            onAdministrationClick={() => this.handleAdministrationClicked()}
            onDeleteClick={() => this.handleDeleteClicked()}
            onSortingClick={(sortingKey) =>
              this.handleSortingClicked(sortingKey)
            }
            sortInverse={this.state.sortInverse}
            sortingKey={this.state.sortingKey}
          />
        </div>
        <CreateMachineDialog
          machineCategories={this.state.machineCategories}
          isOpen={this.state.isNewMachineDialogOpen}
          onClose={() => this.handleNewMachineDialogClose()}
          onCreateClick={(value) => this.handleCreateMachine(value)}
        />
        <Pagination
          onPagePress={(page: number) => this.handleOnPagePress(page)}
          currentPage={this.state.currentPage}
          maxPages={this.state.maxPages}
        />
      </BasePage>
    );
  }

  public async componentDidMount(): Promise<void> {
    await this.getMachineCategories();
    await this.getMachines();
  }

  private async getMachines(): Promise<void> {
    if (this.props.store.state.jwt != undefined) {
      this.setState({ isLoading: true });
      let machines = await this._machinesService.getMachines(
        this.props.store.state.jwt,
        this.state.currentPage,
        this.state.search,
      );

      const pageCount = await this._machinesService.getMachinesPages(
        this.props.store.state.jwt,
        this.state.search,
      );
      // remover corrupt data
      machines = machines.filter((machine) => machine !== null);
      this.setState({ machines, maxPages: pageCount, isLoading: false });
    }
  }

  private getSortedData(): IMachine[] {
    const t = this.props.t;
    let data = this.state.machines;

    switch (this.state.sortingKey) {
      case "id":
        data = data.sort(
          (a, b) =>
            (a.gpsTracker ? Number(a.gpsTracker.serialNumber) : 1) -
            (b.gpsTracker ? Number(b.gpsTracker.serialNumber) : 1),
        );
        break;
      case "type":
        data = data.sort((a, b) =>
          t("labels.machine-types." + a.type) >
          t("labels.machine-types." + b.type)
            ? -1
            : 1,
        );
        break;
      case "name":
        data = data.sort((a, b) => (a.name > b.name ? -1 : 1));
        break;
      case "state":
        data = data.sort((a, b) => {
          const map = {
            [MachineState.active]: 1,
            [MachineState.inactive]: 2,
            [MachineState.archived]: 3,
          };
          return map[a.state] > map[b.state] ? -1 : 1;
        });
        break;
      case "area": {
        data.sort((a, b) => {
          let aArea = 0;
          let bArea = 0;
          if (a) {
            aArea = a.totalFieldArea;
          }
          if (b) {
            bArea = b.totalFieldArea;
          }
          return aArea - bArea;
        });
        break;
      }
      case "date":
        data = data.sort(
          (a, b) =>
            new Date(a.lastMoved).getTime() - new Date(b.lastMoved).getTime(),
        );
        break;
      case "battery":
        data = data.sort(
          (a, b) =>
            BatteryPercentageService.getBatteryPercentage(a) -
            BatteryPercentageService.getBatteryPercentage(b),
        );
        break;
    }

    if (this.state.sortInverse) {
      data.reverse();
    }
    return data.filter((machine) => machine !== null);
  }

  private async getMachineCategories(): Promise<void> {
    if (this.props.store.state.jwt == undefined) {
      return;
    }
    const machineCategories = await this._machinesService.getMachineCategories(
      this.props.store.state.jwt,
    );
    this.setState({ machineCategories });
  }

  private async handleDeleteClicked(): Promise<void> {
    if (this.state.machineActionId != undefined) {
      this.setState({ isLoading: true });
      await this._machinesService.deleteMachine(
        this.state.machineActionId,
        this.props.store.state.jwt,
      );
      await this.getMachines();
      this.setState({ isLoading: false, isMachineActionMenuOpen: false });
    }
  }

  private handleSortingClicked(sortingKey: string): void {
    switch (sortingKey) {
      case "id":
      case "type":
      case "name":
      case "state":
      case "area":
      case "date":
      case "battery":
        {
          this.setState({ sortInverse: !this.state.sortInverse, sortingKey });
        }
        break;
      default:
        break;
    }
  }

  private handleActionOpen(
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    machineActionId: number,
  ): void {
    this.setState({
      isMachineActionMenuOpen: true,
      machineActionId,
      machineMenuAnchor: event.currentTarget,
    });
  }

  private handleActionClose(): void {
    this.setState({
      isMachineActionMenuOpen: false,
      machineActionId: undefined,
      machineMenuAnchor: undefined,
    });
  }

  private handleAdministrationClicked(): void {
    if (this.state.machineActionId != undefined) {
      const route = Routes.machinesAdministration.replace(
        ":machineId",
        this.state.machineActionId.toString(),
      );
      this.props.history.push(route);
    }
  }

  private handleNewMachineClicked(): void {
    this.setState({ isNewMachineDialogOpen: true });
  }

  private handleNewMachineDialogClose(): void {
    this.setState({ isNewMachineDialogOpen: false });
  }

  private async handleCreateMachine(machine: INewMachine): Promise<void> {
    if (this.props.store.state.jwt == undefined) {
      return;
    }
    try {
      await this._machinesService.postMachine(
        this.props.store.state.jwt,
        machine,
      );
      await this.getMachines();
      this.handleNewMachineDialogClose();
    } catch {
      this._notificationCenter.sendNotification({
        notificationType: NotificationTypes.error,
        text: this.props.t("labels.create-machine.errors.failed"),
      });
    }
  }

  private handleSearchChanged(search: string): void {
    this.setState({ search });
  }

  private handleOnPagePress(page: number): void {
    this.setState({ currentPage: page }, async () => {
      await this.getMachines();
    });
  }

  private async handleOnSearchClicked(): Promise<void> {
    this.setState({ currentPage: 1 }, async () => await this.getMachines());
  }
}

export const Root = withApplicationStateStoreContext(
  withRouter(withTranslation()(withStyles(styles)(RootComponent))),
);
