import {
  Card,
  createStyles,
  TextField,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ContentHeader } from "../../components/dashboard";
import { BasePage } from "../../components/general/BasePage";
import { LoadingIndicator } from "../../components/general/LoadingIndicator";
import { Timeline, TimelineInfos } from "../../components/history";
import { Routes } from "../../components/navigation/Routes";

import {
  IApplicationStoreContextConsumerProps,
  withApplicationStateStoreContext,
} from "../../infrastructure/application-state";
import { IHistoryService } from "../../infrastructure/history";
import { inject } from "../../infrastructure/injection";
import { IMachine, IMachinesService } from "../../infrastructure/machines";
import { ITimelineItem } from "../../infrastructure/timeline/ITimelineItem";
import { TypeNames } from "../../infrastructure/TypeNames";
import moment = require("moment");

const styles = (theme: Theme) =>
  createStyles({
    head: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      textAlign: "center",
    },
    body: {
      flex: 16,
      display: "flex",
      flexDirection: "row",
    },
    timelineContainer: {
      flex: 1,
      display: "flex",
      backgroundColor: theme.palette.background.paper,
      justifyContent: "center",
      borderRadius: theme.spacing(1),
    },
    timelineInfoContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(1),
    },
    container: {
      display: "flex",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    rightContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 16,
      padding: 20,
    },
    loadingIndicatorContainer: {
      flex: 1,
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
  });
export interface IRouteParams {
  readonly machineId?: string;
}
type IRootProps = WithStyles<typeof styles> &
  RouteComponentProps<IRouteParams> &
  IApplicationStoreContextConsumerProps &
  WithTranslation;

class RootState {
  public selectedMachine: IMachine;
  public machines: IMachine[];
  public timeline: ITimelineItem[] = [];
  public isMachineDialogOpen: boolean = false;
  public searchText: string = "";
  public minDate: string = moment(
    new Date().setDate(new Date().getDate() - 7),
  ).format("yyyy-MM-DDT00:00");
  public maxDate: string = moment(new Date()).format("yyyy-MM-DDTHH:mm");
  public timelinePage: number = 1;
  public maxPages: number = 1;
}

class RootComponent extends React.Component<IRootProps, RootState> {
  @inject(TypeNames.historyService)
  private readonly _historyService: IHistoryService;
  @inject(TypeNames.machinesService)
  private readonly _machineService: IMachinesService;
  public constructor(props: IRootProps) {
    super(props);
    this.state = new RootState();
  }

  public render(): React.ReactNode {
    const { classes, t } = this.props;
    if (this.state.machines == undefined) {
      return (
        <BasePage
          currentBaseRoute={Routes.history}
          onRouteClick={(newRoute: Routes) =>
            this.props.history.push(newRoute)
          }>
          <div className={classes.head}>
            <ContentHeader
              iconProps={{
                isIcon: true,
              }}
              title={t("labels.history.title")}
            />
          </div>
          <Card className={classes.body}>
            <div className={classes.loadingIndicatorContainer}>
              <LoadingIndicator />
            </div>
          </Card>
        </BasePage>
      );
    }
    return (
      <BasePage
        currentBaseRoute={Routes.history}
        onRouteClick={(newRoute: Routes) => this.props.history.push(newRoute)}>
        <div className={classes.head}>
          <ContentHeader
            iconProps={{
              isIcon: true,
            }}
            title={t("labels.history.title")}
          />
        </div>
        <Card className={classes.body}>
          <div className={classes.timelineInfoContainer}>
            <TimelineInfos
              machine={this.state.selectedMachine}
              machines={this.state.machines.filter((machine) => {
                if (
                  machine.name
                    .toLowerCase()
                    .includes(this.state.searchText.toLowerCase())
                ) {
                  return machine;
                }
              })}
              isOpen={this.state.isMachineDialogOpen}
              onClose={() => this.setState({ isMachineDialogOpen: false })}
              onOpen={() => this.setState({ isMachineDialogOpen: true })}
              onMachineChanged={(id: number) => this.handleMachineChanged(id)}
              search={this.state.searchText}
              onSearchChanged={(searchText: string) =>
                this.setState({ searchText })
              }
            />
          </div>
          <div className={classes.rightContainer}>
            {this.renderTimePicker()}
            <div className={classes.timelineContainer}>
              <Timeline
                timelineItem={this.state.timeline[0]}
                isTransport={
                  this.state.selectedMachine.category === "transport"
                }
                currentPage={this.state.timelinePage}
                maxPages={this.state.maxPages}
                onNewPagePress={(page: number) =>
                  this.setState({ timelinePage: page }, () =>
                    this.getTimeline(),
                  )
                }
              />
            </div>

            {/*<Pagination*/}
            {/*  onPagePress={(page: number) =>*/}
            {/*    this.setState({ timelinePage: page }, () => this.getTimeline())*/}
            {/*  }*/}
            {/*  currentPage={this.state.timelinePage}*/}
            {/*  maxPages={this.state.maxPages}*/}
            {/*/>*/}
          </div>
        </Card>
      </BasePage>
    );
  }

  public async componentDidMount(): Promise<void> {
    await this.getMachines();
    await this.getTimeline();
  }

  private async getTimeline(): Promise<void> {
    if (this.props.store.state.jwt != undefined) {
      const timeline = await this._historyService.getMachineTimeline(
        this.props.store.state.jwt,
        this.state.timelinePage,
        this.state.selectedMachine.id,
        new Date(this.state.minDate).toISOString(),
        new Date(this.state.maxDate).toISOString(),
      );
      const timelinePages = await this._historyService.getTimelinePage(
        this.props.store.state.jwt,
        this.state.selectedMachine.id,
        new Date(this.state.minDate).toISOString(),
        new Date(this.state.maxDate).toISOString(),
      );
      this.setState({ timeline, maxPages: timelinePages });
    }
  }

  private async getMachines() {
    if (this.props.store.state.jwt != undefined) {
      const machines = await this._historyService.getMachines(
        this.props.store.state.jwt,
      );
      const selectedMachine = machines.find(
        (x) => x.id === +this.props.history.location.search.replace("?", ""),
      );

      if (machines.length > 0) {
        this.setState({
          selectedMachine:
            selectedMachine == undefined ? machines[0] : selectedMachine,
          machines,
        });
      }
    }
  }

  private handleMachineChanged(id: number): void {
    const machine = this.state.machines.find((machine) => machine.id === id);
    if (machine != undefined) {
      this.setState({ selectedMachine: machine, timelinePage: 1 }, () =>
        this.getTimeline(),
      );
    }
  }

  private renderTimePicker(): JSX.Element {
    const { classes, t } = this.props;
    return (
      <div
        style={{
          display: "flex",
          height: 50,
          justifyContent: "space-between",
        }}>
        <form className={classes.container} noValidate>
          <TextField
            id="datetime-local-min"
            label={t("labels.history.from") + ":"}
            type="datetime-local"
            defaultValue={this.state.minDate}
            className={classes.textField}
            onChange={(event) => this.setState({ minDate: event.target.value })}
            onBlur={() =>
              this.setState({ timelinePage: 1 }, () => this.getTimeline())
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>
        <form className={classes.container} noValidate>
          <TextField
            id="datetime-local-max"
            label={t("labels.history.until") + ":"}
            type="datetime-local"
            defaultValue={this.state.maxDate}
            onChange={(event) => this.setState({ maxDate: event.target.value })}
            onBlur={() =>
              this.setState({ timelinePage: 1 }, () => this.getTimeline())
            }
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>
      </div>
    );
  }
}

export const Root = withApplicationStateStoreContext(
  withRouter(withTranslation()(withStyles(styles)(RootComponent))),
);
