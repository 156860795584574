import { IconButton } from "@material-ui/core";
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";
import * as React from "react";

interface ISortingButtonProps {
  readonly selectedSortingKey: string;
  readonly sortingKey: string;
  readonly sortInverse: boolean;
  readonly onSortingClick: () => void;
}

class SortingButtonComponent extends React.Component<ISortingButtonProps> {
  public render(): React.ReactNode {
    if (
      this.props.sortInverse &&
      this.props.sortingKey === this.props.selectedSortingKey
    ) {
      return (
        <IconButton onClick={() => this.props.onSortingClick()}>
          <ArrowDropDown />
        </IconButton>
      );
    }
    return (
      <IconButton onClick={() => this.props.onSortingClick()}>
        <ArrowDropUp />
      </IconButton>
    );
  }
}

export const SortingButton = SortingButtonComponent;
